"use client";

import React, { ReactElement } from "react";
import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import { TElement, useEditorRef } from "@udecode/plate-common";
import { useOpenState } from "./dropdown-menu";
import { IMenuItem } from "../new-editor/utils/menuItemUtil";
import { Box, Menu, MenuItem, useTheme } from "@mui/material";
import { ReactEditor } from "slate-react";
import { InsertElementMenuItem } from "./insert-element-menu-item";
import { InsertElementMenuGroupHeader } from "./insert-element-menu-group-header";
import { insertElementAt } from "../new-editor/utils/editorUtils";
import { Location, Path } from "slate";
import { useItemDisabled } from "../new-editor/hooks";

interface IInsertDropDownMenuItems {
  label: string;
  items: Array<IMenuItem>;
}

type InsertDropdownMenu = {
  menuItems?: IInsertDropDownMenuItems[];
  buttonComponent?: ReactElement;
  element?: TElement;
  anchorEl: HTMLElement;
  open: boolean;
  handleClose: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  insertPath: Path;
} & DropdownMenuProps;

export function InsertDropdownMenu({
  menuItems,
  buttonComponent,
  element,
  anchorEl,
  open,
  handleClose,
  handleClick,
  insertPath,
  ...props
}: InsertDropdownMenu) {
  const theme = useTheme();
  const editor = useEditorRef();
  const openState = useOpenState();

  const handleMenuItemClick = async (type: string, event) => {
    insertElementAt(type, editor, insertPath);
    handleClose(event);
  };

  const isItemDisabled = useItemDisabled();

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          ":hover": { cursor: "pointer" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {buttonComponent}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableRestoreFocus
        sx={{
          maxHeight: "500px",
          zIndex: theme.zIndex.modal,
          ".MuiMenu-list": {
            p: 0,
          },
        }}
      >
        {menuItems?.map(({ items: nestedItems, label }, menuIndex) => (
          <Box key={label}>
            <InsertElementMenuGroupHeader groupLabel={label} />

            <Box sx={{ py: 1 }}>
              {nestedItems.map(
                (
                  {
                    value: type,
                    label: itemLabel,
                    icon: Icon,
                    description: itemDescription,
                  },
                  index,
                ) => (
                  <Box key={index} sx={{ px: 1 }}>
                    <MenuItem
                      key={index}
                      onClick={async (event) =>
                        handleMenuItemClick(type, event)
                      }
                      disabled={isItemDisabled(type)}
                      sx={{ p: 0, borderRadius: 2 }}
                    >
                      <InsertElementMenuItem
                        itemLabel={itemLabel}
                        itemDescription={itemDescription}
                        Icon={Icon}
                      />
                    </MenuItem>
                  </Box>
                ),
              )}
            </Box>
          </Box>
        ))}
      </Menu>
    </Box>
  );
}
