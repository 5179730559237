import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  useTheme,
  Collapse,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";

import { SchemaElements } from "../editor/normalizers/withSchema";
import { ExerciseAsset } from "../exercise-library/types";
import { ExerciseAutocomplete } from "../exercise-library/ExerciseAutocomplete";
import { useExerciseLibrary } from "../exercise-library/hooks/useExerciseLibrary";
import { useSaveExercise } from "../exercise-library/hooks/useSaveExercise";
import { InputPlusMinus } from "../input-plus-minus";
import CoachSets from "../coach-sets/CoachSets";
import {
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
  UnitsTime,
} from "../../constants";

import { WorkoutExercise, WorkoutExerciseImage } from "./types";
import { WorkoutMedia } from "./WorkoutMedia";
import { WorkoutUploadMedia } from "./WorkoutUploadMedia";
import { WorkoutExerciseHistoryButton } from "./WorkoutExerciseHistoryButton";
import {
  createEmptyExercise,
  createEmptySet,
  getSetFieldMaxLength,
} from "./utils";
import { WorkoutDemoVideo } from "./WorkoutDemoVideo";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { WorkoutActionsDelete } from "./WorkoutActionsDelete";
import WorkoutExerciseSettings from "./WorkoutExerciseSettings";

import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";
import { ReactComponent as SettingsIcon } from "../../icons/SettingsFill.svg";
import { useSetDirty } from "../../hooks/useSetDirty";
import { useAddExercise } from "../../hooks/useAddExercise";
import { Tooltip } from "../info/Tooltip";
import Settings from "../../icons/Settings";

const useStyles = makeStyles((theme) => ({
  root: {},

  wrapper: {
    paddingBottom: theme.spacing(10),
  },

  workoutEditTitle: {
    color: theme.palette.secondary.main,
    textTransform: "capitalize",
    margin: `${theme.spacing(2.5, 0, 4, 0)}!important`,
  },

  setsContainerInput: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2.5, 0),
  },

  setsTopContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  settingsButton: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: 16,
  },

  label: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: 16,
    margin: theme.spacing(2.5, 0, 2, 0),
  },

  setsLabel: {
    marginRight: 10,
  },

  textField: {
    "& > div": {
      borderRadius: 0,
    },
  },

  input: {
    fontWeight: 500,
  },

  caretDownIcon: {
    width: 11,
  },

  images: {
    display: "flex",
    flexWrap: "wrap",
  },

  actions: {
    display: "flex",
    position: "sticky",
    zIndex: 999,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0),
    bottom: theme.spacing(-3),
    right: 0,
    left: 0,
  },

  button: {
    borderRadius: 0,
    borderWidth: "2px !important",
    borderStyle: "solid",
    padding: theme.spacing(1.5),
    fontWeight: 700,
    fontSize: 16,
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },

    "&:not([disabled])": {
      borderColor: theme.palette.secondary.main,
    },

    "&[disabled]": {
      borderColor: theme.palette.text.secondary,
    },
  },

  cancelButton: {
    "&[disabled]": {
      color: theme.palette.text.secondary,
    },
  },

  saveButton: {
    "&[disabled]": {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.white,
    },
  },

  checkboxLabel: {
    color: theme.palette.text.primary,
    fontWeight: "500 !important" as any,
  },
  addToLibraryText: {
    color: theme.palette.text.secondary,
    lineHeight: "17px",
    marginTop: 20,
  },
}));

export interface WorkoutEditExerciseProps extends BoxProps {
  sectionName?: string | undefined;
  exercise: WorkoutExercise;
  onUpdate?: (exercise: WorkoutExercise) => void;
  onCancel?: () => void;
  disableSubtitle?: boolean;
}

const checkSetValid = (exercise: WorkoutExercise) =>
  exercise.sets?.every((set) => set.reps !== "-" && set.reps !== "");

const isCompleted = (exercise: WorkoutExercise) =>
  Boolean(exercise.title && checkSetValid(exercise));

export function WorkoutEditExercise(props: WorkoutEditExerciseProps) {
  const {
    className,
    sectionName,
    exercise: defaultValue,
    onUpdate,
    onCancel,
    disableSubtitle,
    ...other
  } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const addExercise = useAddExercise();
  const rootRef = useExerciseLibrary();
  const contextSetDirty = useSetDirty();
  const [assetId, setAssetId] = React.useState<string>(defaultValue.assetId);
  const [exercise, setExercise] = React.useState(defaultValue);
  const [dirty, setDirty] = React.useState(false);
  const completed = isCompleted(exercise);
  const [initiallyCompleted, setInitiallyCompleted] = React.useState(completed);
  const disabled = !dirty || !completed;
  const [updateLibrary, setUpdateLibrary] = React.useState(false);
  const saveExercise = useSaveExercise();
  const [visibleDeleteModal, setVisibleDeleteModal] = React.useState(false);
  const [chooseMedia, setChooseMedia] = React.useState(null);
  const [openExerciseSettings, setOpenExerciseSettings] = React.useState(false);
  const [addMedia, setAddMedia] = React.useState<
    WorkoutExerciseImage[] | WorkoutExerciseImage
  >(null);
  const [addOwnVideo, setAddOwnVideo] = React.useState(null);

  const typeReps = exercise?.typeReps || ExerciseTypeReps.WHOLE;
  const typeSet = exercise?.typeSet || ExerciseTypeSet.WEIGHT;

  const getDefaultUnits = (type: ExerciseTypeSet) =>
    type === ExerciseTypeSet.TIME ? UnitsTime.MINUTES : user.units;

  const handleUpdateLibraryChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      setUpdateLibrary(checked),
    [],
  );

  const handleOpenModal = React.useCallback((media) => {
    setVisibleDeleteModal(true);
    setChooseMedia(media);
  }, []);

  const handleClickSettings = React.useCallback(() => {
    setOpenExerciseSettings((value) => !value);
  }, [setOpenExerciseSettings]);

  const handleCloseModal = React.useCallback(() => {
    setVisibleDeleteModal(false);
    setChooseMedia(null);
  }, []);

  const handleRemoveMedia = React.useCallback(
    (image: WorkoutExerciseImage) => {
      const images = exercise.images.filter((it) => it !== image);
      setExercise({
        ...exercise,
        images,
      });

      setDirty(true);
    },
    [exercise],
  );

  const handleRemoveDemoVideo = React.useCallback(() => {
    setExercise({
      ...exercise,
      video: null,
    });
    setDirty(true);
  }, [exercise]);

  const confirmAction = React.useCallback(() => {
    if (!chooseMedia?.isOwnVideo) {
      handleRemoveMedia(chooseMedia?.media);
      setVisibleDeleteModal(false);
    } else {
      handleRemoveDemoVideo();
      setVisibleDeleteModal(false);
    }
  }, [
    chooseMedia,
    handleRemoveMedia,
    setVisibleDeleteModal,
    handleRemoveDemoVideo,
  ]);

  const handleToggleCoverMedia = React.useCallback(
    (image: WorkoutExerciseImage) => {
      const images = exercise.images.map((it) => ({
        ...it,
        cover: !it.cover && it === image,
      }));

      setExercise({
        ...exercise,
        images,
      });
      setDirty(true);
    },
    [exercise],
  );

  const handleAutocompleteTitleChange = React.useCallback(
    (_, title: string) => {
      setExercise({
        ...exercise,
        title,
      });
      setDirty(true);
    },
    [exercise],
  );

  const handleTextFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.currentTarget;

      setExercise({
        ...exercise,
        [name]: value,
      });
      setDirty(true);
    },
    [exercise],
  );

  const handleSetFieldChange = React.useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      index: number,
    ) => {
      // eslint-disable-next-line prefer-const
      let { name, value } = event.currentTarget;

      const maxLength = getSetFieldMaxLength(name, typeReps, typeSet);
      if (value.replace(".", "").length <= maxLength || value === "") {
        const sets = [...exercise.sets];
        sets[index] = {
          ...sets[index],
          [name]: value,
        };
        setExercise({
          ...exercise,
          sets: [...sets],
        });
        setDirty(true);
      }
    },
    [exercise],
  );

  const handleExerciseSettingsChange = React.useCallback(
    (field, value) => {
      if (field === "units") {
        setExercise({
          ...exercise,
          units: value,
        });
      } else if (field === "typeExtraMeasurement") {
        setExercise({
          ...exercise,
          [field]: value,
          sets: exercise.sets.map((item) => ({
            ...item,
            extraMeasurement: "",
          })),
        });
      } else if (field === "typeReps") {
        setExercise({
          ...exercise,
          [field]: value,
          sets: exercise.sets.map((item) => ({
            ...item,
            reps: "",
          })),
        });
      } else if (field === "typeSet") {
        setExercise({
          ...exercise,
          [field]: value,
          sets: exercise.sets.map((item) => ({
            ...item,
            weight: "",
          })),
          units: getDefaultUnits(value),
        });
      } else {
        setExercise({
          ...exercise,
          [field]: value,
        });
      }
    },
    [setExercise, exercise, user.units],
  );

  const handleSetsNumberPlus = React.useCallback(() => {
    setDirty(true);
    setExercise({
      ...exercise,
      sets: [...exercise.sets, createEmptySet()],
    });
  }, [exercise]);

  const handleSetsNumberMinus = React.useCallback(() => {
    setDirty(true);
    if (exercise.sets.length > 1) {
      const sets = [...exercise.sets];
      sets.pop();
      setExercise({
        ...exercise,
        sets: [...sets],
      });
    }
  }, [exercise]);

  const handleCancel = React.useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleSave = React.useCallback(() => {
    const defaultExerciseConfig = {
      typeSet: ExerciseTypeSet.WEIGHT,
      typeReps: ExerciseTypeReps.WHOLE,
      units: getDefaultUnits(exercise.typeSet),
    };
    if (updateLibrary) {
      saveExercise(
        {
          assetId,
          exercise: {
            ...defaultExerciseConfig,
            ...exercise,
          },
        },
        {
          onCompleted: (response) => {
            if (onUpdate) {
              const assetId = response.upsertCustomAsset.asset.id;

              onUpdate({
                assetId,
                ...defaultExerciseConfig,
                ...exercise,
              });
            }
          },
        },
      );
    } else {
      if (onUpdate) {
        if (contextSetDirty) contextSetDirty(true);
        onUpdate({ ...defaultExerciseConfig, ...exercise });
      }
    }
  }, [
    assetId,
    exercise,
    onUpdate,
    saveExercise,
    updateLibrary,
    contextSetDirty,
  ]);

  const handleUpload = React.useCallback(
    (image: WorkoutExerciseImage[] | WorkoutExerciseImage) => {
      if (Array.isArray(image)) {
        const images = [...exercise.images];
        image.map((item, index) =>
          images.push({
            ...item,
            cover: !exercise.images.length && !index,
          }),
        );
        setExercise({
          ...exercise,
          images: [...images],
        });
      } else {
        const images = [
          ...exercise.images,
          {
            ...image,
            cover: !exercise.images.length,
          },
        ];

        setExercise({
          ...exercise,
          images,
        });
      }
      setDirty(true);
    },
    [exercise],
  );

  const handleUploadVideo = React.useCallback(
    (video: any) => {
      setExercise({
        ...exercise,
        video: video.url,
      });

      setDirty(true);
    },
    [exercise],
  );

  const handleAutocompleteChange = React.useCallback(
    ({ assetId, exercise, userId }: ExerciseAsset) => {
      const id = SchemaElements.generateId();
      setExercise({
        ...(exercise ?? createEmptyExercise()),
        id,
        superset: id,
        assetId,
      });
      setDirty(true);
      setAssetId(assetId);
    },
    [],
  );

  const inputProps = {
    id: "workout-exercise-autocomplete",
    name: "title",
    placeholder: "ex. Alternating bodyweight lunge",
    fullWidth: true,
  };
  const theme = useTheme();
  React.useEffect(() => {
    if (addMedia) {
      handleUpload(addMedia);
      setAddMedia(null);
    }
    if (addOwnVideo) {
      handleUploadVideo(addOwnVideo);
      setAddOwnVideo(null);
    }
  }, [addMedia, handleUpload, addOwnVideo, handleUploadVideo]);

  React.useEffect(() => {
    if (completed && !initiallyCompleted) {
      setInitiallyCompleted(true);
    }
  }, [completed, initiallyCompleted]);

  return (
    <>
      <Box className={clsx(s.root, className)} {...other}>
        <Typography variant="h2" className={s.workoutEditTitle}>
          Exercise Section
        </Typography>
        <Typography variant="h5">{sectionName}</Typography>
        <Typography variant="h6" className={s.label}>
          Exercise name
        </Typography>

        {completed || initiallyCompleted ? (
          <TextField
            {...inputProps}
            variant="outlined"
            className={s.textField}
            InputProps={{
              className: s.input,
              endAdornment: (
                <Tooltip title="View history" placement="top">
                  <WorkoutExerciseHistoryButton
                    disabled={addExercise}
                    exercise={exercise}
                  />
                </Tooltip>
              ),
            }}
            value={exercise.title}
            onChange={handleTextFieldChange}
            disabled={addExercise && (completed || initiallyCompleted)}
          />
        ) : (
          <ExerciseAutocomplete
            rootRefRef={rootRef}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.exercise.title
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                {...inputProps}
                variant="outlined"
                className={s.textField}
                InputProps={{
                  ...params.InputProps,
                  className: s.input,
                  endAdornment: <CaretDownIcon className={s.caretDownIcon} />,
                }}
              />
            )}
            inputValue={exercise.title}
            onInputChange={handleAutocompleteTitleChange}
            onChange={handleAutocompleteChange}
          />
        )}

        {!addExercise && (
          <Box>
            {!assetId && exercise.title && (
              <div className={s.addToLibraryText}>
                There is no such exercise in the database.
                <br />
                You can add it as a new exercise
              </div>
            )}
            <FormControlLabel
              classes={{ label: s.checkboxLabel }}
              control={
                <Checkbox
                  color="primary"
                  disabled={!dirty}
                  checked={updateLibrary}
                  onChange={handleUpdateLibraryChange}
                />
              }
              disabled={!dirty}
              label={
                assetId ? "Update exercise library" : "Add to exercise library"
              }
            />
          </Box>
        )}

        <Typography variant="h6" className={s.label}>
          {addExercise ? "video demo" : "Add your own video demo"}
        </Typography>
        <WorkoutDemoVideo
          onUpload={setAddOwnVideo}
          video={exercise.video}
          onRemoveVideo={handleRemoveDemoVideo}
          handleOpenModal={handleOpenModal}
        />

        <Typography variant="h6" className={s.label}>
          Instructions
        </Typography>
        <TextField
          className={s.textField}
          InputProps={{
            className: s.input,
          }}
          name="instructions"
          value={exercise.instructions}
          onChange={handleTextFieldChange}
          placeholder="ex. Make sure to brace your core"
          variant="outlined"
          fullWidth
          rows={5}
          multiline
          disabled={addExercise}
        />
        {!disableSubtitle && (
          <>
            <div className={s.setsTopContent}>
              <Box className={s.setsContainerInput}>
                <Typography variant="h6" className={clsx(s.label, s.setsLabel)}>
                  Sets
                </Typography>
                <InputPlusMinus
                  value={exercise.sets.length}
                  onClickMinus={handleSetsNumberMinus}
                  onClickPlus={handleSetsNumberPlus}
                  disabled={addExercise && !initiallyCompleted}
                />
              </Box>
              <Box>
                <Button
                  className={s.settingsButton}
                  startIcon={<Settings fill={theme.palette.primary.main} />}
                  children="Settings"
                  onClick={handleClickSettings}
                  disabled={addExercise && !initiallyCompleted}
                />
              </Box>
            </div>
            <Collapse in={openExerciseSettings}>
              <WorkoutExerciseSettings
                extraMeasurement={
                  exercise.typeExtraMeasurement || ExerciseTypeExtra.NONE
                }
                typeSet={exercise.typeSet || ExerciseTypeSet.WEIGHT}
                typeReps={exercise.typeReps || ExerciseTypeReps.WHOLE}
                units={exercise?.units || user.units}
                onChangeSettings={handleExerciseSettingsChange}
              />
            </Collapse>
            <CoachSets
              sets={exercise.sets}
              onChangeSet={handleSetFieldChange}
              units={exercise?.units || user.units}
              typeExtraMeasurement={
                exercise.typeExtraMeasurement || ExerciseTypeExtra.NONE
              }
              typeSet={exercise.typeSet || ExerciseTypeSet.WEIGHT}
              typeReps={exercise.typeReps || ExerciseTypeReps.WHOLE}
              disabled={addExercise && !initiallyCompleted}
            />
          </>
        )}
        {!addExercise && (
          <>
            <Typography variant="h6" className={s.label}>
              Add media
            </Typography>
            <Box className={s.images}>
              {!!exercise?.images?.length &&
                exercise.images.map((image, index) => (
                  <WorkoutMedia
                    key={index}
                    image={image}
                    onRemove={handleRemoveMedia}
                    onToggleCover={handleToggleCoverMedia}
                    handleOpenModal={handleOpenModal}
                  />
                ))}

              <WorkoutUploadMedia onUpload={setAddMedia} />
            </Box>
          </>
        )}

        <Box className={s.actions}>
          <Button
            variant="outlined"
            className={clsx(s.button, s.cancelButton)}
            disabled={disabled}
            onClick={handleCancel}
            fullWidth
          >
            Discard
          </Button>
          <Button
            variant="contained"
            className={clsx(s.button, s.saveButton)}
            fullWidth
            onClick={handleSave}
            disabled={addExercise ? !initiallyCompleted : !exercise.title}
          >
            Save exercise
          </Button>
        </Box>
        <WorkoutActionsDelete
          title={"Delete item"}
          onCancel={handleCloseModal}
          onConfirm={confirmAction}
          open={visibleDeleteModal}
          image={chooseMedia?.media}
        />
      </Box>
    </>
  );
}
