import { Capacitor } from "@capacitor/core";
import { getCookie } from "../components/program-component/utils";

export const isMobileApp = Boolean(process.env.REACT_APP_CAPACITOR_ENV);
export const androidMobileApp =
  isMobileApp && navigator.userAgent.includes("Android");

export const capPlatform = Capacitor.getPlatform();

export const isCapacitorApp = Capacitor.getPlatform() === "android" || "ios";

export const iOSMobileApp = capPlatform === "ios";

export const brandedAppWorkspaceKey =
  getCookie("workspace-name") ??
  process.env.REACT_APP_BRANDED_APP_WORKSPACE_KEY;

export const brandedAppWorkspaceLogoUrl =
  getCookie("workspace-logo") ??
  process.env.REACT_APP_BRANDED_APP_WORKSPACE_LOGO_URL;

export const isBrandedApp = Boolean(brandedAppWorkspaceKey);
