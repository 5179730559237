import clsx from "clsx";
import React, { useRef, useState } from "react";
import {
  Container,
  ContainerProps,
  FormLabel,
  TextField,
  FormControl,
  Box,
  Typography,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { SettingsCard } from "../card/SettingsCard";
import { ActionButton } from "../button/ActionButton";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  CropImageDialog,
  CropImageDialogCrop,
} from "../dialog/CropImageDialog";

import { CardMedia } from "../card/CardMedia";
import { parseThumbnailUrl, generateThumbnailUrl } from "../../utils/thumbnail";
import { slugify } from "../../utils/misc";
import { CropScaled } from "../editor/types/legacy";
import { MealLoggingSettings } from "../meal-logging-settings/MealLoggingSettings";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";

import { useUpdateClientPortalMutation } from "./mutations/UpdateClientPortalMutation";
import { ClientPortalSettingsUploadLogoDialog } from "./ClientPortalSettingsUploadLogoDialog";
import { ClientPortalSettings_workspace$key } from "./__generated__/ClientPortalSettings_workspace.graphql";
import { useNavigate } from "react-router-dom";
import { CLIENT_HOME_ROUTE } from "../../routes/routes";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import ImageCropDialog from "../new-editor/elements/CropDialog";
import { PixelCrop } from "react-image-crop";

const useStyles = makeStyles((theme) => ({
  root: {},

  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },

  logo: {
    width: 90,
    height: 90,
    marginRight: theme.spacing(4.5),
    cursor: "pointer",
  },

  fields: {
    display: "flex",
    flexWrap: "wrap",

    "& > fieldset": {
      width: "100%",
      marginBottom: theme.spacing(3),
    },

    [theme.breakpoints.up("md")]: {
      "& > fieldset": {
        width: `calc(50% - ${theme.spacing(2)})`,
      },

      "& > fieldset:nth-child(1), & > fieldset:nth-child(4)": {
        width: "100%",
      },

      "& > fieldset:nth-child(2)": {
        marginRight: theme.spacing(3),
      },
    },
  },

  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },

  footer: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2),

    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    borderRadius: 6,
    backgroundColor: `${theme.palette.quote}7F`,

    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },

  footerText: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    opacity: 0.6,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },

  urlAdornment: {
    marginRight: theme.spacing(0.75),
    color: theme.palette.text.secondary,
    fontWeight: 500,
    lineHeight: "19px",
  },

  viewAsClient: {
    fontSize: 16,
    whiteSpace: "nowrap",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "inherit",
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(3),
    },
  },

  mealLogging: {
    width: "100%",
  },

  uploadButtons: {
    display: "flex",
    flexDirection: "column",
  },
}));

const reactPropProps = {
  minWidth: 90,
  minHeight: 90,
  keepSelection: false,
};

const cropOptions = {
  aspect: 1,
};

const getThumbnailCrop = (url: string): CropScaled => {
  const info = url && parseThumbnailUrl(url);
  const crop = info?.crop;

  return (
    crop && {
      unit: "px",
      x: crop.x,
      y: crop.y,
      width: crop.w,
      height: crop.h,
      scaleX: 0,
      scaleY: 0,
    }
  );
};

const workspaceFragment = graphql`
  fragment ClientPortalSettings_workspace on Workspace {
    ...MealLoggingSettings_workspace
    name
    slug
    message
    logoURL
    mealLogging
  }
`;

export interface ClientPortalSettingsProps
  extends Omit<ContainerProps, "children"> {
  workspace: ClientPortalSettings_workspace$key;
}

export function ClientPortalSettings(props: ClientPortalSettingsProps) {
  const { className, workspace: workspaceRef, ...other } = props;
  const workspace = useFragment(workspaceFragment, workspaceRef);
  const { name, logoURL, message, slug, mealLogging } = workspace;
  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();
  const [dirty, setDirty] = React.useState(false);
  const [settings, setSettings] = React.useState({
    name,
    logoURL,
    message,
    slug,
    mealLogging,
  });
  const [updateClientPortal, pending] = useUpdateClientPortalMutation();
  const completed = Boolean(settings.slug);
  const disabled = !dirty || pending || !completed;
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [cropOpen, setCropOpen] = React.useState(false);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [preview, setPreview] = useState(false);
  const [isCroppedImage, setIsCroppedImage] = useState(false);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const crop: CropScaled = getThumbnailCrop(settings.logoURL);
  const switchUser = useSwitchUser();

  const handleChange = React.useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      checked?: boolean,
    ) => {
      const { id, value } = event.currentTarget;

      setSettings((settings) => ({
        ...settings,
        [id]:
          typeof checked === "boolean"
            ? checked
            : id === "slug"
              ? slugify(value)
              : value,
      }));
      setDirty(true);
    },
    [],
  );

  const handleSave = React.useCallback(
    () =>
      updateClientPortal({
        variables: {
          input: settings,
        },
        onSuccess: () => {
          setDirty(false);
        },
      }),
    [settings, updateClientPortal],
  );

  const handleCropOpen = React.useCallback(() => {
    setCropOpen(true);
  }, []);

  const handleCropClose = React.useCallback(() => {
    setCropOpen(false);
  }, []);

  const handleChangeImage = React.useCallback(() => {
    setCropOpen(false);
    setUploadOpen(true);
  }, []);

  const handleCrop = React.useCallback((crop: CropImageDialogCrop) => {
    setSettings(({ logoURL, ...settings }) => ({
      ...settings,
      logoURL: generateThumbnailUrl({
        src: logoURL,
        crop: crop && {
          x: crop.x * crop.scaleY,
          y: crop.y * crop.scaleY,
          w: crop.width * crop.scaleX,
          h: crop.height * crop.scaleY,
        },
      }),
    }));
    setDirty(true);
  }, []);

  const handleUploadOpen = React.useCallback(() => {
    setUploadOpen(true);
  }, []);

  const handleUploadClose = React.useCallback(() => {
    setUploadOpen(false);
  }, []);

  const handleViewAsClientClick = React.useCallback(() => {
    switchUser(process.env.REACT_APP_SAMPLE_CLIENT_USER_ID);
  }, []);

  const handleLogoChange = React.useCallback(
    (logoURL: string) => {
      setSettings({
        ...settings,
        logoURL,
      });
      setDirty(true);
      setUploadOpen(false);
      setCropOpen(true);
    },
    [settings],
  );

  const handleRemoveLogo = React.useCallback(() => {
    setSettings({
      ...settings,
      logoURL: "",
    });
    setIsCroppedImage(false);
    setDirty(true);
  }, [settings]);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={dirty} />
      <SettingsCard
        header="Client portal settings"
        ButtonProps={{
          disabled,
          onClick: handleSave,
        }}
      >
        <Box className={s.fields}>
          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="logo"
              component="label"
              children="Logo"
            />

            <Box className={s.logoWrapper}>
              {isCroppedImage ? (
                <Box className={s.logo}>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: 90,
                      height: 90,
                    }}
                    onClick={handleCropOpen}
                  />
                </Box>
              ) : (
                settings.logoURL && (
                  <CardMedia
                    className={s.logo}
                    image={settings.logoURL?.replace(
                      "//stridist.com/img2/users",
                      "//s.stridist.com/users",
                    )}
                    onClick={handleCropOpen}
                    scale={{
                      w: 90,
                      h: 90,
                    }}
                  />
                )
              )}
              <Box className={s.uploadButtons}>
                <ActionButton onClick={handleUploadOpen}>
                  Upload new logo
                </ActionButton>
                {settings.logoURL && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleRemoveLogo}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Box>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="name"
              component="label"
              children="Name"
            />
            <TextField
              variant="outlined"
              helperText="You can use your name or the name of your business. Keep it simple."
              fullWidth
              id="name"
              value={settings.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="slug"
              component="label"
              children="Portal URL"
            />
            <TextField
              variant="outlined"
              fullWidth
              id="slug"
              error={!settings.slug}
              helperText={
                !settings.slug && "Please choose URL for your client portal"
              }
              InputProps={{
                startAdornment: (
                  <Typography component="span" className={s.urlAdornment}>
                    www.app.stridist.com/
                  </Typography>
                ),
              }}
              value={settings.slug}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel
              className={s.label}
              htmlFor="message"
              component="label"
              children="Motivational message"
            />
            <TextField
              variant="outlined"
              fullWidth
              id="message"
              value={settings.message}
              onChange={handleChange}
              required
            />
          </FormControl>
        </Box>

        <Box className={s.footer}>
          <Typography component="div" className={s.footerText}>
            You can use your sample client ({user.email}) to see what your
            clients will experience once they log in to your client portal
          </Typography>
          <ActionButton
            children="View as sample client"
            onClick={handleViewAsClientClick}
          />
        </Box>

        <FormControl component="fieldset" className={s.mealLogging}>
          <FormLabel
            className={s.label}
            component="label"
            children="Meal logging"
          />
          <MealLoggingSettings workspace={workspace} onChange={handleChange} />
        </FormControl>
      </SettingsCard>

      <ClientPortalSettingsUploadLogoDialog
        open={uploadOpen}
        onClose={handleUploadClose}
        onChange={handleLogoChange}
      />
      <ImageCropDialog
        open={cropOpen}
        onClose={handleCropClose}
        imageSrc={
          settings.logoURL &&
          generateThumbnailUrl({
            src: settings.logoURL,
            crop: null,
          })
            ?.replace("//test.stridist.com/img2", "//test-s.stridist.com")
            ?.replace("//stridist.com/img2", "//s.stridist.com")
        }
        previewCanvasRef={previewCanvasRef}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        preview={preview}
        setPreview={setPreview}
        setIsCroppedImage={setIsCroppedImage}
        children={
          <Button variant="text" color="primary" onClick={handleChangeImage}>
            Change image
          </Button>
        }
      ></ImageCropDialog>
    </Container>
  );
}
