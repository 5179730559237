import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  CircularProgress,
  debounce,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFragment, useMutation } from "react-relay/hooks";
import { graphql } from "react-relay";
import { Node } from "slate";

import { useQueryParam } from "../../hooks/useQueryParam";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { EditorCoverImageContext } from "../../hooks/useEditorCoverImage";
import { usePreview } from "../../hooks/usePreview";
import { useCurrentUser } from "../../hooks/useCurrentUser";

import { ComponentSchedule } from "../schedule/ComponentSchedule";
import {
  EditorComponentItemDisabledContext,
  CheckInComponentContext,
  useNewEditor,
  useEditorProgram,
} from "../new-editor/hooks";
import {
  getComponentSpecifics,
  getComponentDefaultTitle,
} from "../../utils/component";
import { AlertData } from "../snackbar/SnackbarAlert";
import { HabitPromptEdit } from "../habit-prompt/HabitPromptEdit";
import { HabitPrompt } from "../habit-prompt/HabitPrompt";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import { ComponentPublishButton } from "../program/ComponentPublishButton";
import { useHistoryBlock } from "../history-block/hooks";
import { CoachComponentTitle } from "../program-component/CoachComponentTitle";
import { ComponentIcon } from "../program-component/ComponentIcon";
import {
  ComponentType,
  ComponentStatus,
  CoachComponentSavingState,
  joinedComponentSlugs,
  ComponentRepeat,
  ReminderType,
} from "../../constants";
import { Schedule } from "../schedule/types";
import { colorSystem } from "../../theme";
import { ComponentTemplate } from "../component-template/ComponentTemplateDialog";
import { AccordionProps } from "../accordion/Accordion";
import { EditorDirty } from "../../hooks/useSetDirty";
import { isMobileApp } from "../../utils/mobile";

import { CoachComponent_component$key } from "./__generated__/CoachComponent_component.graphql";
import {
  CoachComponentSaveComponentMutation,
  UpsertComponentInput,
} from "./__generated__/CoachComponentSaveComponentMutation.graphql";
import { CoachComponentBar } from "./CoachComponentBar";
import { MessageComponentPanel } from "./MessageComponentPanel";
import {
  getEmptyNode,
  isEmptyContent,
  unfixLegacyContent,
} from "../editor/utils/common";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WorkoutAddButton } from "../workout/WorkoutAddButton";
import { UseComponentTemplate } from "../component-template/UseComponentTemplate";
import { EditorElementView } from "../new-editor/utils/editorUtils";
import { menuItemDisabled } from "../new-editor/utils/menuItemUtil";
import ComponentBarHeightContext from "../../contexts/ComponentBarHeightContext";
import { getCookie } from "./utils";
import JsonEditor from "../editor/JsonEditor";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../routes/routes";
import { useProgramRefetch } from "../../hooks/useProgramRefetch";
import { ProgramWeek } from "../../hooks/useProgramWeeks";

const ComponentEditor = React.lazy(
  () => import("../new-editor/ComponentEditor"),
);

const useStyles = makeStyles((theme) => {
  return {
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },

    root: {
      paddingTop: theme.spacing(6.5),
      paddingBottom: theme.spacing(8),
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(12),
        maxWidth: 1100,
      },
    },

    settings: {
      margin: theme.spacing(4, 0),
      position: "relative",
      backgroundColor: theme.palette.background.paper,
    },

    previewHeader: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(2, 0, 1.5),
    },

    icon: {
      marginRight: theme.spacing(1),
    },

    previewComponentType: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },

    previewTitle: {
      fontSize: 24,
      fontWeight: "bold",
      color: theme.palette.common.black,
      wordBreak: "break-word",
    },

    spinner: {
      color: theme.palette.activity.checkin,
      marginTop: theme.spacing(0.25),
    },

    publishing: {
      "& .MuiAlert-root": {
        boxShadow: theme.shadows[4],
        borderRadius: theme.spacing(0.5),
        paddingRight: theme.spacing(10),
        color: theme.palette.activity.checkin,
        backgroundColor: theme.palette.common.white,
      },
    },

    previewBarPublishButton: {
      fontSize: 13,
      fontWeight: "bold",
      lineHeight: "16px",
      padding: theme.spacing(1.25, 2),
      color: theme.palette.common.white,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: `${theme.palette.primary.main}4D`,
      marginRight: theme.spacing(2),

      "&.Mui-disabled": {
        color: `${colorSystem.white}4D`,
      },
    },

    editing: {
      paddingTop: theme.spacing(16),
    },

    mobileContainer: {
      paddingTop: "148px",
    },
  };
});

const componentFragment = graphql`
  fragment CoachComponent_component on Component
  @argumentDefinitions(draft: { type: "Boolean!", defaultValue: false }) {
    id
    weekId
    slug
    type
    status

    title(draft: $draft)
    content(draft: $draft)
    habitPrompt(draft: $draft)
    messageContent(draft: $draft)
    reminderType(draft: $draft)
    reminderTime(draft: $draft)
    messageTime(draft: $draft)

    days
    repeat
    duration

    ...CoachComponentBar_component @arguments(draft: $draft)
    ...ComponentPublishButton_component
    ...CoachComponentTitle_component @arguments(draft: $draft)
    ...ComponentIcon_component
  }
`;

const saveComponentMutation = graphql`
  mutation CoachComponentSaveComponentMutation($input: UpsertComponentInput!) {
    upsertComponent(input: $input) {
      component {
        ...RefreshSlug
        ...CoachComponent_component @arguments(draft: true)
        id
        weekId
      }
    }
  }
`;

type SaveInput = {
  title?: string;
  content?: string;
  status?: ComponentStatus;
  habitPrompt?: string;
  image?: string;
  weekId?: string;
  days?: boolean[];
  duration?: number;
  repeat?: ComponentRepeat;
  reminderType?: ReminderType;
  reminderTime?: string;
  messageTime?: string;
  messageContent?: string;
};

const slugRegexp = new RegExp(`/(${joinedComponentSlugs})s?/.*`);

export interface CoachComponentProps extends Omit<ContainerProps, "children"> {
  componentRef: CoachComponent_component$key;
  readOnly?: boolean;
  autoSaveDelay?: number;
  handleCloseDialog?: () => void;
}

export function CoachComponent(props: CoachComponentProps) {
  const {
    className,
    componentRef,
    readOnly,
    autoSaveDelay = 2000,
    handleCloseDialog,
  } = props;
  const navigate = useNavigate();
  const { slug } = useParams();
  const user = useCurrentUser();
  const component = useFragment(componentFragment, componentRef);
  const componentType = component.type as ComponentType;
  const defaultTitle = getComponentDefaultTitle(componentType);
  const [version, setVersion] = React.useState(Date.now());

  const autoSaveEnabled = !readOnly;
  const [savingState, setSavingState] = React.useState(
    CoachComponentSavingState.EMPTY,
  );
  const [title, setTitle] = React.useState(
    defaultTitle === component.title ? "" : component.title,
  );
  const {
    weekId,
    days,
    duration,
    repeat,
    reminderType,
    reminderTime,
    messageTime,
  } = component;
  const [schedule, setSchedule] = React.useState<Schedule>({
    weekId,
    days: days as boolean[],
    duration,
    repeat: repeat as ComponentRepeat,
    reminderType: reminderType as ReminderType,
    reminderTime,
    messageTime,
  });
  const [habitPrompt, setHabitPrompt] = React.useState(component.habitPrompt);

  const editor = useNewEditor();
  const componentContent = component.content
    ? JSON.parse(component.content)
    : null;
  const [content, setContent] = React.useState<Node[] | null>(
    componentContent &&
      !(Array.isArray(componentContent) && componentContent.length === 0)
      ? componentContent
      : [getEmptyNode()],
  );

  const devCookie = getCookie("stabilization");

  const [messageContent, setMessageContent] = React.useState<Node[] | null>(
    JSON.parse(component.messageContent) || [],
  );

  const [validationErrors, setValidationErrors] = React.useState<any>(null);
  const [dirty, setDirty] = React.useState(false);
  const [saved, setSaved] = React.useState(true);
  const [preview, setPreview] = usePreview();
  const location = useLocation();
  const [previewQueryParam] = useQueryParam("preview", false);
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const [unsafe, historyBlockUpdate] = useHistoryBlock();
  const [schedulePanelOpen, setSchedulePanelOpen] = React.useState(false);
  const [promptPanelOpen, setPromptPanelOpen] = React.useState(false);
  const [messagePanelOpen, setMessagePanelOpen] = React.useState(false);

  const programRefetch = useProgramRefetch();

  const program = useEditorProgram();
  const programWeeks: Array<ProgramWeek> = program.weeks.edges.map(
    ({ node }) => ({
      id: node.id,
      week: node.week,
    }),
  );

  React.useEffect(() => {
    if (promptPanelOpen && (schedulePanelOpen || messagePanelOpen)) {
      setPromptPanelOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulePanelOpen, messagePanelOpen]);

  React.useEffect(() => {
    if (schedulePanelOpen && (promptPanelOpen || messagePanelOpen)) {
      setSchedulePanelOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptPanelOpen, messagePanelOpen]);

  React.useEffect(() => {
    if (messagePanelOpen && (schedulePanelOpen || promptPanelOpen)) {
      setMessagePanelOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulePanelOpen, promptPanelOpen]);

  React.useEffect(() => {
    historyBlockUpdate(dirty);
  }, [dirty, historyBlockUpdate]);

  const setSnackAlert = React.useCallback(
    (data: AlertData) => {
      if (!previewQueryParam) {
        snackAlert(data);
      }
    },
    [previewQueryParam, snackAlert],
  );

  const { typeName } = getComponentSpecifics(componentType);

  const handleCloseClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      unsafe(() => {
        sessionStorage.setItem("backButtonClicked", "true");
        navigate(-1);
      });
    },
    [location.pathname, unsafe],
  );

  const [textFieldContent, setTextFieldContent] = React.useState<string>(
    JSON.stringify(content, null, 2),
  );

  const save = React.useCallback(
    (data: SaveInput) => {
      /*
        *** Drafting logic must be refactored ***

        It is intentional. The idea of using "debounce" function
          inside React component wasn't a good one. That mechanism
          should be overwritten (and probably generalized) as soon
          as we have a chance.
      */
      setDirty(false);
      setSavingState(CoachComponentSavingState.SAVING);

      const input: UpsertComponentInput = {
        id: component.id,
        ...data,
      };

      if (!input.title) {
        input.title = defaultTitle;
      }

      saveComponent({
        variables: { input },
        onCompleted: (_, errors) => {
          if (errors && errors.length) {
            const timeError =
              errors[0].message.includes('null value in column "time"') &&
              "Field time is required";
            setDirty(true);
            setSnackAlert({
              severity: "error",
              message: timeError || errors[0].message || "Error occurred.",
            });
            setValidationErrors((errors[0] as any).state);
          } else {
            setSaved(true);
            setValidationErrors(null);
            setSavingState(CoachComponentSavingState.SAVED);
            setDirty(false);
            historyBlockUpdate(false);
            setSnackAlert(null);

            if (data.status === ComponentStatus.PUBLISHED) {
              closeAfterSave();

              if (data.duration > 1) {
                // refetch program data on component edit with duration more than 1
                // to update relay store with new component ids (duplicated components in other weeks)
                // TODO drop with migration to REST API or refactoring of component scheduling
                programRefetch();
              }
            }
          }
        },
        onError: (error) => {
          setDirty(true);
          console.error(error);
          setSnackAlert({
            severity: "error",
            message: "Internal server error.",
          });
          setValidationErrors(null);
          setSavingState(CoachComponentSavingState.EMPTY);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSave = React.useMemo(() => debounce(save, autoSaveDelay), []);

  const getDataToSave = React.useCallback(
    (update: SaveInput = {}) => {
      const isContentUpdated = !!update.content;
      if (isContentUpdated) {
        update.content = JSON.stringify(
          unfixLegacyContent(JSON.parse(update.content)),
        );
      }
      return {
        ...schedule,
        title,
        habitPrompt,
        ...(!isContentUpdated && {
          content: JSON.stringify(unfixLegacyContent(content)),
        }),
        ...update,
      };
    },
    [content, schedule, title, habitPrompt],
  );

  const handleChange = React.useCallback(
    (update: SaveInput = {}) => {
      setDirty(true);
      setSaved(false);

      if (autoSaveEnabled) {
        const data = getDataToSave(update);

        handleSave(data);
      }
    },
    [autoSaveEnabled, getDataToSave, handleSave],
  );

  const handlePublish = React.useCallback(() => {
    sessionStorage.setItem("backButtonClicked", "true");

    setSnackAlert({
      severity: "success",
      message: "Publishing story...",
      onClose: undefined,
      autoHideDuration: null,
      icon: <CircularProgress size={16} className={s.spinner} />,
      className: s.publishing,
    });

    save(
      getDataToSave({
        status: ComponentStatus.PUBLISHED,
      }),
    );
  }, [setSnackAlert, s.spinner, s.publishing, save, getDataToSave]);

  const handleTitleChange = React.useCallback(
    (event) => {
      const title = event.target.value;

      setTitle(title);
      handleChange({ title });
    },
    [handleChange],
  );

  const handleSchedulePanelChange: AccordionProps["onChange"] =
    React.useCallback(
      (_, expanded) => {
        if (schedulePanelOpen !== expanded) {
          setSchedulePanelOpen(expanded);
        }
      },
      [schedulePanelOpen],
    );

  const handlePromptPanelChange: AccordionProps["onChange"] = React.useCallback(
    (_, expanded) => {
      if (promptPanelOpen !== expanded) {
        setPromptPanelOpen(expanded);
      }
    },
    [promptPanelOpen],
  );

  const handleMessagePanelChange: AccordionProps["onChange"] =
    React.useCallback(
      (_, expanded) => {
        if (messagePanelOpen !== expanded) {
          setMessagePanelOpen(expanded);
        }
      },
      [messagePanelOpen],
    );

  const handleScheduleChange = React.useCallback(
    (schedule: Schedule) => {
      setDirty(true);
      setSchedule(schedule);

      handleChange(schedule);
    },
    [handleChange],
  );

  const handleHabitPromptChange = React.useCallback(
    (habitPrompt: string) => {
      setDirty(true);
      setHabitPrompt(habitPrompt);

      handleChange({ habitPrompt });
    },
    [handleChange],
  );

  const handleMessageContentChange = React.useCallback(
    (value: Node[] | null) => {
      setDirty(true);
      setMessageContent(value);
      handleChange({ messageContent: JSON.stringify(value) });
    },
    [handleChange],
  );

  const handleContentChange = React.useCallback(
    (value: Node[] | null) => {
      setContent(value);
      handleChange({ content: JSON.stringify(value) });
    },
    [handleChange],
  );

  const handleCoverImageChange = React.useCallback(
    (image: string) => {
      setDirty(true);
      handleChange({ image });
    },
    [handleChange],
  );

  const handleContentTemplate = React.useCallback(
    (template: ComponentTemplate) => {
      setTitle(template.title);
      setHabitPrompt(template.habitPrompt);
      setContent(JSON.parse(template.content));
      setVersion(Date.now());

      handleChange(template);
    },
    [handleChange],
  );

  const [saveComponent] = useMutation<CoachComponentSaveComponentMutation>(
    saveComponentMutation,
  );
  const closeAfterSave = React.useCallback(() => {
    const message = `${typeName} saved successfully.`;
    const newPath = location.pathname.replace(slugRegexp, "");

    setPreview(null);

    unsafe(() => navigate(newPath, { state: { successMessage: message } }));
  }, [location.pathname, setPreview, typeName, unsafe]);

  const isEmpty = React.useMemo(() => isEmptyContent(content), [content]);

  const previewSrc = React.useMemo(() => {
    const param = "preview=true";
    const search = location.search
      ? `${location.search}&${param}`
      : `?${param}`;
    const path = `${location.pathname.substring(
      0,
      location.pathname.lastIndexOf("/"),
    )}/${component.slug}`;

    return `${path}${search}`;
  }, [location.pathname, location.search, component.slug]);

  const publishButton = React.useMemo(
    () => (
      <ComponentPublishButton
        className={s.previewBarPublishButton}
        componentRef={component}
        onClick={handlePublish}
        empty={isEmpty}
        dirty={dirty && !saved}
      />
    ),
    [
      component,
      dirty,
      handlePublish,
      isEmpty,
      s.previewBarPublishButton,
      saved,
    ],
  );

  const isDisabledMenuItem = (type: string) => menuItemDisabled(type, content);

  const [componentBarHeight, setComponentBarHeight] = React.useState(0);
  const componentBarHeightContextValue = {
    componentBarHeight,
    setComponentBarHeight,
  };

  const theme = useTheme();

  return (
    <ComponentBarHeightContext.Provider value={componentBarHeightContextValue}>
      <EditorDirty.Provider value={setDirty}>
        <EditorCoverImageContext.Provider value={handleCoverImageChange}>
          <EditorComponentItemDisabledContext.Provider
            value={isDisabledMenuItem}
          >
            <CheckInComponentContext.Provider
              value={{
                view: previewQueryParam
                  ? EditorElementView.Preview
                  : EditorElementView.Coach,
              }}
            >
              {preview ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: theme.spacing(PREVIEW_BAR_SPACING),
                    height: "100vh",
                    width: "100%",
                    my: "auto",
                  }}
                >
                  <PreviewBar actions={publishButton} />
                  <PreviewBox
                    src={`${COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", slug)}/${component.type.toLowerCase()}s/${component.slug}/?preview=true`}
                  />
                </Box>
              ) : (
                <>
                  <Container
                    maxWidth="md"
                    className={clsx(
                      s.root,
                      className,
                      !readOnly && !previewQueryParam && s.editing,
                    )}
                  >
                    {!previewQueryParam && (
                      <>
                        <CoachComponentBar
                          componentRef={component}
                          onSaveClick={handlePublish}
                          onCloseClick={handleCloseDialog}
                          savingState={savingState}
                          isEmpty={isEmpty}
                          isDirty={dirty}
                          editor={editor}
                        />

                        <Typography color="textSecondary" gutterBottom>
                          {typeName}
                        </Typography>

                        <CoachComponentTitle
                          component={component}
                          error={Boolean(
                            validationErrors && validationErrors.title,
                          )}
                          onChange={handleTitleChange}
                          value={title}
                          placeholder={`Give Your ${typeName} a Title`}
                          defaultValue={defaultTitle}
                        />

                        <div className={s.settings}>
                          <ComponentSchedule
                            layout="expansion"
                            componentType={componentType}
                            schedule={schedule}
                            onChange={handleScheduleChange}
                            panelOpen={schedulePanelOpen}
                            onPanelChange={handleSchedulePanelChange}
                            programWeeks={programWeeks}
                          />
                        </div>

                        {component.type === ComponentType.HABIT && (
                          <div className={s.settings}>
                            <HabitPromptEdit
                              prompt={habitPrompt}
                              onChange={handleHabitPromptChange}
                              open={promptPanelOpen}
                              onPanelChange={handlePromptPanelChange}
                            />
                          </div>
                        )}

                        {component.type === ComponentType.MESSAGE && (
                          <div className={s.settings}>
                            <MessageComponentPanel
                              componentId={component.id}
                              content={messageContent}
                              onChange={handleMessageContentChange}
                              open={messagePanelOpen}
                              onPanelChange={handleMessagePanelChange}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {previewQueryParam && (
                      <>
                        <Box className={s.previewHeader}>
                          <ComponentIcon
                            className={s.icon}
                            componentRef={component}
                            variant="icon"
                          />
                          <Typography
                            className={s.previewComponentType}
                            variant="body1"
                            component="span"
                            children={typeName}
                          />
                        </Box>
                        <Typography
                          className={s.previewTitle}
                          variant="h1"
                          children={title}
                        />

                        {component.type === ComponentType.HABIT && (
                          <HabitPrompt completed={false} prompt={habitPrompt} />
                        )}
                      </>
                    )}

                    {componentType !== ComponentType.MESSAGE && (
                      <>
                        <ComponentEditor
                          key={version}
                          editor={editor}
                          value={content}
                          placeholder="Add your content here"
                          onChange={handleContentChange}
                          readOnly={readOnly || previewQueryParam}
                          disabled={Boolean(previewQueryParam)}
                          inlineToolbar
                          sidebar
                          deserialize
                          preNormalize
                          multiline
                          autoFocus
                          delay={250}
                          topToolbar={user.topToolbar}
                          useStickyTop
                        >
                          {component.type === ComponentType.WORKOUT &&
                            !previewQueryParam && <WorkoutAddButton />}
                          {component.type !== ComponentType.MESSAGE &&
                            !previewQueryParam &&
                            isEmptyContent(content) && (
                              <UseComponentTemplate
                                onApplyTemplate={handleContentTemplate}
                                componentType={component.type as ComponentType}
                              />
                            )}
                        </ComponentEditor>
                        {!previewQueryParam && devCookie === "true" && (
                          <JsonEditor
                            content={content}
                            onContentChange={(newContent) => {
                              handleContentChange(newContent);
                              setVersion(Date.now());
                            }}
                          />
                        )}
                      </>
                    )}
                  </Container>
                </>
              )}
            </CheckInComponentContext.Provider>
          </EditorComponentItemDisabledContext.Provider>
        </EditorCoverImageContext.Provider>
      </EditorDirty.Provider>
    </ComponentBarHeightContext.Provider>
  );
}
