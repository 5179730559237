/**
 * @generated SignedSource<<0983054a609ed80c65d339afebc179fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProgramDefaultView = "CALENDAR" | "LIST" | "%future added value";
export type ProgramStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProgramSettings_program$data = {
  readonly defaultView: ProgramDefaultView;
  readonly description: string | null | undefined;
  readonly id: string;
  readonly imageURL: string | null | undefined;
  readonly inviteCode: {
    readonly id: string;
    readonly startDate: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"InviteLinkSettings_inviteCode">;
  } | null | undefined;
  readonly length: number;
  readonly name: string;
  readonly slug: string;
  readonly status: ProgramStatus;
  readonly " $fragmentType": "ProgramSettings_program";
};
export type ProgramSettings_program$key = {
  readonly " $data"?: ProgramSettings_program$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramSettings_program">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramSettings_program",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "length",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultView",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InviteCode",
      "kind": "LinkedField",
      "name": "inviteCode",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InviteLinkSettings_inviteCode"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Program",
  "abstractKey": null
};
})();

(node as any).hash = "3a524b1ea09371398e3bb2a9b3802326";

export default node;
