import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

import { useUploadFile } from "../../hooks/useUploadFile";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { AssetType, SOMETHING_WENT_WRONG } from "../../constants";
import { useNativeDropzone } from "../../utils/device";
import { Accept } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    backgroundColor: `${theme.palette.quote}4F`,
    padding: theme.spacing(1.5),
  },

  label: {
    display: "block",
  },

  buttonText: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
  },

  buttonLink: {
    color: theme.palette.primary.main,
  },
}));

export type DropzoneUploadButtonClassKey =
  | "root"
  | "buttonText"
  | "buttonLink"
  | "text";

export interface DropzoneUploadButtonProps
  extends Omit<ButtonProps, "onChange" | "classes"> {
  onChange?: (value: string) => void;
  assetType: false | AssetType;
  mimeType?: Accept;
  inputName: string;
  assetDescription?: string;
  text?: React.ReactNode;
  textClassName?: string;
  classes?: Partial<ClassNameMap<DropzoneUploadButtonClassKey>>;
}

export function DropzoneUploadButton(props: DropzoneUploadButtonProps) {
  const {
    className,
    onChange,
    assetType,
    mimeType,
    inputName,
    assetDescription = "a file",
    text,
    textClassName,
    classes,
    ...other
  } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const snackAlert = useSnackAlert();
  const [uploading, setUploading] = React.useState(false);
  const [uploadFile] = useUploadFile({
    id: user.id,
    getAssetType: () => assetType,
  });

  const onUpload = React.useCallback(
    (file) => {
      setUploading(true);

      uploadFile(file[0])
        .then(({ url }) => {
          setUploading(false);
          if (onChange) {
            onChange(url);
          }
        })
        .catch((error) => {
          console.error(error);
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          });
          setUploading(false);
        });
    },
    [onChange, snackAlert, uploadFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useNativeDropzone({
    onDrop: onUpload,
    accept: mimeType,
  });

  return (
    <Button
      {...(getRootProps() as any)}
      color="inherit"
      variant="outlined"
      className={clsx(s.root, className, classes?.root)}
      classes={{ label: s.label }}
      fullWidth
      {...other}
    >
      <input type="file" name={inputName} {...getInputProps()} />

      <Typography
        component="span"
        className={clsx(s.buttonText, classes?.buttonText)}
      >
        {uploading ? (
          "Uploading..."
        ) : isDragActive ? (
          "Drop the file here..."
        ) : text ? (
          <Typography
            className={clsx(s.buttonText, textClassName, classes?.text)}
          >
            {text}
          </Typography>
        ) : (
          <>
            Drag {assetDescription} or{" "}
            <Typography
              component="span"
              className={clsx(s.buttonText, s.buttonLink, classes?.buttonLink)}
            >
              upload instead.
            </Typography>
          </>
        )}
      </Typography>
    </Button>
  );
}
