import clsx from "clsx";
import React, { useState, startTransition } from "react";
import {
  Container,
  Box,
  ContainerProps,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import {
  useFragment,
  useMutation,
  usePaginationFragment,
} from "react-relay/hooks";

import { useQueryParam } from "../../hooks/useQueryParam";
import { useMediaDnDSupported } from "../../hooks/useMediaDnDSupported";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { usePreview } from "../../hooks/usePreview";
import { useLocation, useSearchParams } from "react-router-dom";
import { useProgramEnrollmentsList } from "../../hooks/useProgramEnrollmentsList";
import { ShowCoversContext } from "../../hooks/useShowCovers";
import { EnableColorsContext } from "../../hooks/useEnableColors";
import { ProgramDetails } from "../program/ProgramDetails";
import {
  ProgramDetailsViewButton,
  ProgramDetailsViewMode,
} from "../program/ProgramDetailsViewButton";
import {
  ProgramDetailsFilters,
  defaultFilters,
  Filters,
} from "../program/ProgramDetailsFilters";
import {
  ProgramStartDateViewButton,
  ProgramStartDateViewButtonProps,
} from "../program/ProgramStartDateViewButton";
import { EditorProgramContext } from "../new-editor/hooks";
import {
  generateUniqueServerID,
  useInterruptiveMutation,
} from "../dirty-transaction/hooks";
import { DirtyTransaction } from "../dirty-transaction/DirtyTransaction";
import {
  ProgramStartDateView,
  ProgramStartDateViewDetails,
} from "../program/ProgramStartDateViewButton";
import { ToggleCoversButton } from "../button/ToggleCoversButton";
import { ToggleColorsButton } from "../button/ToggleColorsButton";
import { UnlockAllContentButton } from "../button/UnlockAllContentButton";
import { toEnum } from "../../utils/misc";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { isMobileApp } from "../../utils/mobile";

import { CurriculumEditScreen_program$key } from "./__generated__/CurriculumEditScreen_program.graphql";
import {
  CurriculumEditScreenMoveWeekMutation,
  WeekMoveDirectionType,
} from "./__generated__/CurriculumEditScreenMoveWeekMutation.graphql";
import { useHistoryBlock } from "../history-block/hooks";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import { LockOpen, Lock } from "lucide-react";
import { LoadingButton } from "@mui/lab";
import MinimizedTooltip from "../tooltip/MinimizedTooltip";
import { CurriculumEditScreenComponentsLockedStatusMutation } from "./__generated__/CurriculumEditScreenComponentsLockedStatusMutation.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";
import { ProgramRefetchContext } from "../../hooks/useProgramRefetch";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    maxWidth: 1500,
  },

  calendar: {},

  list: {
    maxWidth: 1116,
  },

  filters: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: isMobileApp ? "-24px" : "",
  },

  filtersRight: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    rowGap: theme.spacing(1),
  },
}));

const addWeekMutation = graphql`
  mutation CurriculumEditScreenAddWeekMutation(
    $input: AddWeekInput!
    $first: Int
  ) {
    addWeek(input: $input) {
      program {
        ...CurriculumEditScreen_program @arguments(first: $first, after: null)
      }
    }
  }
`;

const deleteWeekMutation = graphql`
  mutation CurriculumEditScreenDeleteWeekMutation(
    $input: DeleteWeekInput!
    $first: Int
  ) {
    deleteWeek(input: $input) {
      program {
        ...CurriculumEditScreen_program @arguments(first: $first, after: null)
      }
    }
  }
`;

const duplicateWeekMutation = graphql`
  mutation CurriculumEditScreenDuplicateWeekMutation(
    $input: DublicateWeekInput!
    $first: Int
  ) {
    dublicateWeek(input: $input) {
      program {
        ...CurriculumEditScreen_program @arguments(first: $first, after: null)
      }
    }
  }
`;

const moveWeekMutation = graphql`
  mutation CurriculumEditScreenMoveWeekMutation(
    $input: MoveWeekInput!
    $first: Int
  ) {
    moveWeek(input: $input) {
      program {
        ...CurriculumEditScreen_program @arguments(first: $first, after: null)
      }
    }
  }
`;

const enrollmentsFragment = graphql`
  fragment CurriculumEditScreen_enrollments on EnrollmentConnection {
    edges {
      node {
        client {
          displayName
        }
      }
    }
  }
`;

const updateComponentsLockedStatusMutation = graphql`
  mutation CurriculumEditScreenComponentsLockedStatusMutation(
    $input: UpdateComponentsLockedStatusByProgramIdInput!
  ) {
    updateComponentsLockedStatusByProgramId(input: $input) {
      components {
        id
        locked
      }
    }
  }
`;

const programFragment = graphql`
  fragment CurriculumEditScreen_program on Program
  @refetchable(queryName: "CurriculumEditScreenRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 3 }
    after: { type: "String" }
  ) {
    weeks: weeks(first: $first, after: $after)
      @connection(key: "Program_weeks", filters: []) {
      edges {
        node {
          week
          id
          positions
          components(archived: true) {
            ...ProgramCalendarComponentButtons_component
            ...ProgramCalendarComponent_component
            ...WeekComponentList_components
            id
            type
            status
            weekId
            duration
            repeat
            days
            locked
          }
          ...WeekCard_week @relay(plural: true)
          ...ProgramCalendarWeek_week
        }
      }
    }
    id
    name
    slug
    defaultView
    length
  }
`;

export interface CurriculumViewOptions {
  view: ProgramDetailsViewMode;
  dateDetails: ProgramStartDateViewDetails;
  showCovers: boolean;
  enableColors: boolean;
}

export interface CurriculumEditScreenProps
  extends Omit<ContainerProps, "children"> {
  programRef?: CurriculumEditScreen_program$key;
}

export function CurriculumEditScreen(props: CurriculumEditScreenProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const s = useStyles();
  const { programRef } = props;
  const {
    data: program,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment(programFragment, programRef);
  const enrollmentsRef = useProgramEnrollmentsList() as any;
  const [searchParams] = useSearchParams();
  const component = searchParams.get("component");

  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const [unsafe] = useHistoryBlock();
  const location = useLocation();
  const [preview] = usePreview();
  const [filters, setFilters] = React.useState(defaultFilters);
  const canUseCalendar = useMediaDnDSupported() && !isSm;
  const { defaultView, id: programId } = program;
  const [loading, setLoading] = React.useState(false);
  const [countWeeks, setCountWeeks] = React.useState(3);

  const snackAlert = useSnackAlert();

  const [addWeek, addWeekInFlight] = useMutation(addWeekMutation);

  const [deleteWeek] = useInterruptiveMutation(deleteWeekMutation);

  const [dublicateWeek, dublicateWeekInFlight] = useMutation(
    duplicateWeekMutation,
  );

  const [moveWeek] =
    useMutation<CurriculumEditScreenMoveWeekMutation>(moveWeekMutation);

  const [updateComponents] =
    useMutation<CurriculumEditScreenComponentsLockedStatusMutation>(
      updateComponentsLockedStatusMutation,
    );

  const setErrorMessage = React.useCallback(
    (error: string) => {
      snackAlert({
        severity: "error",
        message: error,
      });
    },
    [snackAlert],
  );

  const handleAddWeek = React.useCallback(() => {
    const id = generateUniqueServerID("Week");

    addWeek({
      variables: {
        input: {
          id,
          programId: program.id,
        },
        first: countWeeks + 1,
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.error(errors);
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Added successfully",
          });
        }
      },
    });
  }, [addWeek, program.id, snackAlert, countWeeks]);

  const handleDeleteWeek = React.useCallback(
    (event, setMoreMenuEl) => {
      const { id } = event.currentTarget.dataset;
      setMoreMenuEl(null);

      deleteWeek({
        variables: { input: { id }, first: countWeeks },
        onCompleted: (data, errors) => {
          if (errors) {
            console.error(errors);
            snackAlert({
              severity: "error",
              message: "Error occurred.",
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Deleted successfully",
            });
          }
        },
      });
    },
    [deleteWeek, snackAlert, countWeeks],
  );

  const handleDuplicateWeek = React.useCallback(
    (event, closeMoreMenu) => {
      const { id } = event.currentTarget.dataset;

      dublicateWeek({
        variables: {
          input: {
            id,
            programId: program.id,
          },
          first: countWeeks,
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.error(errors);
            snackAlert({
              severity: "error",
              message: "Error occurred.",
            });
          } else {
            closeMoreMenu();
            snackAlert({
              severity: "success",
              message: "Created successfully",
            });
          }
        },
      });
    },
    [dublicateWeek, program.id, snackAlert, countWeeks],
  );

  const handleMove = React.useCallback(
    (event, closeMoreMenu) => {
      const { id, direction: _direction } = event.currentTarget.dataset;
      const direction: WeekMoveDirectionType = _direction.toUpperCase();
      const input = {
        id,
        direction,
      };

      moveWeek({
        variables: { input, first: countWeeks },
        onCompleted(_, errors) {
          if (errors && errors.length) {
            setErrorMessage(errors[0].message || "Error occurred.");
          } else {
            snackAlert({
              severity: "success",
              message: "Week successfully moved",
            });
          }
        },
        updater: (store) => {},
      });
      closeMoreMenu();
    },
    [moveWeek, setErrorMessage, snackAlert, countWeeks],
  );

  const [updateLockingLoading, setUpdateLockingLoading] = useState(false);

  const handlelLockAll = React.useCallback(
    (mode: boolean) => {
      setUpdateLockingLoading(true);

      updateComponents({
        variables: {
          input: {
            programId,
            locked: mode,
          },
        },
        onCompleted(_, errors) {
          setUpdateLockingLoading(false);

          if (errors && errors.length) {
            setErrorMessage(errors[0].message || "Error occurred.");
          } else {
            snackAlert({
              severity: "success",
              message: `Components ${mode ? "locked" : "unlocked"} successfully`,
            });
          }
        },
        updater: (store) => {},
      });
    },
    [program.weeks.edges, updateComponents, setErrorMessage, snackAlert],
  );

  const curriculumViewKey = "curriculum-view";
  const curriculumViewStorageKey = `${curriculumViewKey}-${program.id}`;
  const initialViewOptions: CurriculumViewOptions = React.useMemo(
    () => ({
      view: toEnum(
        defaultView,
        ProgramDetailsViewMode,
        ProgramDetailsViewMode.LIST,
      ),
      dateDetails: {
        view: ProgramStartDateView.ANY,
      },
      showCovers: false,
      enableColors: false,
    }),
    [defaultView],
  );
  const [savedViewOptions, setSavedViewOptions] =
    useLocalStorage<CurriculumViewOptions>(
      curriculumViewStorageKey,
      initialViewOptions,
    );
  const [queryViewOptions, setQueryViewOptions] = useQueryParam(
    curriculumViewKey,
    JSON.stringify(savedViewOptions),
    {
      silent: true,
    },
  );
  const viewOptions: CurriculumViewOptions = JSON.parse(queryViewOptions);

  React.useEffect(() => {
    if (viewOptions.dateDetails.view === ProgramStartDateView.CLIENT) {
      const clientName = viewOptions.dateDetails.clientName;
      const clientNames = enrollments.edges.map(
        ({ node: { client } }) => client.displayName,
      );

      if (!clientNames.includes(clientName)) {
        const resetViewOptions = {
          view: ProgramDetailsViewMode.CALENDAR,
          dateDetails: {
            view: ProgramStartDateView.ANY,
          },
          showCovers: false,
          enableColors: false,
        };
        setQueryViewOptions(JSON.stringify(resetViewOptions));
      }
    }
  }, [
    enrollments,
    initialViewOptions,
    setQueryViewOptions,
    viewOptions.dateDetails,
    viewOptions.dateDetails.clientName,
  ]);

  const viewMode: ProgramDetailsViewMode = canUseCalendar
    ? viewOptions.view
    : ProgramDetailsViewMode.LIST;
  const startDateViewDetails: ProgramStartDateViewDetails = canUseCalendar
    ? viewOptions.dateDetails
    : { view: ProgramStartDateView.ANY };
  const Wrapper =
    viewMode === ProgramDetailsViewMode.CALENDAR
      ? DirtyTransaction
      : React.Fragment;

  const handleFiltersChange = React.useCallback((filters: Filters) => {
    setFilters(filters);
  }, []);

  const handleViewModeChange = React.useCallback(
    (event, view) => {
      unsafe(() =>
        setQueryViewOptions(JSON.stringify({ ...viewOptions, view })),
      );
    },
    [setQueryViewOptions, unsafe, viewOptions],
  );

  const handleStartDateViewDetailsChange: ProgramStartDateViewButtonProps["onChange"] =
    React.useCallback(
      (dateDetails) => {
        unsafe(() =>
          setQueryViewOptions(JSON.stringify({ ...viewOptions, dateDetails })),
        );
      },
      [setQueryViewOptions, unsafe, viewOptions],
    );

  const handleShowCoversToggle = React.useCallback(
    (value: boolean) => {
      unsafe(() =>
        setQueryViewOptions(
          JSON.stringify({
            ...viewOptions,
            showCovers: !viewOptions.showCovers,
          }),
        ),
      );
    },
    [setQueryViewOptions, unsafe, viewOptions],
  );

  const handleEnableColorsToggle = React.useCallback(
    (value: boolean) => {
      unsafe(() =>
        setQueryViewOptions(
          JSON.stringify({
            ...viewOptions,
            enableColors: !viewOptions.enableColors,
          }),
        ),
      );
    },
    [setQueryViewOptions, unsafe, viewOptions],
  );

  const handleMoreClick = React.useCallback(() => {
    setCountWeeks(countWeeks + 3);
    setLoading(true);
    loadNext(3, {
      onComplete: () => setLoading(false),
    });
  }, [loadNext, setLoading, countWeeks]);

  const onComponentAdd = () => {
    // wait until the component editor opens before refetching
    // TODO drop with migration to REST API
    setTimeout(() => {
      startTransition(() => {
        refetch({ first: countWeeks }, RELAY_LAZY_LOAD_COMMON_CONFIG);
      });
    }, 500);
  };

  React.useEffect(() => {
    if (sessionStorage.getItem("backButtonClicked") === "true") {
      if (hasNext && !loading) {
        handleMoreClick();
      }
    }
    if (hasNext === false) {
      sessionStorage.setItem("backButtonClicked", "false");
    }
  }, [handleMoreClick, hasNext, loading]);

  React.useEffect(() => {
    if (JSON.stringify(savedViewOptions) !== JSON.stringify(viewOptions)) {
      setSavedViewOptions(viewOptions);
    }
  }, [savedViewOptions, setSavedViewOptions, viewOptions]);

  return (
    <ProgramRefetchContext.Provider value={onComponentAdd}>
      {preview && !component ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: theme.spacing(PREVIEW_BAR_SPACING),
            height: `calc(100vh - ${theme.spacing(PREVIEW_BAR_SPACING)})`,
            width: "100vw",
          }}
        >
          <PreviewBar />
          <PreviewBox src={`${location.pathname}/preview?week=1`} />
        </Box>
      ) : (
        <Container
          className={clsx(s.root, {
            [s.calendar]: viewMode === ProgramDetailsViewMode.CALENDAR,
            [s.list]: viewMode === ProgramDetailsViewMode.LIST,
          })}
        >
          <Box className={s.filters}>
            {canUseCalendar && (
              <ProgramDetailsViewButton
                value={viewMode}
                onChange={handleViewModeChange}
              />
            )}
            <Box className={s.filtersRight}>
              <ProgramStartDateViewButton
                value={startDateViewDetails}
                onChange={handleStartDateViewDetailsChange}
                fullWidth={isSm}
              />

              {viewMode === ProgramDetailsViewMode.LIST && !isMobileApp && (
                <ToggleCoversButton
                  toggleFlag={viewOptions.showCovers}
                  onToggle={handleShowCoversToggle}
                  fullWidth={isSm}
                />
              )}

              {viewMode === ProgramDetailsViewMode.CALENDAR && (
                <ToggleColorsButton
                  toggleFlag={viewOptions.enableColors}
                  onToggle={handleEnableColorsToggle}
                  fullWidth={isSm}
                />
              )}

              <ProgramDetailsFilters
                filters={filters}
                onChange={handleFiltersChange}
              />
              <Box sx={{ marginLeft: "auto" }}>
                {updateLockingLoading ? (
                  <LoadingButton
                    loading
                    variant="text"
                    color={"primary"}
                    sx={{ minWidth: 128 }}
                  >
                    Loading
                  </LoadingButton>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <MinimizedTooltip
                      placement="bottom"
                      title={"Unlock all content"}
                      delayNextEnter={false}
                    >
                      <Button
                        onClick={() => handlelLockAll(false)}
                        sx={{ gap: 0.5 }}
                      >
                        <LockOpen />
                      </Button>
                    </MinimizedTooltip>
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      All content
                    </Typography>
                    <MinimizedTooltip
                      placement="bottom"
                      title={"Lock all content"}
                      delayNextEnter={false}
                    >
                      <Button
                        onClick={() => handlelLockAll(true)}
                        sx={{ gap: 0.5 }}
                      >
                        <Lock />
                      </Button>
                    </MinimizedTooltip>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <EditorProgramContext.Provider value={program}>
            <ShowCoversContext.Provider value={viewOptions.showCovers}>
              <EnableColorsContext.Provider value={viewOptions.enableColors}>
                <Wrapper>
                  <ProgramDetails
                    programRef={program}
                    viewMode={viewMode}
                    filters={filters}
                    startDate={startDateViewDetails.date}
                    length={program.length}
                    weeks={program.weeks}
                    id={program.id}
                    slug={program.slug}
                    handleAddWeek={handleAddWeek}
                    addWeekInFlight={addWeekInFlight}
                    handleDeleteWeek={handleDeleteWeek}
                    handleDuplicateWeek={handleDuplicateWeek}
                    dublicateWeekInFlight={dublicateWeekInFlight}
                    handleMove={handleMove}
                    hasMore={hasNext}
                    handleMoreClick={handleMoreClick}
                    loading={loading}
                  />
                </Wrapper>
              </EnableColorsContext.Provider>
            </ShowCoversContext.Provider>
          </EditorProgramContext.Provider>
        </Container>
      )}
    </ProgramRefetchContext.Provider>
  );
}
