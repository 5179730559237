import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { maybePluralize } from "../../utils/text";
import { CardAvatar } from "../card/CardAvatar";

import { WorkoutExerciseClientResult } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },

  text: {
    flexGrow: 1,
  },

  primaryText: {
    fontSize: 18,
    fontWeight: 600,
  },

  secondaryText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));

export interface WorkoutExerciseHistoryClientProps extends BoxProps {
  client: WorkoutExerciseClientResult;
  onClientSelect?: (id: string) => void;
}

export function WorkoutExerciseHistoryClient(
  props: WorkoutExerciseHistoryClientProps,
) {
  const s = useStyles();
  const { className, client, onClientSelect, ...other } = props;
  const { id, photoURL, displayName, email, results } = client;

  const handleClick = React.useCallback(() => {
    if (onClientSelect) {
      onClientSelect(id);
    }
  }, [id, onClientSelect]);

  return (
    <Box className={clsx(s.root, className)} onClick={handleClick} {...other}>
      <CardAvatar className={s.avatar} src={photoURL} />
      <Box className={s.text}>
        <Typography className={s.primaryText}>{displayName}</Typography>
        <Typography className={s.secondaryText}>{email}</Typography>
      </Box>
      <Typography className={s.secondaryText}>
        {results ? maybePluralize(results, "result") : "No results"}
      </Typography>
    </Box>
  );
}
