import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { colorSystem } from "../other/colorUtil";
import { FileRejection, useDropzone, ErrorCode } from "react-dropzone";
import { getCustomErrorMessage } from "../other/reactDropzoneUtil";
import FileRejectionHelperText from "./FileRejectionHelperText";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useMutation } from "react-relay";
import { createUploadUrlMutation } from "../modals/EmbedFileDialog";
import { uploadFile } from "../../../utils/file";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Icons } from "../../plate-ui/Icons/icons";

export interface FilePreviewBoxProps {
  file: FileInfo;
  onFileDelete?: () => void;
  disabled?: boolean;
}

type PreviewBoxComponent = React.ComponentType<FilePreviewBoxProps>;

const DefaultPreviewBox: PreviewBoxComponent = ({
  file,
  onFileDelete,
  disabled,
}) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ mt: 1 }}
      gap={1}
    >
      {file.type.includes("image") && (
        <Box>
          <img
            src={file.url}
            alt={file.name}
            style={{
              maxWidth: 150,
              maxHeight: 150,
              objectFit: "contain",
            }}
          />
        </Box>
      )}
      <Typography variant="body2" textAlign={"center"}>
        {file.name}
      </Typography>
      {onFileDelete && (
        <IconButton
          disabled={disabled}
          onClick={() => onFileDelete()}
          size="medium"
          sx={{ color: (theme) => theme.palette.text.disabled }}
        >
          <Icons.close />
        </IconButton>
      )}
    </Box>
  );
};

interface IPhotoUploaderProps {
  file?: FileInfo;
  disabled?: boolean;
  onlyImages?: boolean;
  readonly?: boolean;
  onFileUpload?: (file: FileInfo) => void;
  onFileDelete?: () => void;
  maxFileSize?: number;
  maxFiles?: number;
  previewBox?: PreviewBoxComponent;
}

export type FileInfo = {
  name: string;
  url: string;
  type: string;
  size: number;
};

const MAX_FILES = 1;
const MAX_FILE_SIZE = 20000000; // 20 mb
const MIN_FILE_SIZE = 0;
const IMAGE_FILE_TYPES = {
  "image/png": [".png"],
  "image/jpg": [".jpeg", ".jpg"],
  "image/gif": [".gif"],
};

const FileUploader = ({
  file,
  disabled,
  maxFileSize = MAX_FILE_SIZE,
  onlyImages = false,
  readonly = false,
  onFileUpload,
  onFileDelete,
  maxFiles = MAX_FILES,
  previewBox: FileView = DefaultPreviewBox,
}: IPhotoUploaderProps) => {
  const theme = useTheme();

  const [uploaderErrors, setUploaderErrors] = useState<FileRejection[]>();
  const [fileUrlError, setFileUrlError] = useState<string | undefined>();

  const [createUploadUrl] = useMutation(createUploadUrlMutation);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const user = useCurrentUser();

  const preventUpload = disabled || loading || !!file;

  const { getRootProps, getInputProps } = useDropzone({
    accept: onlyImages ? IMAGE_FILE_TYPES : undefined,
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE,
    multiple: false,
    noClick: preventUpload ? true : false,
    noKeyboard: preventUpload ? true : false,
    disabled: preventUpload,
    onDropAccepted: (acceptedFiles) => {
      if (!acceptedFiles[0] && MAX_FILES === 1) return;

      // Since MAX_FILES = 1;
      const imageFile = acceptedFiles[0];
      const reader = new FileReader();
      setLoading(true);
      setProgress(0);
      reader.onloadend = () => {
        createUploadUrl({
          variables: {
            // TODO_Editor id: program?.id || userId,
            id: user.id,
            file: imageFile.name,
            type: "PROGRAM_FILE",
          },
          onCompleted: ({ createSignedUrl }: any, errors) => {
            if (errors) {
              setFileUrlError("Oops! Something went wrong. Please try again.");
              setLoading(false);
            } else {
              // TODO_Editor: Consider deleting the signed URL and uploaded file on change or element removal
              const { url } = createSignedUrl;
              uploadFile(url, imageFile, (progress) => {
                setProgress(
                  Math.round((progress.loaded / progress.total) * 100),
                );
              })
                .then(() => {
                  const urlInstance = new URL(
                    `https:/${url.substr(url.indexOf("/", 8))}`,
                  );
                  urlInstance.search = "";

                  const uploadUrl = String(urlInstance);
                  return uploadUrl;
                })
                .then((url) => {
                  onFileUpload &&
                    onFileUpload({
                      url,
                      name: imageFile.name,
                      size: imageFile.size,
                      type: imageFile.type,
                    });
                })
                .catch((err) => {
                  // TODO_Editor: Inform user of errors using common component
                  setFileUrlError(
                    "Oops! Something went wrong. Please try again.",
                  );
                  console.error(err);
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          },
          onError: (err) => {
            setLoading(false);
            // TODO_Editor: Inform user of errors using common component
            setFileUrlError("Oops! Something went wrong. Please try again.");
            console.error(err);
          },
        });
      };

      reader.readAsDataURL(imageFile);
      setUploaderErrors(undefined);
    },
    onDropRejected: (fileRejections) => {
      setUploaderErrors(fileRejections);
    },
  });
  const getErrorMessage = (code: ErrorCode | string) => {
    return getCustomErrorMessage(
      code,
      IMAGE_FILE_TYPES,
      MAX_FILE_SIZE / 1000000, // to mb
      MIN_FILE_SIZE,
      MAX_FILES,
    );
  };
  return (
    <Box paddingTop={2} height={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
        width={"100%"}
        height={"100%"}
        gap={1}
        sx={{
          border: "2px solid",
          borderStyle: "dashed",
          borderColor: theme.palette.divider,
          background: colorSystem.white2,
          borderRadius: 2,
          padding: 6,

          ":hover": !preventUpload && {
            cursor: "pointer",
            borderColor: theme.palette.primary.main,
          },
          ...(disabled && {
            filter: (theme) => theme.filters.disabled,
          }),
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />

        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            width={1}
            height={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2" textAlign={"center"} mb={1}>
              Uploading...
            </Typography>
            <LinearProgress
              variant={"determinate"}
              value={progress}
              sx={{
                color: theme.palette.primary.main,
                width: "25%",
                borderRadius: 2,
              }}
            />
          </Box>
        ) : file ? (
          <Box width={"100%"}>
            <FileView
              file={file}
              onFileDelete={!readonly ? onFileDelete : undefined}
              disabled={disabled}
            />
          </Box>
        ) : readonly ? (
          <Box>
            <Typography textAlign={"center"} color={"inherit"}>
              No file uploaded
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                borderRadius: "100%",
                p: 2,
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
              }}
            >
              {onlyImages ? (
                <AddPhotoAlternateIcon color="inherit" fontSize="large" />
              ) : (
                <UploadFileIcon color="inherit" fontSize="large" />
              )}
            </Box>
            <Typography textAlign={"center"} color={"inherit"}>
              Drag and drop here <br /> or
            </Typography>

            <Button
              disabled={disabled}
              variant="outlined"
              endIcon={<FileUploadIcon />}
            >
              Upload
            </Button>
          </>
        )}
      </Box>

      {fileUrlError && (
        <Typography color={"error"} variant={"caption"} mb={1}>
          {fileUrlError}
        </Typography>
      )}

      <FileRejectionHelperText
        uploaderErrors={uploaderErrors}
        getErrorMessage={getErrorMessage}
      />
    </Box>
  );
};

export default FileUploader;
