import React, { useRef } from "react";
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import {
  Box,
  BoxProps,
  Button,
  ClickAwayListener,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "../fields/DatePicker";
import { numberInputProps } from "../../constants";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { toISODateString } from "../../utils/date";
import {
  MetricType,
  parseAsFloat,
} from "../client-measurement/ClientMeasurementRow";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.75, 0, 1.75, 0),
    height: theme.spacing(8.5),
  },

  text: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 600,

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },

  date: {
    fontWeight: 500,
    whiteSpace: "nowrap",
    fontSize: 14,
    width: "100%",
    maxWidth: 100,

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },

  editValue: {
    marginRight: "auto",
    width: theme.spacing(7),

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(6),
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  measurement: {
    flex: 1,
    textAlign: "right",
    marginLeft: 10,
  },

  actions: {
    whiteSpace: "nowrap",
    flex: 1,
    minWidth: 140,
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.up("sm")]: {
      minWidth: 200,
    },

    "& button": {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
    },

    "& button svg": {
      width: theme.spacing(2.75),
      height: theme.spacing(2.75),
    },

    "& button svg path": {
      strokeWidth: 1,
    },

    "& button$save, & button$cancel": {
      fontSize: 14,
      fontWeight: 700,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.75, 1),

      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0.75, 2),
      },
    },

    "& button$save": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.progress.green,

      "&:disabled": {
        backgroundColor: theme.palette.text.secondary,
      },
    },

    "& button$cancel": {
      color: theme.palette.common.black,
    },
  },

  dateTextField: {
    width: "100%",
    maxWidth: 100,
  },

  inputRoot: {
    width: "inherit",
  },

  cancel: {
    fontSize: 14,
    fontWeight: 700,
  },

  save: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0.5),
    },
  },
}));

export interface ClientStepsHistoryRowProps extends BoxProps {
  editing?: boolean;
  handleEditing: () => void;
  onCancel: () => void;
  editingDate: boolean;
  value: any;
  setEditingDate: (value: boolean) => void;
  editingValue: boolean;
  setEditingValue: (value: boolean) => void;
  onSave: ({ id, unit, date, name, measurement, index }: MetricType) => void;
  index: number;
  disabled: boolean;
  onRemove: ({ id, index }: MetricType) => void;
  setNewHistory: (value: null) => void;
  newHistory: any;
  today: any;
}

const ClientBodyWeightHistoryRow = (props: ClientStepsHistoryRowProps) => {
  const {
    editing,
    handleEditing,
    onCancel,
    editingDate,
    value,
    setEditingDate,
    editingValue,
    setEditingValue,
    onSave,
    index,
    disabled,
    onRemove,
    setNewHistory,
    newHistory,
    today,
  } = props;
  const s = useStyles();
  const toggleDatePickerState = usePopupState({
    variant: "popover",
    popupId: "date-picker",
  });
  const dateTextFieldRef = useRef(null);

  const [stepValue, setStepValue] = React.useState(value.value.measurement);
  const [date, setDate] = React.useState(value.activityDate);
  const [dirty, setDirty] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const handleDateClick = React.useCallback(() => {
    if (editing) {
      setEditingDate(true);
      setTimeout(() => {
        toggleDatePickerState.open(dateTextFieldRef.current);
      }, 0);
    }
  }, [editing, setEditingDate]);

  const handleValueClick = React.useCallback(() => {
    if (editing) {
      setEditingValue(true);
      setEditingDate(false);
    }
  }, [editing, setEditingValue, setEditingDate]);

  const handleValueBlur = React.useCallback(() => {
    setEditingValue(false);
  }, [setEditingValue]);

  const handleChangeMeasurement = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.target.value === "") {
        setStepValue(event.target.value);
      } else {
        const value = parseAsFloat(event.target.value);
        setStepValue(value);
      }
      setDirty(true);
    },
    [],
  );

  const handleCancel = React.useCallback(() => {
    setStepValue(value.value.measurement || "");
    setDate(value.activityDate);
    setDirty(false);
    if (newHistory) setNewHistory(null);
    onCancel();
  }, [value, onCancel, newHistory, setNewHistory]);

  const handleDatePickerChange = React.useCallback(
    (selectedDate: any) => {
      setDate(dayjs(selectedDate).startOf("day").format("YYYY-MM-DD"));
      setDirty(true);
      toggleDatePickerState.close();
    },
    [toggleDatePickerState],
  );

  const handleRemoveClick = React.useCallback(() => setConfirmDelete(true), []);

  const handleCancelDelete = React.useCallback(
    () => setConfirmDelete(false),
    [],
  );

  const handleSave = React.useCallback(() => {
    onSave({
      id: value?.id,
      date: dayjs(new Date(date)).format("YYYY-MM-DD"),
      measurement: parseFloat(stepValue || "0"),
      name: "bodyweight",
      index: today?.id ? index : index - 1,
      history: true,
    });
    handleCancel();
  }, [date, onSave, stepValue, value, index, handleCancel, today]);

  const handleDelete = React.useCallback(() => {
    onRemove({
      id: value?.id,
      index: today?.id ? index : index - 1,
    });
    handleCancelDelete();
  }, [value, index, handleCancelDelete, onRemove, today]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSave();
      }
    },
    [handleSave],
  );

  React.useEffect(() => {
    if (editing) {
      setEditingValue(true);
    } else {
      setStepValue(value.value.measurement);
      setDate(value.activityDate);
      setDirty(false);
    }
  }, [editing, setEditingValue, value]);

  return (
    <Box className={clsx(s.root)}>
      {editing && editingDate ? (
        <>
          <TextField
            variant="standard"
            className={s.date}
            value={dayjs(new Date(date)).format("DD/MM/YYYY")}
            InputProps={{
              classes: {
                input: s.text,
              },
            }}
            inputRef={dateTextFieldRef}
            {...bindTrigger(toggleDatePickerState)}
            autoFocus
            fullWidth
          />

          <Popover {...bindPopper(toggleDatePickerState)}>
            <ClickAwayListener
              onClickAway={toggleDatePickerState.close}
              mouseEvent={"onMouseUp"}
            >
              <div>
                <DatePicker
                  value={value?.activityDate}
                  onChange={handleDatePickerChange}
                  maxDate={dayjs().subtract(1, "day").startOf("day")}
                />
              </div>
            </ClickAwayListener>
          </Popover>
        </>
      ) : (
        <Typography className={clsx(s.text, s.date)} onClick={handleDateClick}>
          {dayjs(new Date(date)).format("MMM DD, YYYY")}
        </Typography>
      )}
      <Box className={s.measurement}>
        {editing && editingValue ? (
          <TextField
            variant="standard"
            className={s.editValue}
            value={stepValue}
            onChange={handleChangeMeasurement}
            onKeyDown={handleKeyDown}
            onBlur={handleValueBlur}
            autoFocus
            type="number"
            InputProps={{
              classes: {
                input: s.text,
              },
              inputProps: numberInputProps,
            }}
          />
        ) : (
          <>
            <Typography className={s.text} onClick={handleValueClick}>
              {stepValue}
            </Typography>
          </>
        )}
      </Box>
      <Box className={s.actions}>
        {editing ? (
          <>
            <Button className={s.cancel} variant="text" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className={s.save}
              variant="contained"
              onClick={handleSave}
              disabled={!dirty}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <IconButton
              children={<EditIcon />}
              onClick={handleEditing}
              size="large"
            />
            <IconButton
              children={<DeleteIcon />}
              onClick={handleRemoveClick}
              disabled={disabled}
              size="large"
            />
          </>
        )}
      </Box>
      <ConfirmActionDialog
        title="Are you sure you want to remove this result?"
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        open={confirmDelete}
        disabled={disabled}
      />
    </Box>
  );
};

export default ClientBodyWeightHistoryRow;
