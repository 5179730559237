import React, { useContext, useEffect, useRef, useTransition } from "react";
import { Button, Container, Box, useMediaQuery, Portal } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { usePopupState } from "material-ui-popup-state/hooks";
import { maybePluralize } from "../../utils/text";
import { polyfillCSS } from "../../utils/css";
import { toEnum } from "../../utils/misc";
import { useDebounce } from "../../hooks/useDebounce";
import { useEffectLater } from "../../hooks/useEffectLater";
import { ClientSort, ClientStatus, Plan, planClients } from "../../constants";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import {
  useFragment,
  useMutation,
  useRefetchableFragment,
} from "react-relay/hooks";

import { useQueryParam } from "../../hooks/useQueryParam";
import { ClientPendingCard } from "../card/ClientPendingCard";
import { GroupActions } from "../filters/GroupActions";
import { ActionButton } from "../button/ActionButton";
import { EmptyResults } from "../coach-programs/EmptyResults";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { PageSkeleton } from "../loading/PageSkeleton";
import { ClientCard } from "../card/ClientCard";
import { CoachClientsFilter } from "./CoachClientsFilter";
import { ConnectionPagination } from "../pagination/ConnectionPagination";
import { useToggleManyClientsArchivedMutation } from "./mutations/ToggleManyClientsArchived";
import { SendEmailDialog } from "../dialog/SendEmailDialog";
import { NewClientAction } from "./NewClientAction";
import { CoachMessageClientsDialog } from "./CoachMessageClientsDialog";

import { ClientStatus as ClientStatusEnum } from "./__generated__/ClientSummary_client.graphql";
import {
  CoachClientsListScreen_root$key,
  CoachClientsListScreen_root$data,
} from "./__generated__/CoachClientsListScreen_root.graphql";
import { CoachClientsListScreen_user$key } from "./__generated__/CoachClientsListScreen_user.graphql";
import { CoachClientsListScreenInviteAllAddedClientMutation } from "./__generated__/CoachClientsListScreenInviteAllAddedClientMutation.graphql";
import AddClientButtonContext from "../../contexts/AddClientContext";
import { CoachClientsListScreen_invitedUsers$key } from "./__generated__/CoachClientsListScreen_invitedUsers.graphql";
import { CoachClientsListScreen_addedUsers$key } from "./__generated__/CoachClientsListScreen_addedUsers.graphql";
import { CoachClientFiltersContext } from "../../contexts/CoachClientFiltersContext";
import CoachClientListContext from "../../contexts/CoachClientListContext";
import TrackInfoTool from "../tools/TrackInfoTool";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(10)} + var(--safe-area-inset-right)) !important`,
    ),
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
    },
  },

  filters: {
    marginBottom: theme.spacing(3),
  },

  card: {
    marginBottom: theme.spacing(2),
    minHeight: "91px",
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },

  empty: {
    marginTop: theme.spacing(20),
  },

  sendMessageButton: {
    marginLeft: theme.spacing(2),
  },
  inviteAllButtonContainer: {
    display: "flex",
    justifyContent: "right",
    marginBottom: theme.spacing(2.5),
  },
  inviteAllButton: {
    color: theme.palette.common.black,
  },
}));

const inviteAllAddedClientMutation = graphql`
  mutation CoachClientsListScreenInviteAllAddedClientMutation(
    $input: InviteAddedClientInput!
  ) {
    inviteAddedClient(input: $input) {
      invites {
        ...ClientPendingCard_invite
      }
      clientMutationId
    }
  }
`;

const invitedUsersFragment = graphql`
  fragment CoachClientsListScreen_invitedUsers on Root {
    invitedUsers: invites(first: 9999, addedUser: false)
      @connection(key: "ClientInvites_invitedUsers", filters: []) {
      ...InviteDialog_invitedUsers

      edges {
        node {
          id
        }
      }
    }
  }
`;

const addedUsersFragment = graphql`
  fragment CoachClientsListScreen_addedUsers on Root {
    addedUsers: invites(first: 9999, addedUser: true)
      @connection(key: "ClientInvites_addedUsers", filters: []) {
      ...InviteDialog_addedUsers

      edges {
        node {
          id
        }
      }
    }
  }
`;

const rootFragment = graphql`
  fragment CoachClientsListScreen_root on Root
  @refetchable(queryName: "CoachClientsListScreenRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
    query: { type: "String" }
    status: { type: "[ClientStatusFilter!]" }
    sort: { type: "ClientSortBy!" }
    includeAllClients: { type: "Boolean", defaultValue: true }
    tagId: { type: ID, defaultValue: null }
  ) {
    tags {
      edges {
        node {
          id
          title
        }
      }
    }
    clients(
      first: $first
      after: $after
      query: $query
      status: $status
      orderBy: $sort
      tagId: $tagId
    ) {
      pageCursors {
        ...ConnectionPagination_pageCursors
      }
      totalCount
      edges {
        cursor
        client: node {
          id
          ...ClientCard_client
          ...ClientMenu_client
          ...CoachMessageClientsDialog_clients

          invite {
            client {
              clientStatus
              id
            }
            ...ClientPendingCard_invite
          }
        }
      }
    }

    allClients: clients(first: 9999, status: $status)
      @include(if: $includeAllClients) {
      edges {
        client: node {
          id
          ...CoachMessageClientsDialog_clients
        }
      }
    }

    inviteCode {
      ...InviteDialog_inviteCode
    }
  }
`;

const userFragment = graphql`
  fragment CoachClientsListScreen_user on User {
    clientsCountNoSample: clientsCount(noSample: true)
    plan
    planTier
  }
`;

export type CoachClientsListClientNode =
  CoachClientsListScreen_root$data["clients"]["edges"][0]["client"];

export interface CoachClientsListScreenProps {
  root: CoachClientsListScreen_root$key;
  invitedUsers: CoachClientsListScreen_invitedUsers$key;
  addedUsers: CoachClientsListScreen_addedUsers$key;
  status: [ClientStatusEnum];
  user: CoachClientsListScreen_user$key;
}

export function CoachClientsListScreen(props: CoachClientsListScreenProps) {
  const {
    root: rootRef,
    invitedUsers: invitedUsersRef,
    addedUsers: addedUsersRef,
    status,
    user: userRef,
  } = props;
  const [root, rootRefetch] = useRefetchableFragment(rootFragment, rootRef);
  const { invitedUsers } = useFragment(invitedUsersFragment, invitedUsersRef);
  const { addedUsers } = useFragment(addedUsersFragment, addedUsersRef);

  const dataContext = useContext(CoachClientListContext);
  dataContext?.setCoachClientsList(root);

  const user = useFragment(userFragment, userRef);
  const clients = root?.clients;
  const inviteCode = root?.inviteCode;

  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();

  const [inviteAddedClient, invitingAddedClientMutation] =
    useMutation<CoachClientsListScreenInviteAllAddedClientMutation>(
      inviteAllAddedClientMutation,
    );

  const { allClients } = React.useMemo(() => {
    const allClients = root?.allClients;

    return { allClients };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(root?.allClients)]);

  const s = useStyles();
  const brandName = useCurrentBrandName();
  const pageSize = 10;

  const [initialStatus, setInitialStatus] = React.useState(status[0]);
  const [selectedClients, setSelectedClients] = React.useState<
    CoachClientsListClientNode[]
  >([]);

  const [emailDialogIsOpen, setEmailDialogIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { after, setAfter, query, setQuery, filterTag, sortKey } = useContext(
    CoachClientFiltersContext,
  );

  const messageClientsDialogState = usePopupState({
    variant: "popover",
    popupId: "message-many-clients",
  });

  const confirmArchiveClientsDialogState = usePopupState({
    variant: "popover",
    popupId: "archive-many-clients",
  });

  const tagIdRefetch = React.useMemo(() => {
    return status.includes(ClientStatus.ACTIVE) ? filterTag : "";
  }, [status, filterTag]);

  const delayedQuery = useDebounce(query, 500);
  const handleLoaded = React.useCallback(() => {
    setLoading(false);
  }, []);

  const [isPending, setTransition] = useTransition();

  const refetch = React.useCallback(() => {
    setLoading(true);
    setTransition(() => {
      rootRefetch(
        {
          query: delayedQuery,
          sort: sortKey,
          tagId: tagIdRefetch,
          after,
          first: pageSize,
          includeAllClients: false,
        },
        { onComplete: handleLoaded, ...RELAY_LAZY_LOAD_COMMON_CONFIG },
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [after, delayedQuery, sortKey, tagIdRefetch]);

  const [toggleManyClientsArchived, toggleManyClientsArchivedInFlight] =
    useToggleManyClientsArchivedMutation();

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    refetch();
  }, [refetch]);

  React.useEffect(() => {
    if (initialStatus !== status[0]) {
      setInitialStatus(status[0]);
      setQuery("");
      setSelectedClients([]);
    }
  }, [initialStatus, status]);

  const clientNodes = React.useMemo(
    () =>
      clients
        ? clients.edges
            .map(({ client }) => client)
            .filter(
              (client) =>
                !status.includes(ClientStatus.PENDING) ||
                Boolean(client.invite),
            )
        : [],
    [clients, status],
  );

  const allClientNodes = React.useMemo(
    () =>
      allClients && !status.includes(ClientStatus.PENDING)
        ? allClients.edges.map(({ client }) => client)
        : [],
    [allClients, status],
  );

  const unselectedClients = clientNodes.filter(({ id }) =>
    selectedClients.every((it) => it.id !== id),
  );

  selectedClients
    .filter(
      ({ id, invite }) => clientNodes.every((it) => it.id !== id) && invite,
    )
    .forEach((it) => clientNodes.unshift(it));

  const handleClientSelect = React.useCallback(
    (clientId: string, selected: boolean) => {
      if (user) {
        if (
          status[0] !== ClientStatus.ACTIVE &&
          user?.clientsCountNoSample +
            selectedClients.length +
            1 +
            invitedUsers?.edges?.length +
            addedUsers?.edges?.length >
            Number(planClients(Plan[user?.plan], true)) &&
          selected
        ) {
          snackAlert({
            severity: "error",
            message: "Customer limit reached",
          });
        } else {
          setSelectedClients((value) =>
            selected
              ? [...value, clientNodes.find(({ id }) => id === clientId)]
              : value.filter(({ id }) => id !== clientId),
          );
        }
      }
    },
    [
      clientNodes,
      user,
      snackAlert,
      selectedClients,
      status,
      invitedUsers,
      addedUsers,
    ],
  );

  const total = allClientNodes.length;
  const selectedTotal = selectedClients.length;
  const selectedAll = clientNodes.length > 0 && unselectedClients.length === 0;

  const handleSelectAll = React.useCallback(() => {
    setSelectedClients(clientNodes);
  }, [clientNodes]);

  const isClientAdded = React.useMemo(() => {
    return clientNodes.filter(
      (i) => i.invite?.client?.clientStatus === ClientStatus.ADDED,
    );
  }, [clientNodes]);

  const handleSelectAllPages = React.useCallback(() => {
    if (user) {
      if (
        status[0] !== ClientStatus.ACTIVE &&
        user?.clientsCountNoSample +
          allClientNodes.length +
          invitedUsers?.edges?.length +
          addedUsers?.edges?.length >
          Number(planClients(Plan[user?.plan], true))
      ) {
        snackAlert({
          severity: "error",
          message: "Customer limit reached",
        });
      } else setSelectedClients(allClientNodes as any[]);
    }
  }, [allClientNodes, user, snackAlert, status, invitedUsers, addedUsers]);

  const handleClearSelection = React.useCallback(
    () => setSelectedClients([]),
    [],
  );

  const handlePageChange = React.useCallback(
    (cursor: string) => {
      if (selectedClients.length) handleClearSelection();
      setAfter(cursor);
    },
    [selectedClients, handleClearSelection],
  );

  const handleArchiveSelected = React.useCallback(
    () =>
      toggleManyClientsArchived({
        variables: {
          input: {
            archived: status.includes(ClientStatus.ACTIVE),
            ids: selectedClients.map(({ id }) => id),
          },
        },
        onSuccess: () => {
          confirmArchiveClientsDialogState.close();
          setSelectedClients([]);
          refetch();
        },
      }),
    [
      confirmArchiveClientsDialogState,
      refetch,
      selectedClients,
      status,
      toggleManyClientsArchived,
    ],
  );

  const handleToggleSelected = React.useCallback(() => {
    if (status.includes(ClientStatus.ACTIVE)) {
      confirmArchiveClientsDialogState.open();
    } else {
      handleArchiveSelected();
    }
  }, [confirmArchiveClientsDialogState, handleArchiveSelected, status]);

  const handleMessageSelected = React.useCallback(() => {
    messageClientsDialogState.open();
  }, [messageClientsDialogState]);

  const handleMessagesSent = React.useCallback(() => {
    setSelectedClients([]);
    messageClientsDialogState.close();
  }, [messageClientsDialogState]);

  const handleSendEmail = React.useCallback(
    (message) => {
      const addedUsersId = clientNodes
        .filter(
          (i) =>
            i.invite?.client?.clientStatus === ClientStatus.ADDED &&
            i.invite.client.id,
        )
        .map((i) => i.id);
      inviteAddedClient({
        variables: {
          input: {
            id: addedUsersId,
            message: message,
          },
        },
        onCompleted: (_, errors) => {
          setEmailDialogIsOpen(false);
          if (errors?.length) {
            onError(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: "Invitation has been send",
            });
          }
        },
      });
    },
    [inviteAddedClient, onError, snackAlert, clientNodes],
  );

  const groupActionsDisabled =
    !selectedTotal || toggleManyClientsArchivedInFlight;

  return (
    <>
      <NewClientAction
        inviteCode={inviteCode}
        invitedUsers={invitedUsers}
        addedUsers={addedUsers}
        handleNewClientAction={
          status.includes(ClientStatus.PENDING) ? refetch : () => {}
        }
      />

      {!!isClientAdded.length &&
        (status.includes(ClientStatus.PENDING) ||
          status.includes(ClientStatus.ADDED)) && (
          <Box className={s.inviteAllButtonContainer}>
            <Button
              variant="outlined"
              children="Invite all"
              className={s.inviteAllButton}
              onClick={() => setEmailDialogIsOpen(true)}
            />
          </Box>
        )}

      {loading || !clients ? (
        <PageSkeleton fullWidth />
      ) : (
        <>
          {clientNodes.length > 0 ? (
            clientNodes.map((client) =>
              status.includes(ClientStatus.PENDING) ||
              status.includes(ClientStatus.ADDED) ? (
                <ClientPendingCard
                  key={client.id}
                  className={s.card}
                  invite={client.invite}
                />
              ) : (
                <ClientCard
                  key={client.id}
                  className={s.card}
                  client={client}
                  selectable
                  selected={selectedClients.some(({ id }) => id === client.id)}
                  onSelect={handleClientSelect}
                />
              ),
            )
          ) : (
            <EmptyResults
              className={s.empty}
              title="No clients found"
              description={`You don't have ${status[0].toLocaleLowerCase()} clients${
                query ? " matching your query" : ""
              }`}
            />
          )}
          <ConnectionPagination
            pageCursors={clients.pageCursors}
            onChange={handlePageChange}
            autoHide
          />
        </>
      )}

      <GroupActions
        selectedTotal={selectedTotal}
        total={total}
        unitLabel="client"
        selectedAll={selectedAll}
        onSelectAll={handleSelectAll}
        onSelectAllPages={handleSelectAllPages}
        onClearSelection={handleClearSelection}
      >
        <ActionButton
          variant="outlined"
          disabled={groupActionsDisabled}
          onClick={handleToggleSelected}
        >
          {status.includes(ClientStatus.ACTIVE) ? "Archive" : "Restore"}
        </ActionButton>

        {status.includes(ClientStatus.ACTIVE) && (
          <ActionButton
            className={s.sendMessageButton}
            variant="contained"
            disabled={groupActionsDisabled}
            onClick={handleMessageSelected}
          >
            Send Message
          </ActionButton>
        )}
      </GroupActions>

      {messageClientsDialogState.isOpen && (
        <CoachMessageClientsDialog
          clientsRef={selectedClients}
          onClose={messageClientsDialogState.close}
          onMessagesSent={handleMessagesSent}
          open
        />
      )}

      {confirmArchiveClientsDialogState.isOpen && (
        <ConfirmActionDialog
          title={`Are you sure you want to archive ${maybePluralize(
            selectedClients.length,
            "client",
          )}?`}
          description={`Archived clients no longer have access to ${brandName}.`}
          open
          onClose={confirmArchiveClientsDialogState.close}
          onCancel={confirmArchiveClientsDialogState.close}
          onConfirm={handleArchiveSelected}
        />
      )}
      <SendEmailDialog
        sendingEmail={invitingAddedClientMutation}
        sendEmail={handleSendEmail}
        onClose={() => setEmailDialogIsOpen(false)}
        open={emailDialogIsOpen}
      />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Clients",
          properties: {
            status,
          },
        }}
      />
    </>
  );
}
