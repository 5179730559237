import React from "react";

const CancelIcon = ({ fill }) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.21418 1.87667L5.33323 9.60314C5.41574 9.72761 5.5181 9.82753 5.63327 9.89603C5.74844 9.96453 5.87371 10 6.00048 10C6.12725 10 6.25252 9.96453 6.3677 9.89603C6.48287 9.82753 6.58523 9.72761 6.66774 9.60314L11.7868 1.87667C12.2753 1.13917 11.8703 0 11.1195 0H0.879971C0.129216 0 -0.275825 1.13917 0.21418 1.87667Z"
      fill={fill}
    />
  </svg>
);

export default CancelIcon;
