import clsx from "clsx";
import React from "react";
import { Autocomplete, AutocompleteProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay";
import { useDebounce } from "../../hooks/useDebounce";

import { ExerciseAutocomplete_rootRef$key } from "./__generated__/ExerciseAutocomplete_rootRef.graphql";

import { ExerciseAsset } from "../exercise-library/types";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    margin: 0,
  },

  popper: {
    zIndex: "99999 !important" as any,
    borderRadius: 12,
    boxShadow: theme.shadows[5],
  },

  option: {
    padding: theme.spacing(1, 2),
    fontSize: 18,
    fontWeight: 500,
  },
}));

const rootRefFragment = graphql`
  fragment ExerciseAutocomplete_rootRef on Root
  @refetchable(queryName: "ExerciseAutocompleteRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 0 }
    after: { type: "String", defaultValue: null }
    query: { type: "String" }
  ) {
    suggestedExercises: custom_assets(
      first: $first
      after: $after
      query: $query
      assetType: [WORKOUT_EXERCISE]
    ) @connection(key: "ExerciseAutocomplete_suggestedExercises", filters: []) {
      edges {
        node {
          id
          name
          content
          userId
        }
      }
    }
  }
`;

export interface ExerciseAutocompleteProps
  extends Omit<
    AutocompleteProps<ExerciseAsset, true, false, any>,
    "options" | "onChange"
  > {
  rootRefRef: ExerciseAutocomplete_rootRef$key;
  pageSize?: number;
  onChange?: (value: ExerciseAsset) => void;
}

export const getExerciseAssetLabel = ({ exercise: { title } }: ExerciseAsset) =>
  title;

export const renderExerciseAssetLabel = (
  { exercise }: ExerciseAsset,
  { inputValue },
) => {
  const handleText = (value) =>
    value
      .replace(/\\/g, "\\\\")
      .replace(/\(/g, "\\(")
      .replace(/\)/g, "\\)")
      .replace(/\[/g, "\\[")
      .replace(/]/g, "\\]");

  const regex = new RegExp("(" + handleText(inputValue) + ")", "gi");

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: exercise?.title
          ? exercise?.title.replace(regex, "<strong>$1</strong>")
          : "".replace(regex, "<strong>$1</strong>"),
      }}
    />
  );
};

export function ExerciseAutocomplete(props: ExerciseAutocompleteProps) {
  const {
    className,
    rootRefRef,
    pageSize = 50,
    inputValue,
    onChange,
    ...other
  } = props;
  const { data: rootRef, refetch: refetchConnection } = usePaginationFragment(
    rootRefFragment,
    rootRefRef,
  );
  const s = useStyles();
  const { suggestedExercises: assets } = rootRef;
  const [loading, setLoading] = React.useState(false);
  const delayedQuery = useDebounce(inputValue, 250);
  const setLoaded = React.useCallback(() => setLoading(false), []);
  const edges = (assets?.edges || []).filter(({ node }) => node);
  const options = edges.map(({ node: { id: assetId, content, userId } }) => ({
    assetId,
    userId,
    exercise: JSON.parse(content),
  }));

  const handleChange = React.useCallback(
    (_, value: ExerciseAsset) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    if (!loading) {
      refetchConnection(
        {
          query: delayedQuery,
          first: pageSize,
        },
        {
          onComplete: setLoaded,
        },
      );
    }
  }, [delayedQuery, pageSize, refetchConnection, setLoaded, loading]);

  return (
    <Autocomplete
      classes={{
        root: clsx(s.root, className),
        paper: s.paper,
        popper: s.popper,
        option: s.option,
      }}
      inputValue={inputValue}
      options={options}
      filterOptions={(options) => options.filter((i: any) => i.exercise.title)}
      // TODO_EXERCISES
      // renderOption={renderExerciseAssetLabel}
      // getOptionLabel={getExerciseAssetLabel}
      onChange={handleChange as any}
      disableClearable={false}
      freeSolo
      {...other}
    />
  );
}
