/**
 * @generated SignedSource<<462ff71403e46d556f5ecc3dec2a6aa4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignUpClientRouteQuery$variables = {
  fbUserId: string;
  workspace?: string | null | undefined;
};
export type SignUpClientRouteQuery$data = {
  readonly invite: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientAcceptInviteScreen_invite">;
  } | null | undefined;
  readonly workspace: {
    readonly " $fragmentSpreads": FragmentRefs<"ThemeProvider_workspace">;
  };
};
export type SignUpClientRouteQuery = {
  response: SignUpClientRouteQuery$data;
  variables: SignUpClientRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fbUserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspace"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "firebaseUid",
    "variableName": "fbUserId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "workspace"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignUpClientRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Invite",
        "kind": "LinkedField",
        "name": "invite",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientAcceptInviteScreen_invite"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ThemeProvider_workspace"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignUpClientRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Invite",
        "kind": "LinkedField",
        "name": "invite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "coach",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accepted",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mealLogging",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f8d5e3db4e1899613a2c768844700dd",
    "id": null,
    "metadata": {},
    "name": "SignUpClientRouteQuery",
    "operationKind": "query",
    "text": "query SignUpClientRouteQuery(\n  $fbUserId: String!\n  $workspace: String\n) {\n  invite(firebaseUid: $fbUserId) {\n    ...ClientAcceptInviteScreen_invite\n    id\n  }\n  workspace(slug: $workspace) {\n    ...ThemeProvider_workspace\n    id\n  }\n}\n\nfragment ClientAcceptInviteScreen_invite on Invite {\n  id\n  coach {\n    displayName\n    id\n  }\n  accepted\n}\n\nfragment ThemeProvider_workspace on Workspace {\n  primaryColor\n  name\n  logoURL\n  message\n  slug\n  mealLogging\n}\n"
  }
};
})();

(node as any).hash = "4c4a87e5ef7880c3657121a1d55c9df6";

export default node;
