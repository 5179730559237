import React from "react";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Slide,
  Divider,
  useTheme,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { ArrowBack } from "@mui/icons-material";

import { ComponentStatus, ComponentType } from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { ReactComponent as DuplicateIcon } from "../../icons/DuplicateOutline.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/ArchiveOutline.svg";
import { ReactComponent as ChangeIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as MoveIcon } from "../../icons/DataTransferVertical.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as CopyIcon } from "../../icons/CopyOutline.svg";
import { ReactComponent as ImageIcon } from "../../icons/streamline-icon-common-file-horizontal-image.svg";
import { ReactComponent as FlashIcon } from "../../icons/streamline-icon-flash.svg";
import { Menu, MenuProps } from "../menu/Menu";
import { SelectComponentIcons } from "../program-component/SelectComponentIcons";
import { SelectComponentWeek } from "../program-component/SelectComponentWeek";
import { CopyComponentDialog } from "../dialog/CopyComponentDialog";
import { generateUniqueServerID } from "../dirty-transaction/hooks";
import { ChangeComponentCoverDialog } from "../dialog/ChangeComponentCoverDialog";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useCopyCalendarComponent } from "../../hooks/useCopyCalendarComponent";

import { WeekComponentMenu_component$key } from "./__generated__/WeekComponentMenu_component.graphql";
import { WeekComponentMenuRemoveComponentMutation as RemoveComponentMutation } from "./__generated__/WeekComponentMenuRemoveComponentMutation.graphql";
import { WeekComponentMenuUpsertComponentMutation as UpsertComponentMutation } from "./__generated__/WeekComponentMenuUpsertComponentMutation.graphql";
import { WeekComponentMenuDuplicateComponentMutation as DuplicateComponentMutation } from "./__generated__/WeekComponentMenuDuplicateComponentMutation.graphql";
import { LockOpen, Lock } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {},
  back: {
    "& .MuiListItemText-primary": {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 500,
    },
  },
}));

const duplicateComponentMutation = graphql`
  mutation WeekComponentMenuDuplicateComponentMutation(
    $input: DuplicateComponentInput!
  ) {
    duplicateComponent(input: $input) {
      week {
        ...WeekCard_week
      }
    }
  }
`;

const upsertComponentMutation = graphql`
  mutation WeekComponentMenuUpsertComponentMutation(
    $input: UpsertComponentInput!
  ) {
    upsertComponent(input: $input) {
      component {
        ...WeekComponentMenu_component
      }
    }
  }
`;

const removeComponentMutation = graphql`
  mutation WeekComponentMenuRemoveComponentMutation(
    $input: RemoveComponentInput!
  ) {
    removeComponent(input: $input) {
      removedComponentId
    }
  }
`;

export type MenuState = null | "icons" | "copy" | "move";

const fragment = graphql`
  fragment WeekComponentMenu_component on Component {
    id
    type
    status
    previousStatus
    weekId
    locked
    ...SelectComponentIcons_component
    ...CopyComponentDialog_component
    ...ChangeComponentCoverDialog_component @arguments(draft: true)
  }
`;

export interface WeekComponentMenuProps extends MenuProps {
  componentRef: WeekComponentMenu_component$key;
  calendar?: boolean;
}

export function WeekComponentMenu(props: WeekComponentMenuProps) {
  const theme = useTheme();
  const { componentRef, calendar = false, onClose, ...other } = props;
  const s = useStyles();
  const component = useFragment(fragment, componentRef);
  const [duplicateComponent] = useMutation<DuplicateComponentMutation>(
    duplicateComponentMutation,
  );
  const [upsertComponent] = useMutation<UpsertComponentMutation>(
    upsertComponentMutation,
  );
  const [removeComponent] = useMutation<RemoveComponentMutation>(
    removeComponentMutation,
  );
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();

  const [menu, setMenu] = React.useState<MenuState>(null);
  const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
  const [openChangeCoverDialog, setOpenChangeCoverDialog] =
    React.useState(false);
  const [, setCopyCalendarComponentId] = useCopyCalendarComponent();

  const closeMenu = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const handleDuplicateClick = React.useCallback(() => {
    const newId = generateUniqueServerID("Component");
    const input = { id: component.id, newId };

    closeMenu();

    duplicateComponent({
      variables: { input },
      optimisticUpdater: (store) => {
        if (store.get(newId)) {
          return;
        }

        const original = store.get(component.id);
        const week = store.get(component.weekId);
        const node = store.create(newId, "Component");
        const args = { archived: true };
        const components = week.getLinkedRecords("components", args);
        const title = original.getValue("title", { draft: true });
        const newTitle = `Copy of ${title}`;

        node.copyFieldsFrom(original);
        node.setValue(newId, "id");
        node.setValue("", "slug");

        node.setValue(newTitle, "title", { draft: true });
        week.setLinkedRecords([...components, node], "components", args);
      },
      onCompleted: (_, errors) => {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          snackAlert({
            severity: "success",
            message: "Component was duplicated",
          });
        }
      },
    });
  }, [
    closeMenu,
    component.id,
    component.weekId,
    duplicateComponent,
    onError,
    snackAlert,
  ]);

  const handleToggleArchivedClick = React.useCallback(() => {
    const { previousStatus } = component;
    const status =
      component.status === ComponentStatus.ARCHIVED
        ? previousStatus && previousStatus !== ComponentStatus.DRAFT
          ? previousStatus
          : ComponentStatus.DRAFT
        : ComponentStatus.ARCHIVED;

    closeMenu();

    upsertComponent({
      variables: {
        input: {
          id: component.id,
          status,
        },
      },
      optimisticUpdater: (store) => {
        store.get(component.id).setValue(status, "status");
      },
      onCompleted: (_, errors) => {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          snackAlert({
            severity: "success",
            message: `Component was ${
              status === ComponentStatus.ARCHIVED ? "archived" : "restored"
            }`,
          });
        }
      },
      onError,
    });
  }, [closeMenu, component, onError, snackAlert, upsertComponent]);

  const handleRemoveClick = React.useCallback(() => {
    closeMenu();

    removeComponent({
      variables: {
        input: {
          id: component.id,
        },
      },
      // optimisticUpdater: (store) => {
      //   store.delete(component.id);
      // },
      onCompleted: (_, errors) => {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          snackAlert({
            severity: "success",
            message: "Component was permanently removed.",
          });
        }
      },
      updater: (store) => {
        store.delete(component.id);
      },
      onError,
    });
  }, [closeMenu, component.id, onError, removeComponent, snackAlert]);

  const handleChangeMenu = React.useCallback((event) => {
    const {
      dataset: { menu },
    } = event.currentTarget;

    setMenu(menu);
  }, []);

  const handleWeekChange = React.useCallback(
    (weekId: string) => {
      const input = {
        id: component.id,
        weekId,
      };

      upsertComponent({
        variables: {
          input,
        },
        optimisticUpdater: (store) => {
          store.get(component.id).setValue(weekId, "weekId");
        },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: "Component starting week updated",
            });
          }
        },
        onError,
      });
    },
    [component.id, onError, snackAlert, upsertComponent],
  );

  const handleCopy = React.useCallback(
    (event) => {
      snackAlert({
        severity: "info",
        message: "Hold shift to paste to multiple days",
      });

      setCopyCalendarComponentId(
        // Only workout components can be copied at the moment
        component.type === ComponentType.WORKOUT ? component.id : undefined,
      );
      onClose(event, "backdropClick");
    },
    [
      component.id,
      component.type,
      onClose,
      setCopyCalendarComponentId,
      snackAlert,
    ],
  );

  const handleCopyToProgramDialogOpen = React.useCallback(() => {
    setOpenCopyDialog(true);
  }, []);

  const handleCopyDialogClose = React.useCallback(
    (event) => {
      setOpenCopyDialog(false);
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  const handleChangeCoverDialogOpen = React.useCallback(() => {
    setOpenChangeCoverDialog(true);
  }, []);

  const handleChangeCoverDialogClose = React.useCallback(
    (event) => {
      setOpenChangeCoverDialog(false);
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  const handleSaveChangeCover = React.useCallback(
    (image: string) => {
      const input = {
        id: component.id,
        image,
      };

      upsertComponent({
        variables: {
          input,
        },
        optimisticUpdater: (store) => {
          store.get(component.id).setValue(image, "image");
        },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: "Cover image updated",
            });
            setOpenChangeCoverDialog(false);
            closeMenu();
          }
        },
        onError,
      });
    },
    [closeMenu, component.id, onError, snackAlert, upsertComponent],
  );

  const handleLockClick = () => {
    const updatedValue = !component.locked;

    upsertComponent({
      variables: {
        input: {
          id: component.id,
          locked: updatedValue,
        },
      },
      optimisticUpdater: (store) => {
        store.get(component.id).setValue(updatedValue, "locked");
      },
      onCompleted: (_, errors) => {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          snackAlert({
            severity: "success",
            message: `Component was ${updatedValue ? "locked" : "unlocked"} successfully`,
          });
        }
        closeMenu();
      },
      onError,
    });
  };

  return openCopyDialog ? (
    <CopyComponentDialog
      componentRef={component}
      open
      onClose={handleCopyDialogClose}
    />
  ) : openChangeCoverDialog ? (
    <ChangeComponentCoverDialog
      componentRef={component}
      open
      onClose={handleChangeCoverDialogClose}
      onCoverChange={handleSaveChangeCover}
    />
  ) : (
    <Menu
      className={s.root}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      variant="menu"
      onClose={onClose}
      {...other}
    >
      {!menu ? (
        <Box>
          <MenuItem onClick={handleDuplicateClick}>
            <ListItemIcon children={<DuplicateIcon />} />
            <ListItemText primary="Duplicate" />
          </MenuItem>

          <MenuItem onClick={handleToggleArchivedClick}>
            <ListItemIcon children={<ArchiveIcon />} />
            <ListItemText
              primary={component.status === "ARCHIVED" ? "Restore" : "Archive"}
            />
          </MenuItem>

          {component.status === "ARCHIVED" && (
            <MenuItem onClick={handleRemoveClick}>
              <ListItemIcon children={<BinIcon />} />
              <ListItemText primary="Delete permanently" />
            </MenuItem>
          )}

          {component.type !== ComponentType.MESSAGE && (
            <MenuItem onClick={handleChangeMenu} data-menu="icons">
              <ListItemIcon
                sx={{ color: theme.palette.common.black }}
                children={<ChangeIcon />}
              />
              <ListItemText primary="Change icon" />
            </MenuItem>
          )}

          {calendar && component.type === ComponentType.WORKOUT && (
            <MenuItem onClick={handleCopy}>
              <ListItemIcon children={<FlashIcon />} />
              <ListItemText primary="Copy" />
            </MenuItem>
          )}

          <MenuItem onClick={handleCopyToProgramDialogOpen}>
            <ListItemIcon children={<CopyIcon />} />
            <ListItemText primary="Copy to another program" />
          </MenuItem>

          {!calendar && (
            <MenuItem onClick={handleChangeMenu} data-menu="move">
              <ListItemIcon children={<MoveIcon />} />
              <ListItemText primary="Change start week" />
            </MenuItem>
          )}

          {!calendar && component.type !== ComponentType.MESSAGE && (
            <MenuItem onClick={handleChangeCoverDialogOpen}>
              <ListItemIcon
                sx={{ color: theme.palette.common.black }}
                children={<ImageIcon />}
              />
              <ListItemText primary="Change cover image" />
            </MenuItem>
          )}
          <Tooltip
            arrow
            placement="left"
            title="Prevent clients from viewing this until the scheduled date."
          >
            <MenuItem onClick={handleLockClick}>
              <ListItemIcon
                sx={{ color: theme.palette.common.black }}
                children={component.locked ? <LockOpen /> : <Lock />}
              />
              <ListItemText
                primary={component.locked ? "Unlock content" : "Lock content"}
              />
            </MenuItem>
          </Tooltip>
        </Box>
      ) : (
        <Slide in={true} direction="left" mountOnEnter unmountOnExit>
          <Box>
            <MenuItem className={s.back} onClick={handleChangeMenu}>
              <ListItemIcon children={<ArrowBack />} />
              <ListItemText
                primary={
                  menu === "icons"
                    ? "Select icon"
                    : menu === "move"
                      ? "Select week"
                      : "Select course"
                }
              />
            </MenuItem>
            <Divider />
            {menu === "icons" ? (
              <SelectComponentIcons
                componentRef={component}
                onSubmit={closeMenu}
              />
            ) : menu === "move" ? (
              <SelectComponentWeek
                weekId={component.weekId}
                onChange={handleWeekChange}
              />
            ) : (
              "TODO: course menu (ui needed)"
            )}
          </Box>
        </Slide>
      )}
    </Menu>
  );
}
