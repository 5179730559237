import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLazyLoadQuery, graphql } from "react-relay/hooks";
import { queryMatchesContent } from "../../utils/search";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { WorkoutExercise, WorkoutSection } from "./types";
import {
  groupWorkoutClients,
  parseWorkoutResultValueJsonToWorkoutSection,
} from "./utils";
import { WorkoutExerciseHistoryQuery } from "./__generated__/WorkoutExerciseHistoryQuery.graphql";
import { WorkoutExerciseHistoryClient } from "./WorkoutExerciseHistoryClient";
import { WorkoutExerciseHistoryResult } from "./WorkoutExerciseHistoryResult";

import HistoryIcon from "../../icons/HistoryIcon";
import CancelIcon from "../../icons/CancelIcon";

const useStyles = makeStyles((theme) => ({
  root: {},

  search: {
    "& svg": {
      color: theme.palette.text.secondary,
    },
  },

  clients: {
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },

  divider: {
    backgroundColor: theme.palette.quote,
    margin: theme.spacing(2, -2, 2, 0),
  },

  empty: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    marginTop: theme.spacing(15),
  },
}));

export interface WorkoutExerciseHistoryProps extends BoxProps {
  exercise: WorkoutExercise;
}

export function WorkoutExerciseHistory(props: WorkoutExerciseHistoryProps) {
  const { className, exercise, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const isClient = user.role === UserRole.CLIENT;
  const [selectedClientId, setSelectedClientId] = React.useState<string>(
    isClient ? user.id : null,
  );
  const [search, setSearch] = React.useState("");
  const data = useLazyLoadQuery<WorkoutExerciseHistoryQuery>(
    graphql`
      query WorkoutExerciseHistoryQuery(
        $exerciseId: String
        $exerciseTitle: String
      ) {
        workoutExerciseResults(
          exerciseId: $exerciseId
          exerciseTitle: $exerciseTitle
        ) {
          edges {
            node {
              id
              client {
                id
                displayName
                email
                photoURL
              }
              activity {
                component {
                  title
                  content
                }
                completedAt(format: "dddd MMMM DD, YYYY")
              }
              value
            }
          }
        }
      }
    `,
    {
      exerciseId: exercise.assetId,
      exerciseTitle: exercise.title,
    },
    {
      fetchPolicy: "store-or-network",
    },
  );

  const workoutExerciseResults = data.workoutExerciseResults.edges.map(
    ({ node: { value, ...node } }) => ({
      ...node,
      section: parseWorkoutResultValueJsonToWorkoutSection(value),
    }),
  );

  const clients = groupWorkoutClients(workoutExerciseResults, exercise);
  const selectedClient = clients.find((it) => it.id === selectedClientId);

  const filteredClients = clients.filter(({ displayName, email }) =>
    [displayName, email].some((text) => queryMatchesContent(text, search)),
  );

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [],
  );

  const handleSearchClick = React.useCallback(() => {
    if (selectedClient) {
      setSearch("");
      setSelectedClientId("");
    }
  }, [selectedClient]);

  React.useEffect(() => {
    if (selectedClient) {
      setSearch(selectedClient.displayName);
    }
  }, [selectedClient]);

  const empty = clients.length === 0;
  const theme = useTheme();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {!isClient && (
        <TextField
          className={s.search}
          variant="outlined"
          fullWidth
          placeholder="Select a client"
          value={search}
          onChange={handleSearchChange}
          onClick={handleSearchClick}
          disabled={empty}
          InputProps={{
            endAdornment: <CancelIcon fill={theme.palette.primary.main} />,
          }}
        />
      )}

      {empty ? (
        <Typography className={s.empty}>
          <HistoryIcon fill={theme.palette.primary.main} />
          <br />
          No history to display.
        </Typography>
      ) : selectedClient ? (
        <>
          {selectedClient.sections.map((section, index) =>
            section.exercises.map((exercise) => (
              <WorkoutExerciseHistoryResult
                key={[index, exercise.id].join(":")}
                client={selectedClient}
                section={section}
                exercise={exercise}
              />
            )),
          )}
        </>
      ) : (
        filteredClients.length > 0 && (
          <Box className={s.clients}>
            {filteredClients.map((client, index) => (
              <React.Fragment key={client.id}>
                {index !== 0 && <Divider className={s.divider} />}
                <WorkoutExerciseHistoryClient
                  client={client}
                  onClientSelect={setSelectedClientId}
                />
              </React.Fragment>
            ))}
          </Box>
        )
      )}
    </Box>
  );
}
