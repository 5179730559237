import { ComponentType } from "../../../constants";
import { colorSystem } from "../../../theme";

export const getColorByComponentType = (type: ComponentType) => {
  switch (type) {
    case ComponentType.LESSON:
      return colorSystem.blue1;
    case ComponentType.HABIT:
      return colorSystem.yellow;
    case ComponentType.WORKOUT:
      return colorSystem.primary;
    case ComponentType.CHECKIN:
      return colorSystem.green2;
    case ComponentType.MESSAGE:
      return colorSystem.orange;

    default:
      return "unset";
  }
};
