import { brandedAppWorkspaceKey } from "../utils/mobile";

////// COMMON-ROUTE-PARAMS //////

export const SLUG_ROUTE_PARAM = "/:slug";
export const CODE_ROUTE_PARAM = "/:code";
export const USERNAME_ROUTE_PARAM = "/:username";
export const PROGRAM_ROUTE_PARAM = "/:program";
export const COMPONENT_ROUTE_PARAM = "/:component";
export const WORKSPACE_ROUTE_PARAM = "/:workspace";
export const CLIENT_ROUTE_PARAM = "/:client";
export const RECIPIENT_OPTIONAL_ROUTE_PARAM = "/:recipient?";
export const SORT_OPTIONAL_ROUTE_PARAM = "/:sort?";
export const DATE_ROUTE_PARAM = "/:date";
export const DATE_OPTIONAL_ROUTE_PARAM = "/:date?";

////// COMMON-ROUTES //////

export const DEFAULT_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";
export const INVITE_ROUTE = "/invite" + CODE_ROUTE_PARAM;
export const SIGN_UP_ROUTE = "/signup";
export const TERMS_ROUTE = "/terms";
export const PRIVACY_ROUTE = "/privacy";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const NOT_FOUND_ROUTE = "/404";
export const ACCESS_DENIED_ROUTE = "/403";
export const SITEMAP_ROUTE = "/sitemap";
export const HOME_ROUTE = "/home";

////// GENERAL //////

export const COACH_ROUTE = "/coach";
export const CLIENT_ROUTE = "/client";
export const GROUPS_ROUTE = "/groups";
export const PROGRAMS_ROUTE = "/programs";
export const CLIENTS_ROUTE = "/clients";
export const FORMS_ROUTE = "/forms";
export const ACTIVITY_ROUTE = "/activity";
export const MESSAGES_ROUTE = "/messages";
export const CLIENT_PORTAL_ROUTE = WORKSPACE_ROUTE_PARAM + "/login?";
export const PREVIEW_ROUTE = "/preview";
export const MEAL_LOGGING_ROUTE = "/meal-logging";
export const BRANDED_LOGIN_ROUTE = brandedAppWorkspaceKey + "/login?";

////// ADMIN //////

export const ADMIN_ROUTE = "/admin";
export const ADMIN_USERS_ROUTE = ADMIN_ROUTE + "/users";
export const ADMIN_USER_ROUTE = ADMIN_USERS_ROUTE + USERNAME_ROUTE_PARAM;

////// SIGNUP-CLIENT //////

export const SIGN_UP_CLIENT = SIGN_UP_ROUTE + "/client";
export const SIGN_UP_CLIENT_COMPLETE = SIGN_UP_CLIENT + "/complete";
export const SIGN_UP_CLIENT_COMPLETE_PHOTO = SIGN_UP_CLIENT_COMPLETE + "/photo";

//////
////// COACH //////
//////
//////

////// COACH-SETTINGS //////

export const COACH_PROFILE_ROUTE = COACH_ROUTE + "/profile";
export const COACH_CLIENT_PORTAL_ROUTE = COACH_ROUTE + "/client-portal";
export const COACH_THEME_ROUTE = COACH_ROUTE + "/theme";
export const COACH_PLAN_ROUTE = COACH_ROUTE + "/plan";
export const COACH_INTEGRATION_ROUTE = COACH_ROUTE + "/integration";
export const COACH_CARD_INFORMATION_ROUTE = COACH_ROUTE + "/card-information";

// COACH-GROUPS
export const COACH_GROUPS_ROUTE = COACH_ROUTE + GROUPS_ROUTE;

export const COACH_GROUPS_ACTIVE_ROUTE = COACH_GROUPS_ROUTE + "/active";
export const COACH_GROUPS_ARCHIVED_ROUTE = COACH_GROUPS_ROUTE + "/archived";

export const COACH_GROUP_ROUTE = COACH_GROUPS_ROUTE + SLUG_ROUTE_PARAM;
export const COACH_GROUP_DEFAULT_ROUTE = "/posts";

export const COACH_GROUP_POST_ROUTE =
  COACH_GROUP_ROUTE + COACH_GROUP_DEFAULT_ROUTE;
export const COACH_GROUP_MEMBERS_ROUTE = COACH_GROUP_ROUTE + "/members";
export const COACH_GROUP_SETTINGS_ROUTE = COACH_GROUP_ROUTE + "/settings";

// COACH-PROGRAMS
export const COACH_PROGRAMS_ROUTE = COACH_ROUTE + PROGRAMS_ROUTE;

export const COACH_PROGRAMS_PUBLISHED_ROUTE =
  COACH_PROGRAMS_ROUTE + "/published";
export const COACH_PROGRAMS_DRAFT_ROUTE = COACH_PROGRAMS_ROUTE + "/draft";
export const COACH_PROGRAMS_ARCHIVED_ROUTE = COACH_PROGRAMS_ROUTE + "/archived";
export const COACH_PROGRAMS_FOLDERS_ROUTE = COACH_PROGRAMS_ROUTE + "/folders";

export const COACH_PROGRAM_ROUTE = COACH_PROGRAMS_ROUTE + SLUG_ROUTE_PARAM;
export const COACH_PROGRAM_DEFAULT_ROUTE = "/curriculum";

export const COACH_PROGRAM_CURRICULUM_ROUTE =
  COACH_PROGRAM_ROUTE + COACH_PROGRAM_DEFAULT_ROUTE;
export const COACH_PROGRAM_ENROLLMENTS_ROUTE =
  COACH_PROGRAM_ROUTE + "/enrollments";
export const COACH_PROGRAM_SETTING_ROUTE = COACH_PROGRAM_ROUTE + "/settings";

export const COACH_PROGRAM_CREATE_ROUTE = COACH_PROGRAMS_ROUTE + "/create";
export const COACH_PROGRAM_SUMMARY_ROUTE =
  COACH_PROGRAMS_ROUTE + "/summary/:todo";

export const COACH_PROGRAM_PREVIEW_ROUTE =
  COACH_PROGRAM_CURRICULUM_ROUTE + PREVIEW_ROUTE;

export const COACH_PROGRAM_COMPONENT_ROUTE =
  COACH_PROGRAM_CURRICULUM_ROUTE + "/:type" + "/:componentSlug" + "/:preview?";

export const COACH_PROGRAM_COMPONENT_CLIENT_SUMMARY =
  COACH_PROGRAMS_ROUTE +
  "/summary" +
  SLUG_ROUTE_PARAM +
  DATE_ROUTE_PARAM +
  COMPONENT_ROUTE_PARAM +
  CLIENT_ROUTE_PARAM;

// COACH-CLIENTS
export const COACH_CLIENTS_ROUTE = COACH_ROUTE + CLIENTS_ROUTE;

export const COACH_CLIENTS_ACTIVE_ROUTE = COACH_CLIENTS_ROUTE + "/active";
export const COACH_CLIENTS_ARCHIVED_ROUTE = COACH_CLIENTS_ROUTE + "/archived";
export const COACH_CLIENTS_PENDING_ROUTE = COACH_CLIENTS_ROUTE + "/pending";

export const COACH_CLIENTS_FORMS_ROUTE = COACH_CLIENTS_ROUTE + FORMS_ROUTE;
export const COACH_CLIENTS_FORM_ROUTE =
  COACH_CLIENTS_ROUTE + FORMS_ROUTE + SLUG_ROUTE_PARAM;

export const COACH_CLIENT_ROUTE = COACH_CLIENTS_ROUTE + USERNAME_ROUTE_PARAM;
export const COACH_CLIENT_DEFAULT_ROUTE = "/overview";

export const COACH_CLIENT_OVERVIEW_ROUTE =
  COACH_CLIENT_ROUTE + COACH_CLIENT_DEFAULT_ROUTE;
export const COACH_CLIENT_CALENDAR_ROUTE = COACH_CLIENT_ROUTE + "/calendar";
export const COACH_CLIENT_RESPONSE_ROUTE = COACH_CLIENT_ROUTE + "/responses";
export const COACH_CLIENT_NOTES_ROUTE = COACH_CLIENT_ROUTE + "/notes";

export const COACH_CLIENT_PR0GRAMS_ROUTE = COACH_CLIENT_ROUTE + "/programs";
export const COACH_CLIENT_PR0GRAM_ROUTE =
  COACH_CLIENT_ROUTE +
  PROGRAMS_ROUTE +
  PROGRAM_ROUTE_PARAM +
  DATE_OPTIONAL_ROUTE_PARAM;

export const COACH_CLIENT_PR0GRAM_COMPONENT_ROUTE =
  COACH_CLIENT_PR0GRAM_ROUTE + COMPONENT_ROUTE_PARAM;

export const COACH_CLIENT_FORM_ROUTE =
  COACH_CLIENT_ROUTE + FORMS_ROUTE + SLUG_ROUTE_PARAM;

export const COACH_CLIENT_SETTINGS_ROUTE = COACH_CLIENT_ROUTE + "/settings";
export const COACH_CLIENT_TRENDS_ROUTE = COACH_CLIENT_ROUTE + "/trends";
export const COACH_CLIENT_STATS_ROUTE = COACH_CLIENT_ROUTE + "/stats";
export const COACH_CLIENT_PROGRESS_ROUTE = COACH_CLIENT_ROUTE + "/progress";

// COACH-ACTIVITY
export const COACH_ACTIVITY_ROUTE = COACH_ROUTE + ACTIVITY_ROUTE;

export const COACH_ACTIVITY_INBOX_ROUTE = COACH_ACTIVITY_ROUTE + "/inbox";
export const COACH_ACTIVITY_ARCHIVED_ROUTE = COACH_ACTIVITY_ROUTE + "/archived";

// COACH-MESSAGE
export const COACH_MESSAGES_ROUTE = COACH_ROUTE + MESSAGES_ROUTE;
export const COACH_MESSAGE_ROUTE =
  COACH_MESSAGES_ROUTE + RECIPIENT_OPTIONAL_ROUTE_PARAM;

//////
////// CLIENTS //////
//////
//////

////// CLIENT-GENERAL //////

export const CLIENT_HOME_ROUTE = HOME_ROUTE + DATE_OPTIONAL_ROUTE_PARAM;
export const CLIENT_MEAL_LOGGING_ROUTE = MEAL_LOGGING_ROUTE + DATE_ROUTE_PARAM;
export const CLIENT_FORM_SLUG_ROUTE = FORMS_ROUTE + SLUG_ROUTE_PARAM;

export const CLIENT_PROFILE_ROUTE = "/profile";
export const CLIENT_PROFILE_TRENDS_ROUTE = CLIENT_PROFILE_ROUTE + "/trends";

export const CLIENT_ACTIVITY_ROUTE = ACTIVITY_ROUTE;
export const CLIENT_TRIAL_EXPIRED_ROUTE = "/trial-expired";
export const CLIENT_SUBSCRIPTION_CANCELED_ROUTE = "/subscription-canceled";

////// CLIENT-SETTINGS //////

export const CLIENT_SETTING_ROUTE = "/settings";

export const CLIENT_SETTING_PROFILE_ROUTE = CLIENT_SETTING_ROUTE + "/profile";
export const CLIENT_SETTING_NOTIFICATIONS_ROUTE =
  CLIENT_SETTING_ROUTE + "/notifications";

export const CLIENT_PROGRAMS_ROUTE = "/programs";

export const CLIENT_PROGRAMS_ACTIVE_ROUTE = CLIENT_PROGRAMS_ROUTE + "/active";
export const CLIENT_PROGRAMS_UPCOMING_ROUTE =
  CLIENT_PROGRAMS_ROUTE + "/upcoming";
export const CLIENT_PROGRAMS_PAST_ROUTE = CLIENT_PROGRAMS_ROUTE + "/past";

const CLIENT_PROGRAMS_COMPONENT_COMMON_PARAMS =
  PROGRAM_ROUTE_PARAM + DATE_ROUTE_PARAM + COMPONENT_ROUTE_PARAM;

export const CLIENT_PROGRAMS_PROGRAM_ROUTE =
  CLIENT_PROGRAMS_ROUTE + PROGRAM_ROUTE_PARAM;

export const CLIENT_PROGRAMS_PROGRAM_COMPONENT_ROUTE =
  CLIENT_PROGRAMS_ROUTE + CLIENT_PROGRAMS_COMPONENT_COMMON_PARAMS;

export const CLIENT_PROGRAMS_ACTIVITY_ROUTE =
  CLIENT_PROGRAMS_ROUTE + "/:status" + CLIENT_PROGRAMS_COMPONENT_COMMON_PARAMS;

////// CLIENT-GROUPS //////

export const CLIENT_GROUP_ROUTE = GROUPS_ROUTE + SLUG_ROUTE_PARAM;
export const CLIENT_GROUP_DEFAULT_ROUTE = "/posts";
export const CLIENT_GROUP_POST_ROUTE =
  CLIENT_GROUP_ROUTE + CLIENT_GROUP_DEFAULT_ROUTE;
export const CLIENT_GROUP_MEMBERS_ROUTE = CLIENT_GROUP_ROUTE + "/members";

export const CLIENT_MESSAGE_ROUTE =
  MESSAGES_ROUTE + RECIPIENT_OPTIONAL_ROUTE_PARAM;

//////
////// ROUTES_LISTS //////
//////
//////

export const COMMON_ROUTES_LIST = [
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  INVITE_ROUTE,
  SIGN_UP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  // TODO add to sitemap after done
  // TERMS_ROUTE,
  // PRIVACY_ROUTE,
  NOT_FOUND_ROUTE,
];

export const SIGN_UP_ROUTES_LIST = [
  SIGN_UP_CLIENT,
  SIGN_UP_CLIENT_COMPLETE,
  SIGN_UP_CLIENT_COMPLETE_PHOTO,
];

export const COACH_SETTINGS_ROUTES_LIST = [
  COACH_PROFILE_ROUTE,
  COACH_CLIENT_PORTAL_ROUTE,
  COACH_THEME_ROUTE,
  COACH_PLAN_ROUTE,
  COACH_INTEGRATION_ROUTE,
  COACH_CARD_INFORMATION_ROUTE,
];

export const COACH_GROUP_ROUTES_LIST = [
  COACH_GROUP_POST_ROUTE,
  COACH_GROUP_MEMBERS_ROUTE,
  COACH_GROUP_SETTINGS_ROUTE,
];

export const COACH_PROGRAMS_LIST = [
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  COACH_PROGRAMS_DRAFT_ROUTE,
  COACH_PROGRAMS_ARCHIVED_ROUTE,
  COACH_PROGRAMS_FOLDERS_ROUTE,
  COACH_PROGRAM_CREATE_ROUTE,
  COACH_PROGRAM_SUMMARY_ROUTE,
];

export const COACH_PROGRAM_LIST = [
  COACH_PROGRAM_CURRICULUM_ROUTE,
  COACH_PROGRAM_ENROLLMENTS_ROUTE,
  COACH_PROGRAM_SETTING_ROUTE,
  COACH_PROGRAM_COMPONENT_ROUTE,
  COACH_PROGRAM_PREVIEW_ROUTE,
  COACH_PROGRAM_COMPONENT_CLIENT_SUMMARY,
];

export const COACH_GROUPS_ROUTES_LIST = [
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_GROUPS_ARCHIVED_ROUTE,
];

export const COACH_CLIENTS_LIST = [
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_CLIENTS_ARCHIVED_ROUTE,
  COACH_CLIENTS_PENDING_ROUTE,
  COACH_CLIENTS_FORMS_ROUTE,
  COACH_CLIENTS_FORM_ROUTE,
];

export const COACH_CLIENT_ROUTES_LIST = [
  COACH_CLIENT_OVERVIEW_ROUTE,
  COACH_CLIENT_CALENDAR_ROUTE,
  COACH_CLIENT_RESPONSE_ROUTE,
  COACH_CLIENT_NOTES_ROUTE,
  COACH_CLIENT_FORM_ROUTE,
  COACH_CLIENT_SETTINGS_ROUTE,
  COACH_CLIENT_TRENDS_ROUTE,
  // TODO add to sitemap after done
  // COACH_CLIENT_STATS_ROUTE,
  // COACH_CLIENT_PROGRESS_ROUTE,
  COACH_CLIENT_PR0GRAMS_ROUTE,
  COACH_CLIENT_PR0GRAM_ROUTE,
  COACH_CLIENT_PR0GRAM_COMPONENT_ROUTE,
];

export const COACH_ACTIVITY_ROUTES_LIST = [
  COACH_ACTIVITY_INBOX_ROUTE,
  COACH_ACTIVITY_ARCHIVED_ROUTE,
];

export const COACH_MESSAGE_ROUTES_LIST = [
  COACH_MESSAGES_ROUTE,
  COACH_MESSAGE_ROUTE,
];

export const CLIENT_SETTINGS_ROUTES_LIST = [
  CLIENT_SETTING_PROFILE_ROUTE,
  CLIENT_SETTING_NOTIFICATIONS_ROUTE,
];

export const CLIENT_GENERAL_ROUTES_LIST = [
  CLIENT_HOME_ROUTE,
  CLIENT_MEAL_LOGGING_ROUTE,
  CLIENT_PROFILE_ROUTE,
  CLIENT_PROFILE_TRENDS_ROUTE,
  CLIENT_FORM_SLUG_ROUTE,
  CLIENT_ACTIVITY_ROUTE,
  CLIENT_TRIAL_EXPIRED_ROUTE,
  CLIENT_SUBSCRIPTION_CANCELED_ROUTE,
];

export const CLIENT_PROGRAMS_ROUTES_LIST = [
  CLIENT_PROGRAMS_ACTIVE_ROUTE,
  CLIENT_PROGRAMS_UPCOMING_ROUTE,
  CLIENT_PROGRAMS_PAST_ROUTE,
  CLIENT_PROGRAMS_PROGRAM_ROUTE,
  CLIENT_PROGRAMS_PROGRAM_COMPONENT_ROUTE,
  CLIENT_PROGRAMS_ACTIVITY_ROUTE,
];

export const ALL_ROUTES = [
  COMMON_ROUTES_LIST,
  SIGN_UP_ROUTES_LIST,
  COACH_SETTINGS_ROUTES_LIST,
  COACH_GROUP_ROUTES_LIST,
  COACH_PROGRAMS_LIST,
  COACH_PROGRAM_LIST,
  COACH_GROUPS_ROUTES_LIST,
  COACH_CLIENTS_LIST,
  COACH_CLIENT_ROUTES_LIST,
  COACH_ACTIVITY_ROUTES_LIST,
  COACH_MESSAGE_ROUTES_LIST,
  CLIENT_SETTINGS_ROUTES_LIST,
  CLIENT_GENERAL_ROUTES_LIST,
  CLIENT_PROGRAMS_ROUTES_LIST,
];
