import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AppLogo } from "../app/AppLogo";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { AuthButton } from "./AuthButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Elements } from "@stripe/react-stripe-js";
import SignUpCardDetails from "./SignUpCardDetails";
import { STRIPE_MODE } from "../../stripe";
import { loadStripe } from "@stripe/stripe-js";
import {
  billingPlanPrice,
  Plan,
  planClients,
  PlanSubscription,
  tierIndex,
  PlanTier,
} from "../../constants";
import { colorSystem } from "../../theme";
import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormTrigger,
} from "react-hook-form";
import {
  CoachSignupField,
  CoachSignupSchema,
  stepFields,
} from "../../utils/coachSignupSchema";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { urlConstants } from "../../constants/urlConstants";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 457,
  },

  logo: {
    marginBottom: theme.spacing(8),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    marginTop: theme.spacing(1),
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  signIn: {
    marginTop: theme.spacing(1),
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  googleLogo: {
    marginRight: theme.spacing(2),
  },

  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  input: {
    marginTop: theme.spacing(1.25),
  },

  conditions: {
    margin: theme.spacing(2, "auto", 0),
    width: 274,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    textAlign: "center",
  },

  passwordInput: {
    paddingRight: 0,
    backgroundColor: "white",
  },
  note: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "bold",
    marginTop: theme.spacing(3),
  },
  noteText: {
    fontWeight: "normal",
  },
  select: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),
    marginTop: theme.spacing(3),
    color: "black",

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
    "$view [role=button] &": {
      display: "none !important",
    },
  },
  sort: {},
  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),
    "$sort [role=button] &": {
      color: "black",
    },
  },
  noVisible: {
    display: "none",
  },
  flexInput: {
    display: "flex",
  },
  separator: {
    margin: theme.spacing(0, 0.5),
  },
  already: {
    color: colorSystem.black,
    marginBottom: theme.spacing(2),
  },
  info: {
    borderRadius: 0,
    boxShadow: "none",
    borderWidth: 1,
    borderColor: colorSystem.gray4,
    borderStyle: "solid",
    marginTop: theme.spacing(3),
    padding: theme.spacing(2.3, 1.75),
  },
  infoTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "20px",
    color: colorSystem.black,
    paddingBottom: 10,
  },
  infoText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: colorSystem.gray3,
    paddingTop: 4,
  },
}));

interface countriesListItem {
  name: string;
  code: string;
}

export interface SignUpStartFormProps extends BoxProps {
  register: UseFormRegister<CoachSignupSchema>;
  getValues: UseFormGetValues<CoachSignupSchema>;
  trigger: UseFormTrigger<CoachSignupSchema>;
  errors: FieldErrors<CoachSignupSchema>;
  setError: UseFormSetError<CoachSignupSchema>;
  dirtyFields: string[];
  step: number;
  setStep: (step: number) => void;
  onPreSubmit: () => void;
  disabled?: boolean;
  trialLength?: number;
  countriesList: countriesListItem[];
  plan: string;
  clientSecret: string;
  // handleOnBlurInput: (event: any) => void;
  onSaveCard: boolean;
  setSavingCard: any;
  closePreloader: () => void;
}
const SignUpStartForm = (props: SignUpStartFormProps) => {
  const {
    className,
    countriesList,
    onPreSubmit,
    disabled,
    step,
    setStep,
    register,
    getValues,
    trigger,
    errors,
    setError,
    dirtyFields,
    plan,
    trialLength = 14,
    onSaveCard,
    setSavingCard,
    clientSecret,
    closePreloader,
    ...other
  } = props;
  const s = useStyles();
  const brandName = useCurrentBrandName();
  const isMobile = useMediaMobile();

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const VisibilityIcon = passwordVisible ? VisibilityOff : Visibility;
  const [loading, setLoading] = React.useState(false);

  const handlePasswordVisibilityToggle = React.useCallback(() => {
    setPasswordVisible((value) => !value);
  }, []);

  const STRIPE_TEST_PUBLISHABLE_KEY =
    "pk_test_51LsOvfKMXGjdxyrwoHwTa7Xsdb01Bj1DLaUJs84wBeGboFhy6fePnZYHQ96SdojRMD7rPWqs4vYuSkhwnxwHTc2E0010HJDVh4";
  const STRIPE_LIVE_PUBLISHABLE_KEY =
    "pk_live_51LsOvfKMXGjdxyrwH1Rt0XRHMFCKyTBS37ri8yjO6SSvmYX2AeANK77oaUMXSPFyTOr7A5hbiBubPrYY6TSAuEoE009l1R2dlK";

  const publishableKey =
    STRIPE_MODE === "live"
      ? STRIPE_LIVE_PUBLISHABLE_KEY
      : STRIPE_TEST_PUBLISHABLE_KEY;

  const promise = loadStripe(publishableKey);

  const currentStepCompleted = React.useMemo(() => {
    return stepFields[step].every((field) => dirtyFields.includes(field));
  }, [dirtyFields, step]);

  const validateCurrentStep = React.useCallback(
    async (onValid: () => void) => {
      {
        if (await trigger(stepFields[step] as CoachSignupField[], {})) {
          if (step === 1 && getValues("email") !== getValues("confirmEmail")) {
            setError("confirmEmail", {
              type: "manual",
              message: "Emails do not match",
            });
            return;
          }
          onValid();
        }
      }
    },
    [setStep, step],
  );

  const snackAlert = useSnackAlert();
  const showCopyPasteAlert = React.useCallback(
    (action: "paste" | "copy") => {
      snackAlert({
        message: `You cannot ${action} ${
          action === "paste" ? "into" : "from"
        } this field`,
        severity: "info",
      });
    },
    [snackAlert],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {isMobile && (
        <AppLogo className={s.logo} main full width={160} height={37} />
      )}
      {plan === "PROMO" ? (
        <>
          <Typography className={s.title} variant="h1">
            {brandName} Build Programme
          </Typography>
          <Typography className={s.subtitle} variant="h5">
            Last Few Spaces Remaining
          </Typography>
        </>
      ) : (
        <>
          <Typography className={s.title} variant="h1">
            Get started TODAY
          </Typography>
          <Typography className={s.subtitle} variant="h5">
            Start your {trialLength} day free trial below
          </Typography>
        </>
      )}
      <Typography className={clsx(s.signIn, s.already)}>
        Already have an account? <Link href="/login">Log In</Link>
      </Typography>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onPreSubmit();
        }}
      >
        {step === 1 && (
          <>
            <TextField
              className={s.input}
              name="email"
              type="email"
              label="Email address"
              placeholder="Email address"
              {...register("email")}
              error={Boolean(errors.email)}
              helperText={errors.email?.message ?? " "}
              variant="outlined"
              fullWidth
              onPaste={(e) => {
                e.preventDefault();
                showCopyPasteAlert("paste");
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                showCopyPasteAlert("copy");
                return false;
              }}
            />

            <TextField
              className={s.input}
              name="confirmEmail"
              type="email"
              label="Confirm email address"
              placeholder="Confirm email address"
              {...register("confirmEmail")}
              error={Boolean(errors.confirmEmail)}
              helperText={errors.confirmEmail?.message ?? " "}
              variant="outlined"
              fullWidth
              onPaste={(e) => {
                e.preventDefault();
                showCopyPasteAlert("paste");
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                showCopyPasteAlert("copy");
                return false;
              }}
            />

            <TextField
              className={s.input}
              name="fullName"
              type="text"
              label="Full name"
              placeholder="Full name"
              {...register("fullName")}
              error={Boolean(errors.fullName)}
              helperText={errors.fullName?.message ?? " "}
              variant="outlined"
              fullWidth
            />

            <TextField
              className={s.input}
              name="password"
              type={passwordVisible ? "text" : "password"}
              label="Password"
              placeholder="Password"
              {...register("password")}
              error={Boolean(errors.password)}
              helperText={errors.password?.message ?? " "}
              variant="outlined"
              fullWidth
              InputProps={{
                className: s.passwordInput,
                endAdornment: (
                  <IconButton
                    onClick={handlePasswordVisibilityToggle}
                    size="large"
                  >
                    <VisibilityIcon />
                  </IconButton>
                ),
              }}
            />
            <AuthButton
              onClick={async (e) => {
                e.preventDefault();
                validateCurrentStep(() => setStep(2));
              }}
              disabled={!currentStepCompleted}
              children="Continue"
            />
          </>
        )}
        {step === 2 && (
          <>
            <Elements stripe={promise}>
              <SignUpCardDetails
                onSaveCard={onSaveCard}
                setSavingCard={setSavingCard}
                clientSecret={clientSecret}
                plan={plan}
                email={getValues("email")}
                password={getValues("password")}
                fullName={getValues("fullName")}
                addressLine1={getValues("addressLine1")}
                country={getValues("country")}
                city={getValues("city")}
                postalCode={getValues("postalCode")}
                setLoading={setLoading}
                closePreloader={closePreloader}
              />
            </Elements>

            <Typography className={clsx(s.title, s.divider)} variant="h1">
              Billing address for this card
            </Typography>

            <Stack spacing={3.5}>
              <TextField
                className={s.input}
                name="addressLine1"
                type="text"
                label="Address line 1"
                placeholder="Address line 1"
                {...register("addressLine1")}
                variant="outlined"
                fullWidth
              />

              <TextField
                className={s.input}
                name="addressLine2"
                type="text"
                label="Address line 2"
                placeholder="Address line 2"
                {...register("addressLine2")}
                variant="outlined"
                fullWidth
              />

              <Select
                className={clsx(s.select, s.sort)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }}
                variant="outlined"
                {...register("country")}
                value={getValues("country") || "Country"}
                name="country"
              >
                {countriesList.map(({ name }) => (
                  <MenuItem
                    value={name}
                    key={name}
                    className={clsx(name === "Country" && s.noVisible)}
                  >
                    <ListItemText
                      classes={{ primary: s.primaryText }}
                      primary={name}
                    />
                  </MenuItem>
                ))}
              </Select>

              <Box className={s.flexInput}>
                <TextField
                  name="city"
                  type="text"
                  label="City"
                  placeholder="City"
                  {...register("city")}
                  variant="outlined"
                  fullWidth
                />
                <div className={s.separator} />
                <TextField
                  name="postalCode"
                  type="text"
                  label="Postal Code"
                  placeholder="Postal Code"
                  {...register("postalCode")}
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Stack>
            <Card className={s.info}>
              <Typography
                className={s.infoTitle}
                variant="h3"
                children={"Your plan details"}
              />
              <Typography
                className={s.infoText}
                variant="h3"
                children={PlanSubscription[Plan[plan]]}
              />
              <Typography
                className={s.infoText}
                variant="h3"
                children={`Up to ${planClients(Plan[plan])} clients`}
              />
              <Typography
                className={s.infoText}
                variant="h3"
                children={
                  plan.includes("YEARLY") ? "Billed Annually" : "Billed monthly"
                }
              />

              <Typography
                className={s.infoText}
                variant="h3"
                children={
                  plan === "PROMO"
                    ? `$11 upfront then
            $95 in 30 days if you want to continue after your free trial`
                    : `Amount: 14 days free then
            $${billingPlanPrice[plan][tierIndex[PlanTier.DEFAULT]]}.00`
                }
              />
            </Card>
            <AuthButton
              onClick={() => {
                setStep(1);
              }}
              children="Back"
            />
            <AuthButton
              type="submit"
              children="Register"
              disabled={disabled || !currentStepCompleted || loading}
            />
          </>
        )}
      </form>

      <Typography className={s.conditions}>
        By signing up, you agree to our{" "}
        <Link
          href={urlConstants.terms}
          target="_blank"
          rel="noopener noreferrer"
        >
          terms of service
        </Link>{" "}
        and{" "}
        <Link
          href={urlConstants.privacy}
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default React.memo(SignUpStartForm);
