import React from "react";
import { sortBy } from "lodash";

import { ComponentStatus, ComponentRepeat, ComponentType } from "../constants";
import { Filters } from "../components/program/ProgramDetailsFilters";
import {
  componentComparator,
  canScheduleComponentAtWeek,
} from "../components/program-calendar/utils";

export type ProgramWeekSchedule = Array<{
  weeks: number[];
  days: boolean[];
  component: any;
}>;

export type ScheduleUpdate = {
  id: string;
  weekId: string;
  days: boolean[];
  originDay: number;
  originWeek: number;
};

export function useProgramSchedule(
  programRef: any,
  filters: Filters,
  scheduleUpdate?: ScheduleUpdate,
) {
  const components = React.useMemo(() => {
    const acc = [];

    for (const week of programRef.weeks.edges) {
      const _components = week.node.components
        .filter(Boolean)
        .filter(({ type }) => !filters || filters[type])
        .filter(
          ({ status }) =>
            status !== ComponentStatus.ARCHIVED ||
            (filters && filters.archived),
        );
      const components = sortBy(_components, ({ id }) => {
        const index = week.node.positions.indexOf(id);

        return index === -1 ? Date.now() : index;
      });

      for (const component of components) {
        if (!acc.some((it) => it.id === component.id)) {
          acc.push(component);
        }
      }
    }

    return sortBy(acc, componentComparator);
  }, [filters, programRef.weeks]);

  const schedule: ProgramWeekSchedule = React.useMemo(
    () =>
      components.map((component) => {
        const { type, duration, repeat } = component;
        const update = scheduleUpdate?.id === component.id && scheduleUpdate;
        const weekId = update?.weekId || component.weekId;
        const days = update?.days || (component.days as boolean[]);
        const startingWeek = programRef.weeks.edges.find(
          ({ node: { id } }) => id === weekId,
        );
        const startingWeekNumber = startingWeek ? startingWeek.node.week : 1;
        const weeks = programRef.weeks.edges
          .map(({ node: { week } }) => week)
          .filter((week) =>
            canScheduleComponentAtWeek(
              startingWeekNumber,
              week,
              type as ComponentType,
              repeat as ComponentRepeat,
              duration,
            ),
          );

        return {
          weeks,
          days,
          component,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [components, programRef.weeks, scheduleUpdate],
  );

  return [components, schedule] as const;
}
