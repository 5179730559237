import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  MenuItem,
  ListItemText,
  MenuItemProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useEditorProgram } from "../new-editor/hooks";

const useStyles = makeStyles((theme) => ({
  root: {},
  selected: {
    backgroundColor: theme.palette.selected.main,
  },
}));

export interface SelectComponentWeekProps extends Omit<BoxProps, "onChange"> {
  onChange?: (weekId: string) => void;
  weekId?: string;
}

export function SelectComponentWeek(props: SelectComponentWeekProps) {
  const { className, onChange, weekId, ...other } = props;
  const s = useStyles();

  const { weeks } = useEditorProgram();

  const handleClick: MenuItemProps["onClick"] = React.useCallback(
    (event) => {
      const { weekId } = event.currentTarget.dataset;

      if (onChange) {
        onChange(weekId);
      }
    },
    [onChange],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {weeks.edges.map(({ node: { id, week } }) => (
        <MenuItem
          key={id}
          data-week-id={id}
          onClick={handleClick}
          className={clsx({
            [s.selected]: id === weekId,
          })}
        >
          <ListItemText primary={`Week ${week}`} />
        </MenuItem>
      ))}
    </Box>
  );
}
