import clsx from "clsx";
import React from "react";
import {
  TextField,
  DialogProps,
  FormControl,
  FormLabel,
  Box,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
  SelectProps,
  ListItemIcon,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { BaseDialog } from "../dialog/BaseDialog";

import { useAnalytics } from "../../hooks/useAnalytics";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import {
  ProgramDetailsViewMode,
  defaultCalendarViewOptions,
} from "../program/ProgramDetailsViewButton";
import { maybePluralize } from "../../utils/text";
import { SettingsCoverImage } from "../group-settings/SettingsCoverImage";
import { AssetType, getRandomProgramGalleryImage } from "../../constants";
import { ActionButton } from "../button/ActionButton";
import { useCurrentUserId } from "../../hooks/useCurrentUser";

import { CreateProgramDialogMutation } from "./__generated__/CreateProgramDialogMutation.graphql";
import { useNavigate } from "react-router-dom";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(84),
    },
  },

  paper: {
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(84),
    },
  },

  control: {
    margin: theme.spacing(1.5, 0),
    width: "100%",
  },

  label: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
    color: `${theme.palette.text.secondary} !important`,
  },

  fieldsRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
  },

  select: {
    "& > div": {
      padding: 0,
    },
  },

  option: {
    padding: theme.spacing(0, 2),
  },

  optionIcon: {
    color: theme.palette.text.primary,
    minWidth: theme.spacing(3),
  },

  primaryText: {
    fontSize: 16,
    fontWeight: 500,
  },

  coverImage: {
    "& h4": {
      marginBottom: theme.spacing(1),
    },
  },

  submit: {
    marginTop: theme.spacing(3),
  },
}));

const createProgramMutation = graphql`
  mutation CreateProgramDialogMutation($input: CreateProgramInput!) {
    createProgram(input: $input) {
      program {
        ...CoachProgramCard_program
        id
        slug
      }
    }
  }
`;

export interface CreateProgramDialogProps extends DialogProps {}

export function CreateProgramDialog(props: CreateProgramDialogProps) {
  const { className, ...other } = props;
  const [createProgram, inFlight] = useMutation<CreateProgramDialogMutation>(
    createProgramMutation,
  );
  const navigate = useNavigate();
  const userId = useCurrentUserId();
  const s = useStyles();
  const [trackEvent] = useAnalytics();
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler(false, {
    name: "Program name",
  });

  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [programLength, setProgramLength] = React.useState(1);
  const [imageURL, setImageURL] = React.useState(
    getRandomProgramGalleryImage(),
  );
  const [defaultView, setDefaultView] = React.useState(
    ProgramDetailsViewMode.LIST,
  );

  const handleSubmit = React.useCallback(
    () =>
      createProgram({
        variables: {
          input: {
            name,
            description,
            image: imageURL,
            length: programLength,
            defaultView: defaultView.toUpperCase(),
          },
        },
        onCompleted({ createProgram }, errors) {
          if (errors) {
            onError(errors[0]);
          } else {
            trackEvent("Coach - Create Program", {
              name: name,
              length: programLength,
            });

            snackAlert({
              severity: "success",
              message: "Successfully created new program.",
            });

            const { program } = createProgram;
            navigate(
              COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", program.slug),
            );
          }
        },
      }),
    [
      createProgram,
      name,
      description,
      imageURL,
      programLength,
      defaultView,
      onError,
      trackEvent,
      snackAlert,
    ],
  );

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setName(event.target.value);
    },
    [],
  );

  const handleDescriptionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setDescription(event.target.value);
    },
    [],
  );

  const handleProgramLengthChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setProgramLength(event.target.value as number);
    },
    [],
  );

  const handleChangeCoverImage = React.useCallback((value: string) => {
    setImageURL(value || "");
  }, []);

  const handleDefaultViewChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setDefaultView(event.target.value as ProgramDetailsViewMode);
    },
    [],
  );

  const disabled = inFlight;
  const completed = Boolean(name && description);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      PaperProps={{ className: s.paper }}
      title="Create your program"
      {...other}
    >
      <FormControl className={s.control}>
        <FormLabel
          className={s.label}
          component="label"
          children="Basic information"
        />

        <TextField
          variant="outlined"
          fullWidth
          id="name"
          placeholder="Program name"
          value={name}
          onChange={handleNameChange}
          disabled={disabled}
          required
        />
      </FormControl>

      <FormControl className={s.control}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          id="description"
          placeholder="Program description"
          value={description}
          onChange={handleDescriptionChange}
          disabled={disabled}
          required
        />
      </FormControl>

      <Box className={s.fieldsRow}>
        <FormControl className={s.control}>
          <FormLabel
            className={s.label}
            htmlFor="length"
            component="label"
            children="Length"
          />
          <Select
            variant="outlined"
            id="length"
            value={programLength}
            onChange={handleProgramLengthChange}
            fullWidth
            disabled={disabled}
            className={s.select}
          >
            {Array.from({ length: 99 }, (_, i) => i + 1).map((value) => (
              <MenuItem className={s.option} key={value} value={value}>
                <ListItem component="div">
                  <ListItemText classes={{ primary: s.primaryText }}>
                    {maybePluralize(value, "week")}
                  </ListItemText>
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={s.control}>
          <FormLabel
            className={s.label}
            htmlFor="defaultView"
            component="label"
            children="Default view"
          />
          <Select
            className={s.select}
            variant="outlined"
            id="defaultView"
            value={defaultView}
            onChange={handleDefaultViewChange}
            fullWidth
            disabled={disabled}
          >
            {Object.entries(defaultCalendarViewOptions).map(
              ([viewMode, { label, icon }]) => (
                <MenuItem key={viewMode} value={viewMode} className={s.option}>
                  <ListItem component="div">
                    <ListItemIcon className={s.optionIcon}>{icon}</ListItemIcon>
                    <ListItemText classes={{ primary: s.primaryText }}>
                      {label}
                    </ListItemText>
                  </ListItem>
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </Box>

      <SettingsCoverImage
        className={clsx(s.control, s.coverImage)}
        nodeId={userId}
        assetType={AssetType.LIBRARY_IMAGE}
        image={imageURL}
        onImageChange={handleChangeCoverImage}
        disabled={disabled}
        removable={false}
      >
        Give your program its own identity with a cover photo. You can change
        this later in your program settings.
      </SettingsCoverImage>

      <ActionButton
        className={s.submit}
        disabled={!completed}
        onClick={handleSubmit}
        size="large"
        fullWidth
      >
        Create your program
      </ActionButton>
    </BaseDialog>
  );
}
