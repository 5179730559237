import clsx from "clsx";
import React from "react";
import {
  Menu,
  MenuProps,
  MenuItem,
  ListItemIcon,
  ListItemText,
  MenuItemProps,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ComponentType } from "../../constants";
import { getContentType } from "../../utils/component";
import { colorSystem } from "../../theme";

import { ComponentTypeDefaultIcons } from "./icons";

const options = [
  {
    componentType: ComponentType.LESSON,
    description: "Introduce a new concept.",
    icon: ComponentTypeDefaultIcons[ComponentType.LESSON],
  },
  {
    componentType: ComponentType.HABIT,
    description: "Add a daily practice.",
    icon: ComponentTypeDefaultIcons[ComponentType.HABIT],
  },
  {
    componentType: ComponentType.CHECKIN,
    description: "Create a single or repeating survey.",
    icon: ComponentTypeDefaultIcons[ComponentType.CHECKIN],
  },
  {
    componentType: ComponentType.WORKOUT,
    description: "Add an exercise component.",
    icon: ComponentTypeDefaultIcons[ComponentType.WORKOUT],
  },
  {
    componentType: ComponentType.MESSAGE,
    description: "Send an automated messsage.",
    icon: ComponentTypeDefaultIcons[ComponentType.MESSAGE],
  },
];

const ARROW_SPACING = 1.25;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: theme.spacing(55),
  },

  paper: {
    borderRadius: theme.spacing(1.5),
    overflow: "visible",
    marginLeft: theme.spacing(-1),
    boxShadow: "none",
    filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.25))",

    "$arrowTop &::before, $arrowBottom &::before": {
      width: 20,
      height: 10,
      content: "''",
      position: "absolute",
      left: `calc(50% - ${theme.spacing(ARROW_SPACING / 2)})`,
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
    },

    "$arrowTop &::before": {
      clipPath: "polygon(0 100%, 50% 0, 100% 100%)",
      top: theme.spacing(-ARROW_SPACING),
    },

    "$arrowBottom &::before": {
      clipPath: "polygon(50% 100%, 100% 0, 0 0)",
      bottom: theme.spacing(-ARROW_SPACING),
    },
  },

  item: {
    padding: theme.spacing(2, 3),

    "&:hover": {
      backgroundColor: colorSystem.white2,
    },
  },

  primaryText: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  secondaryText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.black,
  },

  itemIcon: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    backgroundColor: colorSystem.secondaryGray,
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.text.secondary,
  },

  arrowTop: {},
  arrowBottom: {},
}));

export interface AddComponentMenuProps extends MenuProps {
  onAddComponent?: (componentType: ComponentType) => void;
  arrow?: "top" | "bottom";
}

export function AddComponentMenu(props: AddComponentMenuProps) {
  const { className, onAddComponent, arrow = "bottom", ...other } = props;
  const s = useStyles();

  const handleClick: MenuItemProps["onClick"] = React.useCallback(
    (event) => {
      const { componentType } = event.currentTarget.dataset;
      if (onAddComponent) {
        onAddComponent(componentType as ComponentType);
      }
    },
    [onAddComponent],
  );

  return (
    <Menu
      className={clsx(s.root, className, {
        [s.arrowTop]: arrow === "top",
        [s.arrowBottom]: arrow === "bottom",
      })}
      classes={{ paper: s.paper }}
      {...other}
    >
      {options.map(({ componentType, description, icon: Icon }, index) => (
        <React.Fragment key={componentType}>
          <MenuItem
            className={s.item}
            data-component-type={componentType}
            onClick={handleClick}
          >
            <ListItemIcon
              className={s.itemIcon}
              children={<Icon className={s.icon} />}
            />

            <ListItemText
              classes={{
                primary: s.primaryText,
                secondary: s.secondaryText,
              }}
              primary={getContentType(componentType)}
              secondary={description}
            />
          </MenuItem>

          {index < options.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Menu>
  );
}
