import clsx from "clsx";
import React from "react";
import {
  Button,
  ButtonProps,
  Divider,
  Checkbox,
  CheckboxProps,
  Switch,
  FormGroup,
  FormControlLabel,
  Popover,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowDropDown } from "@mui/icons-material";

import { ComponentType, componentTypes } from "../../constants";
import { getContentType, humanlyEnumerate } from "../../utils/component";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: 15,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.75, 2),
  },

  paper: {
    borderRadius: theme.spacing(1.5),
    minWidth: theme.spacing(32),
    padding: theme.spacing(2, 0),

    "& > :not($divider)": {
      margin: theme.spacing(0, 2),
    },
  },

  divider: {
    margin: theme.spacing(1.5, 0),
  },

  label: {
    color: theme.palette.common.black,
    fontWeight: 500,
    textTransform: "capitalize",
  },

  header: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },
}));

export type Filters = Record<ComponentType, boolean> & {
  archived: boolean;
};

export const defaultFilters: Filters = {
  [ComponentType.LESSON]: true,
  [ComponentType.HABIT]: true,
  [ComponentType.CHECKIN]: true,
  [ComponentType.WORKOUT]: true,
  [ComponentType.MESSAGE]: true,
  archived: false,
};

export interface ProgramDetailsFiltersProps
  extends Omit<ButtonProps, "onChange"> {
  filters?: Filters;
  onChange?: (filters: Filters) => void;
  disabledArchived?: boolean;
}

export function ProgramDetailsFilters(props: ProgramDetailsFiltersProps) {
  const {
    className,
    onChange,
    filters = defaultFilters,
    disabledArchived = false,
    ...other
  } = props;
  const s = useStyles();
  const ref = React.useRef<HTMLButtonElement>();
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange: CheckboxProps["onChange"] = React.useCallback(
    (event, checked) => {
      if (onChange) {
        const newFilter = {
          ...filters,
          [event.target.value]: checked,
        };
        const empty = componentTypes.every((type) => !newFilter[type]);

        if (!empty) {
          onChange(newFilter);
        }
      }
    },
    [filters, onChange],
  );

  const contentTypesTexts = componentTypes
    .filter((type) => filters[type])
    .map((type) => `${getContentType(type)}s`);

  const filterStatusText = [
    contentTypesTexts.length === Object.values(ComponentType).length
      ? ["All content types"]
      : humanlyEnumerate(contentTypesTexts),
    filters.archived && "including archived",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <>
      <Button
        ref={ref}
        className={clsx(s.root, className)}
        onClick={handleOpen}
        {...other}
      >
        Filter by: {filterStatusText}
        <ArrowDropDown />
      </Button>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        classes={{ paper: s.paper }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Typography className={s.header} variant="body2">
          Filter by
        </Typography>
        {componentTypes.map((type) => (
          <FormGroup key={type}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={type}
                  checked={filters[type]}
                  onChange={handleChange}
                />
              }
              label={
                <Typography variant="body1" className={s.label}>
                  {getContentType(type)}s
                </Typography>
              }
            />
          </FormGroup>
        ))}
        {!disabledArchived && (
          <>
            <Divider className={s.divider} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    value="archived"
                    checked={filters.archived}
                    onChange={handleChange}
                  />
                }
                classes={{ label: s.label }}
                checked={filters.archived}
                label={
                  <Typography
                    variant="body1"
                    className={s.label}
                    children="Show archived"
                  />
                }
              />
            </FormGroup>
          </>
        )}
      </Popover>
    </>
  );
}
