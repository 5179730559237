import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { WorkoutExerciseClientResult, WorkoutActivitySection } from "./utils";
import { WorkoutExercise } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2, 0),
    overflow: "hidden",
  },

  activity: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    margin: theme.spacing(2, 2, 0, 2),
  },

  title: {
    fontSize: 14,
    fontWeight: 500,
    margin: theme.spacing(0, 2, 2, 2),
  },

  result: {
    fontWeight: 500,
    fontSize: 16,
    backgroundColor: theme.palette.selected.light,
    padding: theme.spacing(1.75, 2),
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.quote,
  },
}));

export interface WorkoutExerciseHistoryResultProps extends BoxProps {
  client: WorkoutExerciseClientResult;
  section: WorkoutActivitySection;
  exercise: WorkoutExercise;
}

export function WorkoutExerciseHistoryResult(
  props: WorkoutExerciseHistoryResultProps,
) {
  const { className, client, section, exercise, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.activity}>
        {section.activity.component.title} | {section.activity.completedAt}
      </Typography>
      <Typography className={s.title}>{exercise.subtitle}</Typography>
      {/* TODO_EXERCISES result isnt jsx*/}
      {/* <Typography className={s.result}>{exercise.result}</Typography> */}
    </Box>
  );
}
