import clsx from "clsx";
import React, { startTransition, useCallback, useEffect } from "react";
import {
  Container,
  ContainerProps,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useRefetchableFragment } from "react-relay";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useMediaMobile } from "../../hooks/useMediaMobile";

import { useTerraSdk } from "../../utils/terra-sdk";
import { ClientMeasurementsCard } from "../card/ClientMeasurementsCard";
import { ClientNutritionCard } from "../client-nutrition-targets/ClientNutritionCard";
import { ClientGoalsCard } from "../card/ClientGoalsCard";
import { ClientProgressPhotosCard } from "../card/ClientProgressPhotosCard";
import { ClientSummary } from "../coach-clients/ClientSummary";
import { ClientFormsCard } from "../client-forms/ClientFormsCard";
import { ClientStepsCard } from "../client-steps/ClientStepsCard";
import { ReactComponent as SettingIcon } from "../../icons/Setting.svg";

import { ClientProfileScreen_client$key } from "./__generated__/ClientProfileScreen_client.graphql";
import ClientWorkoutsExercises from "../client-workouts/ClientWorkoutsExercises";
import { ClientBodyWeightCard } from "../client-body-weight/ClientBodyWeightCard";
import { useNavigate } from "react-router-dom";
import { CLIENT_SETTING_ROUTE } from "../../routes/routes";
import TrackInfoTool from "../tools/TrackInfoTool";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    marginTop: theme.spacing(4),
    position: "relative",
  },

  bodyweightCard: {
    cursor: "pointer",

    [theme.breakpoints.up("lg")]: {
      height: 224,
    },
  },
}));

const clientFragment = graphql`
  fragment ClientProfileScreen_client on User
  @refetchable(queryName: "ClientProfileScreenRefetchQuery") {
    metrics(metricType: CHECKIN_ANSWER_MEASUREMENT) {
      value {
        ... on CheckinAnswerMeasurementValue {
          type
        }
      }
    }

    nutritionTarget {
      calories
      ...ClientNutritionCard_nutritionTarget
    }

    autoNutrition {
      id
      ...ClientNutritionCard_autoNutrition
    }
    autoNutritionLastSync(format: "h:mm A, MMM DD, YYYY")

    autoNutritionMacroTarget: nutritionMacroTarget {
      ...ClientNutritionCard_autoNutritionMacroTarget
    }

    terraSdkOptions {
      devId
      referenceId
      token
    }

    nutritionMacroTarget {
      ...ClientNutritionCard_nutritionMacroTarget
    }

    nutritionPortionTarget {
      ...ClientNutritionCard_nutritionPortionTarget
    }

    goals(first: 9999) {
      edges {
        cursor
      }
      ...ClientGoalsCard_goals
    }

    ...ClientSummary_client
    ...ClientStepsCard_client
    ...ClientBodyWeightCard_client
    ...ClientMeasurementsCard_client
    ...ClientProgressPhotosCard_client
    ...ClientFormsCard_client @arguments(first: 999)

    clientForms(first: 999) {
      totalCount
    }
  }
`;

export interface ClientProfileScreenProps
  extends Omit<ContainerProps, "children"> {
  clientRef?: ClientProfileScreen_client$key;
}

export const ClientProfileActions = (clickHandler, isMobile) => {
  const theme = useTheme();
  return (
    <IconButton
      style={{
        borderRadius: theme.spacing(0.5),
      }}
      onClick={clickHandler}
      size="large"
    >
      <SettingIcon />
      {!isMobile && (
        <span
          style={{
            fontWeight: 500,
            fontSize: 18,
            lineHeight: "22px",
            color: "black",
            paddingLeft: theme.spacing(1.5),
          }}
        >
          Settings
        </span>
      )}
    </IconButton>
  );
};

export function ClientProfileScreen(props: ClientProfileScreenProps) {
  const navigate = useNavigate();
  const { className, clientRef, ...other } = props;
  const [client, clientRefetch] = useRefetchableFragment(
    clientFragment,
    clientRef,
  );
  const s = useStyles();
  const isMobile = useMediaMobile("md", false);
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const refresh = React.useCallback(() => {
    startTransition(() => {
      clientRefetch({}, { ...RELAY_LAZY_LOAD_COMMON_CONFIG });
    });
  }, [clientRefetch]);

  const openSettings = React.useCallback(() => {
    navigate(CLIENT_SETTING_ROUTE);
  }, []);

  const { isImpersonating } = useCurrentUser();
  const {
    connected: terraConnected,
    init: initTerraSdk,
    sync: syncTerraSdk,
  } = useTerraSdk(client.terraSdkOptions);

  const syncTerra = useCallback(async () => {
    if (isImpersonating) {
      return;
    }
    if (!terraConnected) {
      return;
    }
    await initTerraSdk();
    await syncTerraSdk();
  }, [isImpersonating, terraConnected, initTerraSdk, syncTerraSdk]);

  useEffect(() => {
    syncTerra().catch((error) => {
      console.warn("Terra capacitor sync error", error);
    });
  }, []);

  return (
    <>
      <Container className={clsx(s.root, className)} {...other}>
        <Grid container sx={{ mb: 3 }} spacing={3}>
          <Grid item xs={12} lg={12}>
            <ClientSummary clientRef={client} />
          </Grid>

          <Grid item xs={12}>
            <ClientStepsCard clientRef={client} />
          </Grid>

          {client.clientForms.totalCount > 0 && (
            <Grid item xs={12}>
              <ClientFormsCard client={client} />
            </Grid>
          )}

          <Grid item xs={12}>
            <ClientNutritionCard
              nutritionTargetRef={client.nutritionTarget}
              nutritionPortionTargetRef={client.nutritionPortionTarget}
              nutritionMacroTargetRef={client.nutritionMacroTarget}
              autoNutritionRef={client.autoNutrition}
              autoNutritionLastSync={client.autoNutritionLastSync}
              autoNutritionMacroTargetRef={client.autoNutritionMacroTarget}
              refresh={refresh}
            />
          </Grid>

          <Grid item xs={12}>
            <ClientGoalsCard goalsRef={client.goals} refresh={refresh} />
          </Grid>

          <Grid item xs={12} md={6}>
            <ClientBodyWeightCard clientRef={client} />
          </Grid>

          <Grid item xs={12} md={6}>
            <ClientMeasurementsCard clientRef={client} />
          </Grid>

          <Grid item xs={12}>
            <ClientProgressPhotosCard clientRef={client} />
          </Grid>

          <Grid item xs={12}>
            <React.Suspense fallback={<div>Loading results…</div>}>
              <ClientWorkoutsExercises typeBox="workout" clientRef={client} />
            </React.Suspense>
          </Grid>

          {!smUp && (
            <Grid item xs={12}>
              <ClientWorkoutsExercises typeBox="exercises" clientRef={client} />
            </Grid>
          )}
        </Grid>
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Client - Profile",
        }}
      />
    </>
  );
}
