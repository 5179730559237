import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button } from "@mui/material";

import Em from "../typography/Em";

import { LoginContainer, LoginButtons } from "./LoginContainer";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
}));

type State = {
  email: string;
  error?: string;
  sent: boolean;
};

export type ForgotPasswordFormProps = {
  className?: string;
  state: State;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  loading?: boolean;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onResend?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const s = useStyles();
  const brandName = useCurrentBrandName();
  const { className, state, onChange, loading, onSubmit, onResend } = props;

  if (state.sent) {
    return (
      <LoginContainer
        className={className}
        title="Check your inbox"
        subheader={`If you have a ${brandName} account, we'll email you a a link to reset your password.`}
      >
        <LoginButtons>
          <Button
            className={s.button}
            variant="contained"
            type="submit"
            onClick={onResend}
          >
            Resend recovery link
          </Button>
        </LoginButtons>
      </LoginContainer>
    );
  }

  return (
    <LoginContainer
      title={
        <React.Fragment>
          Forgot your <Em>password</Em>?
        </React.Fragment>
      }
      subheader="Type your email here and we'll send you a reset link"
    >
      <form onSubmit={onSubmit}>
        <TextField
          name="email"
          type="email"
          label="Email Address"
          placeholder="email@example.com"
          value={state.email}
          onChange={onChange}
          disabled={loading}
          error={Boolean(state.error)}
          helperText={state.error}
          fullWidth
          id="username"
        />
        <LoginButtons>
          <Button
            className={s.button}
            variant="contained"
            type="submit"
            disabled={loading}
          >
            Send reset link
          </Button>
        </LoginButtons>
      </form>
    </LoginContainer>
  );
}
