import clsx from "clsx";
import React, { useRef } from "react";
import {
  Box,
  BoxProps,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Popover,
  ClickAwayListener,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import {
  getUnitLabel,
  MeasurementUnit,
  numberInputProps,
} from "../../constants";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { DatePicker } from "../fields/DatePicker";
import { toISODateString } from "../../utils/date";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.75, 0, 1.75, 0),
    height: theme.spacing(8.5),
  },

  text: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 600,

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },

  date: {
    fontWeight: 500,
    whiteSpace: "nowrap",
    fontSize: 14,
    width: "100%",
    maxWidth: 100,

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },

  editValue: {
    marginRight: "auto",
    width: theme.spacing(6.5),

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(8),
    },

    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  measurement: {
    flex: 1,
    textAlign: "right",
    marginLeft: 10,
  },

  actions: {
    whiteSpace: "nowrap",
    flex: 1,
    minWidth: 140,
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.up("sm")]: {
      minWidth: 200,
    },

    "& button": {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
    },

    "& button svg": {
      width: theme.spacing(2.75),
      height: theme.spacing(2.75),
    },

    "& button svg path": {
      strokeWidth: 1,
    },

    "& button$save, & button$cancel": {
      fontSize: 14,
      fontWeight: 700,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.75, 1),

      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0.75, 2),
      },
    },

    "& button$save": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.progress.green,

      "&:disabled": {
        backgroundColor: theme.palette.text.secondary,
      },
    },

    "& button$cancel": {
      color: theme.palette.common.black,
    },
  },

  dateTextField: {
    width: "100%",
    maxWidth: 100,
  },

  inputRoot: {
    width: "inherit",
  },

  cancel: {
    fontSize: 14,
    fontWeight: 700,
  },

  save: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0.5),
    },
  },
}));

export const parseAsFloat = (value: string) => {
  const parsed = parseFloat(value.replace(/^0+/, ""));

  return Number.isNaN(parsed) ? 0 : parsed;
};

export type MetricType = {
  id?: string;
  name?: string;
  date?: string;
  measurement?: number;
  unit?: string;
  index?: number;
  history?: boolean;
  setConfirmDelete?: (value: boolean) => void;
};

export interface ClientMeasurementRowProps extends BoxProps {
  metric: MetricType;
  disabled?: boolean;
  editing?: boolean;
  onEdit: (metric: MetricType) => void;
  onCancel: () => void;
  onSave: (metric: MetricType) => void;
  onRemove: (metric: MetricType) => void;
}

export const ClientMeasurementRow = (props: ClientMeasurementRowProps) => {
  const {
    className,
    metric,
    disabled = false,
    editing = false,
    onEdit,
    onSave,
    onCancel,
    onRemove,
    ...other
  } = props;
  const s = useStyles();
  const [editingValue, setEditingValue] = React.useState(false);
  const [editingDate, setEditingDate] = React.useState(false);
  const { unit } = metric;
  const originalMeasurement = metric.measurement;
  const originalDate = metric.date;
  const [measurement, setMeasurement] =
    React.useState<any>(originalMeasurement);
  const [date, setDate] = React.useState(originalDate);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);

  const toggleDatePickerState = usePopupState({
    variant: "popover",
    popupId: "date-picker",
  });
  const dateTextFieldRef = useRef(null);

  const handleChangeMeasurement = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.target.value === "") {
        setMeasurement(event.target.value);
      } else {
        const value = parseAsFloat(event.target.value);
        setMeasurement(value);
      }
      setDirty(true);
    },
    [],
  );

  const handleStartEditing = React.useCallback(() => {
    onEdit(metric);
  }, [metric, onEdit]);

  const handleSave = React.useCallback(() => {
    onSave({
      ...metric,
      date,
      measurement: measurement || 0,
    });
  }, [date, measurement, metric, onSave]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSave();
      }
    },
    [handleSave],
  );

  const handleDelete = React.useCallback(() => {
    onRemove({ ...metric, setConfirmDelete });
  }, [metric, onRemove]);

  const handleRemoveClick = React.useCallback(() => setConfirmDelete(true), []);

  const handleCancelDelete = React.useCallback(
    () => setConfirmDelete(false),
    [],
  );

  const handleCancelEditing = React.useCallback(() => {
    setMeasurement(originalMeasurement);
    onCancel();
  }, [onCancel, originalMeasurement]);

  const handleValueBlur = React.useCallback(() => {
    setEditingValue(false);
  }, []);

  const handleValueClick = React.useCallback(() => {
    if (editing) {
      setEditingValue(true);
      setEditingDate(false);
    }
  }, [editing]);

  const handleDatePickerChange = React.useCallback(
    (selectedDate: any) => {
      setDate(dayjs(selectedDate).startOf("day").format("YYYY-MM-DD"));
      setDirty(true);
      toggleDatePickerState.close();
    },
    [toggleDatePickerState],
  );

  const handleDateClick = React.useCallback(() => {
    if (editing) {
      setEditingDate(true);
      setTimeout(() => {
        toggleDatePickerState.open(dateTextFieldRef.current);
      }, 0);
    }
  }, [editing]);

  React.useEffect(() => {
    if (editing) {
      setEditingValue(true);
    } else {
      setMeasurement(originalMeasurement);
      setDate(originalDate);
      setConfirmDelete(false);
    }
  }, [editing, originalDate, originalMeasurement]);

  const unitLabel = getUnitLabel(unit.toLowerCase() as MeasurementUnit);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {editing && editingDate ? (
        <>
          <TextField
            variant="standard"
            className={s.dateTextField}
            value={dayjs(new Date(date)).format("DD/MM/YYYY")}
            inputRef={dateTextFieldRef}
            {...bindTrigger(toggleDatePickerState)}
            autoFocus
          />

          <Popover {...bindPopper(toggleDatePickerState)}>
            <ClickAwayListener
              onClickAway={toggleDatePickerState.close}
              mouseEvent="onMouseUp"
            >
              <div>
                <DatePicker
                  value={dayjs(new Date(date))}
                  onChange={handleDatePickerChange}
                  maxDate={dayjs()}
                />
              </div>
            </ClickAwayListener>
          </Popover>
        </>
      ) : (
        <Typography className={clsx(s.text, s.date)} onClick={handleDateClick}>
          {dayjs(new Date(date)).format("MMM DD, YYYY")}
        </Typography>
      )}

      <Box className={s.measurement}>
        {editing && editingValue ? (
          <TextField
            variant="standard"
            className={s.editValue}
            value={measurement}
            onChange={handleChangeMeasurement}
            onKeyDown={handleKeyDown}
            onBlur={handleValueBlur}
            autoFocus
            type="number"
            InputProps={{
              classes: {
                input: s.text,
              },
              endAdornment: (
                <InputAdornment
                  classes={{ positionEnd: s.text }}
                  disableTypography
                  position="end"
                  children={unitLabel}
                />
              ),
              inputProps: numberInputProps,
            }}
          />
        ) : (
          <>
            <Typography className={s.text} onClick={handleValueClick}>
              {measurement} {unitLabel}
            </Typography>
          </>
        )}
      </Box>

      <Box className={s.actions}>
        {editing ? (
          <>
            <Button
              className={s.cancel}
              variant="text"
              onClick={handleCancelEditing}
            >
              Cancel
            </Button>
            <Button
              className={s.save}
              variant="contained"
              onClick={handleSave}
              disabled={!dirty}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <IconButton
              children={<EditIcon />}
              onClick={handleStartEditing}
              size="large"
            />
            <IconButton
              children={<DeleteIcon />}
              onClick={handleRemoveClick}
              disabled={disabled}
              size="large"
            />
          </>
        )}
      </Box>

      <ConfirmActionDialog
        title="Are you sure you want to remove this result?"
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        open={confirmDelete}
        disabled={disabled}
      />
    </Box>
  );
};
