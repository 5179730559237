import clsx from "clsx";
import React, { useTransition } from "react";
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  graphql,
  useMutation,
  useRefetchableFragment,
} from "react-relay/hooks";
import { Close as CloseIcon } from "@mui/icons-material";

import { getComponentTemplateTitle } from "../../utils/component_template";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { SOMETHING_WENT_WRONG, ComponentType } from "../../constants";

import {
  ComponentTemplateDialog_rootRef$data,
  ComponentTemplateDialog_rootRef$key,
} from "./__generated__/ComponentTemplateDialog_rootRef.graphql";
import { ComponentTemplateDialogUpdateMutation } from "./__generated__/ComponentTemplateDialogUpdateMutation.graphql";
import { ComponentTemplatePreview } from "./ComponentTemplatePreview";
import { ComponentTemplateLibrary } from "./ComponentTemplateLibrary";
import { ComponentTemplatePreviewEmpty } from "./ComponentTemplatePreviewEmpty";
import { ComponentTemplateUpdate } from "./ComponentTemplatePreview";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: "initial",
      minWidth: "80vw",
      height: "80vh",
    },
  },

  content: {
    padding: "0 !important",

    [theme.breakpoints.up("md")]: {
      display: "grid",
      height: "100%",
      gridTemplateColumns: "minmax(250px, 324px) auto",
    },
  },

  library: {
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.1)",
  },

  preview: {
    marginTop: theme.spacing(11.5),
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up("md")]: {
      overflowY: "auto",
    },
  },

  actions: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",

    [theme.breakpoints.up("md")]: {
      padding: 0,
      top: theme.spacing(3),
      right: theme.spacing(3),
      boxShadow: "initial",
      left: "initial",
    },
  },

  actionButton: {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 700,
    padding: theme.spacing(1, 4.5),
  },

  closeButton: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const formatTemplate = ({
  title,
  habitPrompt,
  content,
  image,
}: ComponentTemplateDialog_rootRef$data["template"]): ComponentTemplate => ({
  title: getComponentTemplateTitle(title),
  habitPrompt,
  content,
  image,
});

const updateTemplateMutation = graphql`
  mutation ComponentTemplateDialogUpdateMutation(
    $input: UpsertComponentTemplateInput!
  ) {
    upsertComponentTemplate(input: $input) {
      template {
        ...ComponentTemplatePreview_template
      }
    }
  }
`;

const rootFragment = graphql`
  fragment ComponentTemplateDialog_rootRef on Root
  @refetchable(queryName: "ComponentTemplateDialogRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 99 }
    after: { type: "String" }
    withTemplate: { type: "Boolean", defaultValue: false }
    templateId: { type: "ID", defaultValue: "" }
    componentType: { type: "ComponentType" }
    componentSlug: { type: "String" }
  ) {
    template: componentTemplate(id: $templateId) @include(if: $withTemplate) {
      id
      title
      habitPrompt
      content
      private
      image
      ...ComponentTemplatePreview_template
    }

    componentTemplates(
      first: $first
      after: $after
      componentType: $componentType
      componentSlug: $componentSlug
    )
      @connection(
        key: "ComponentTemplateDialog_componentTemplates"
        filters: []
      ) {
      edges {
        node {
          ...ComponentTemplateLibrary_template
          id
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export interface ComponentTemplate {
  title: string;
  habitPrompt?: string;
  content: string;
  image?: string;
}

export interface ComponentTemplateDialogProps
  extends Omit<DialogProps, "onSelect"> {
  rootRef: ComponentTemplateDialog_rootRef$key;
  onSelectTemplate?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ComponentTemplate,
  ) => void;
  componentType?: ComponentType;
}

export function ComponentTemplateDialog(props: ComponentTemplateDialogProps) {
  const {
    className,
    rootRef,
    onClose,
    onSelectTemplate,
    componentType,
    ...other
  } = props;
  const [root, rootRefetch] = useRefetchableFragment(rootFragment, rootRef);
  const { componentTemplates, template } = root;

  const s = useStyles();
  const snackAlert = useSnackAlert();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const templates = componentTemplates.edges.map(({ node }) => node);
  const [templateId, setTemplateId] = React.useState<string>();
  const [readOnly, setReadOnly] = React.useState(true);
  const [templateUpdate, setTemplateUpdate] =
    React.useState<ComponentTemplateUpdate>(null);
  const [updateTemplate, updatingTemplate] =
    useMutation<ComponentTemplateDialogUpdateMutation>(updateTemplateMutation);

  const [_, startTransition] = useTransition();

  const handleTemplateSelect = React.useCallback(
    (event, templateId: string) => {
      setTemplateId(templateId);
      setReadOnly(true);
      setTemplateUpdate(null);
    },
    [],
  );

  const handleClose = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "escapeKeyDown");
      }
    },
    [onClose],
  );

  const handleSubmit = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "escapeKeyDown");
      }

      if (onSelectTemplate) {
        onSelectTemplate(event, formatTemplate(template));
      }
    },
    [onClose, onSelectTemplate, template],
  );

  React.useEffect(() => {
    if (templateId) {
      startTransition(() => {
        rootRefetch({
          templateId,
          withTemplate: true,
        });
      });
    }
  }, [rootRefetch, templateId]);

  React.useEffect(() => {
    startTransition(() => {
      rootRefetch({}, { fetchPolicy: "store-and-network" });
    });
  }, [rootRef, rootRefetch]);

  const handleEditClick = React.useCallback(() => {
    if (!readOnly && templateUpdate) {
      const input = {
        ...templateUpdate,
        id: templateId,
      };

      updateTemplate({
        variables: { input },
        onCompleted(_, errors) {
          if (errors) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Template updated.",
            });
            setReadOnly(true);
          }
        },
      });
    } else {
      setReadOnly(!readOnly);
    }
  }, [readOnly, snackAlert, templateId, templateUpdate, updateTemplate]);

  const disabled = updatingTemplate;

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      fullScreen={!mdUp}
      onClose={onClose}
      {...other}
    >
      <DialogContent className={s.content}>
        <ComponentTemplateLibrary
          className={s.library}
          templatesRef={templates}
          onSelect={handleTemplateSelect}
        />
        {template ? (
          <ComponentTemplatePreview
            key={template.id}
            className={s.preview}
            templateRef={template}
            onUpdate={setTemplateUpdate}
            readOnly={readOnly}
          />
        ) : (
          <ComponentTemplatePreviewEmpty />
        )}
      </DialogContent>

      <DialogActions className={s.actions}>
        {template?.private && (
          <Button
            className={clsx(s.actionButton)}
            onClick={handleEditClick}
            fullWidth
            variant="outlined"
            color="secondary"
            disabled={disabled}
          >
            {readOnly ? "Edit" : "Done"}
          </Button>
        )}
        {template && (
          <Button
            className={s.actionButton}
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            Use as template
          </Button>
        )}

        <IconButton
          className={clsx(s.closeButton)}
          onClick={handleClose}
          children={<CloseIcon />}
          size="large"
        />
      </DialogActions>
    </Dialog>
  );
}
