import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Button,
  Divider,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientStepsDrawerHeader } from "./ClientStepsDrawerHeader";
import ClientStepsHistoryRow from "./ClientStepsHistoryRow";
import { ReactComponent as PlusIcon } from "../../icons/AddCircleOutline.svg";
import { numberInputProps } from "../../constants";
import { MetricType } from "../client-measurement/ClientMeasurementRow";
import { clientProfileTextfieldStyles } from "../client-body-weight/ClientBodyWeightDrawerEditView";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(4),
  },

  inputWrapper: {
    marginBottom: theme.spacing(2.5),
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  save: {
    marginLeft: theme.spacing(1),
    color: theme.palette.progress.green,
    fontSize: 16,
    fontWeight: 500,
  },

  history: {
    fontSize: 20,
    margin: theme.spacing(3, 4, 2, 0),
  },
  addResult: {
    marginTop: theme.spacing(1),
    fontSize: 16,
    fontWeight: 500,
    float: "right",

    "& svg": {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export interface ClientGoalsDrawerEditViewProps extends BoxProps {
  today?: any;
  disabled: boolean;
  stepsValue: string;
  onClose: () => void;
  onSave: ({ id, unit, date, name, measurement, index }: MetricType) => void;
  onStepsChange: TextFieldProps["onChange"];
  stepsValueHistory: any[];
  editingIndex: number;
  handleCancel: () => void;
  handleEditing: (value: number) => void;
  editingDate: boolean;
  setEditingDate: (value: boolean) => void;
  editingValue: boolean;
  setEditingValue: (value: boolean) => void;
  onRemove: ({ id, index }: MetricType) => void;
  newHistory: any;
  handleCreateClick: () => void;
  setNewHistory: (value: null) => void;
}

export function ClientStepsDrawerEditView(
  props: ClientGoalsDrawerEditViewProps,
) {
  const {
    className,
    stepsValue,
    onClose,
    onStepsChange,
    onSave,
    disabled,
    today,
    stepsValueHistory,
    editingIndex,
    handleCancel,
    handleEditing,
    editingDate,
    setEditingDate,
    editingValue,
    setEditingValue,
    onRemove,
    newHistory,
    handleCreateClick,
    setNewHistory,
    ...other
  } = props;
  const s = useStyles();
  const theme = useTheme();

  return (
    <Box className={clsx(className)} {...other}>
      <ClientStepsDrawerHeader
        className={s.header}
        onClose={onClose}
        title={"Steps"}
      />
      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Correct steps
        </Typography>
        <Box className={s.box}>
          <TextField
            variant="outlined"
            fullWidth
            value={stepsValue}
            onChange={onStepsChange}
            className={s.input}
            error={stepsValue === null}
            autoFocus
            InputProps={{
              inputProps: numberInputProps,
            }}
            sx={clientProfileTextfieldStyles(theme.palette.primary.main)}
          />
          <Button
            variant="text"
            color="primary"
            className={s.save}
            onClick={() =>
              onSave({
                id: today?.id,
                date: dayjs().format("YYYY-MM-DD"),
                measurement: parseFloat(stepsValue),
                name: "step_count",
                index: 0,
              })
            }
            disabled={disabled}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box>
        <Box className={s.historyContainer}>
          <Typography variant="h4" className={s.history}>
            History
          </Typography>
          {!newHistory && (
            <Button
              variant="text"
              color="primary"
              className={s.addResult}
              onClick={handleCreateClick}
              disabled={disabled}
            >
              Add result
              <PlusIcon />
            </Button>
          )}
        </Box>
        <Divider />
        <Box>
          {stepsValueHistory.map(
            (item, index) =>
              item && (
                <React.Fragment key={index}>
                  <ClientStepsHistoryRow
                    editing={index === editingIndex}
                    handleEditing={() => handleEditing(index)}
                    onCancel={handleCancel}
                    editingDate={editingDate}
                    value={item}
                    setEditingDate={setEditingDate}
                    editingValue={editingValue}
                    setEditingValue={setEditingValue}
                    index={index}
                    onSave={onSave}
                    disabled={disabled}
                    onRemove={onRemove}
                    setNewHistory={setNewHistory}
                    newHistory={newHistory}
                    today={today}
                  />
                  {index < stepsValueHistory.length - 1 && <Divider />}
                </React.Fragment>
              ),
          )}
        </Box>
      </Box>
    </Box>
  );
}
