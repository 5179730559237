import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import personalTrainer from "../../icons/personal-trainer.svg";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Link } from "../../components/link/Link";
import { getFirstName } from "../../utils/user";
import Trener from "../../icons/Trener";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const MAX_WIDTH = 460;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: theme.spacing(5),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },

  illustration: {
    width: "100%",
    maxWidth: MAX_WIDTH,
    height: "auto",
  },

  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginTop: theme.spacing(4),
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.common.black,
    marginTop: theme.spacing(1),
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.common.white,
    marginTop: theme.spacing(2),
    textDecoration: "none !important",
    width: "100%",
    maxWidth: MAX_WIDTH,
  },
}));

export interface CreateFirstProgramProps extends BoxProps {}

export function CreateFirstProgram(props: CreateFirstProgramProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const brandName = useCurrentBrandName();
  const user = useCurrentUser();
  const firstName = getFirstName(user);
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const theme = useTheme();
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Trener fill={theme.palette.primary.main} className={s.illustration} />
      <Typography className={s.title} variant="h3">
        {mdUp
          ? `Welcome to ${brandName}, ${firstName}!`
          : "Looks like you’re on a mobile device."}
      </Typography>
      <Typography className={s.subtitle} variant="body1">
        {mdUp
          ? "Create your first program to start coaching clients."
          : "You can’t create programs on mobile. For full functionality, switch to desktop."}
      </Typography>
      {mdUp && (
        <Button
          className={s.button}
          variant="contained"
          component={Link}
          fullWidth
          href="/coach/programs/create"
          children="+ Create your first program"
        />
      )}
    </Box>
  );
}
