import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Button, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { usePopupState } from "material-ui-popup-state/hooks";

import { ReactComponent as ThreadsEmptyArt } from "../../icons/UndrawMessageSent.svg";
import { UserRole } from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Link } from "../link/Link";

import { SelectRecipient } from "./SelectRecipient";
import Message from "../../icons/Message";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: theme.spacing(3),
  },

  header: {
    marginTop: theme.spacing(5),
  },

  subheader: {
    fontSize: "large",
    fontWeight: 500,
  },

  button: {
    fontSize: "larger",
    fontWeight: 700,
    borderRadius: 4,
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 15),
    },
  },
}));

export interface ThreadsEmptyProps extends BoxProps {}

export function ThreadsEmpty(props: ThreadsEmptyProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const brandName = useCurrentBrandName();
  const { role, coach } = useCurrentUser();
  const header =
    role === UserRole.COACH ? "Message your clients" : "Message your coach";
  const subheader =
    role === UserRole.COACH
      ? `Start a private conversation between you and your clients on ${brandName}.`
      : "Start a private conversation between you and your coach.";
  const actionLabel =
    role === UserRole.COACH ? "Message a client" : "Message your coach";
  const href = `${role === UserRole.COACH ? "/coach" : ""}/messages/${
    coach ? coach.username : "new"
  }`;

  const dialogState = usePopupState({
    variant: "popover",
    popupId: "select-recipient-dialog",
  });

  const handleClick = React.useCallback(
    (event) => {
      if (role === UserRole.COACH) {
        event.preventDefault();
        dialogState.open();
      }
    },
    [dialogState, role],
  );
  const theme = useTheme();
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Message fill={theme.palette.primary.main}></Message>
      <Typography variant="h5" className={s.header} gutterBottom>
        {header}
      </Typography>
      <Typography variant="subtitle1" className={s.subheader} gutterBottom>
        {subheader}
      </Typography>

      <Button
        variant="contained"
        className={s.button}
        component={Link}
        href={href}
        onClick={handleClick}
      >
        {actionLabel}
      </Button>
      {dialogState.isOpen && (
        <SelectRecipient open onClose={dialogState.close} />
      )}
    </Box>
  );
}
