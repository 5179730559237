import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";

import { maxEnrollments } from "../../../../constants";
import { ProgramEnrollmentsListContext } from "../../../../hooks/useProgramEnrollmentsList";
import { UnsplashContext } from "../../../../hooks/useUnsplash";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { HistoryBlock } from "../../../../components/history-block/HistoryBlock";
import { CurriculumEditScreen } from "../../../../components/screen/CurriculumEditScreen";
import { CoachProgramCurriculumRouteQuery } from "./__generated__/CoachProgramCurriculumRouteQuery.graphql";
import { ProgramContext } from "../../../../contexts/ProgramContext";
import LayoutLoadingContext from "../../../../contexts/LayoutLoadingContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { CoachProgramCurriculumRouteWeeksQuery } from "./__generated__/CoachProgramCurriculumRouteWeeksQuery.graphql";
import {
  ProgramWeek,
  ProgramWeeksContext,
} from "../../../../hooks/useProgramWeeks";

export function CoachProgramCurriculumRoute() {
  const { slug } = useParams();

  const loadingContext = useContext(LayoutLoadingContext);
  loadingContext.setLoading(true);

  const props = useLazyLoadQuery<CoachProgramCurriculumRouteQuery>(
    graphql`
      query CoachProgramCurriculumRouteQuery(
        $programSlug: String!
        $maxEnrollments: Int!
      ) {
        ...UnsplashImages_rootRef

        program(slug: $programSlug) {
          id
          name
          slug
          ...CurriculumEditScreen_program @arguments(first: 3, after: null)
          ...RefreshSlug
          ...EnrollDialog_program
          ...EnrollDateDialog_program
        }

        clients {
          ...EnrollDialog_clients
        }

        groups {
          ...EnrollDialog_groups
        }

        enrollmentsAvatars: enrollments(
          programSlug: $programSlug
          first: $maxEnrollments
        ) @connection(key: "Program_enrollmentsAvatars", filters: []) {
          totalCount
          edges {
            node {
              id
            }
          }
          ...EnrolledAvatars_connection
        }

        enrollments(programSlug: $programSlug, first: 9999)
          @connection(key: "Program_enrollments", filters: []) {
          edges {
            cursor
          }
          ...ProgramEnrollmentStartDateViewDialog_enrollments
          ...CurriculumEditScreen_enrollments
        }
      }
    `,
    {
      programSlug: slug,
      maxEnrollments,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  // A separate query is required to get ALL week ids because of
  // graphQl's limitation of having unique prop names to query.
  // The main program query retrieves paginated weeks with details.
  // This query returns only identifier for all weeks of the program.
  const programAllWeekIds =
    useLazyLoadQuery<CoachProgramCurriculumRouteWeeksQuery>(
      graphql`
        query CoachProgramCurriculumRouteWeeksQuery($programSlug: String!) {
          program(slug: $programSlug) {
            id
            weeks(first: 9999, after: "") {
              edges {
                node {
                  id
                  week
                }
              }
            }
          }
        }
      `,
      { programSlug: slug },
      RELAY_LAZY_LOAD_COMMON_CONFIG,
    );

  const {
    program: { weeks },
  } = programAllWeekIds;
  const programWeeks: Array<ProgramWeek> = weeks.edges.map(({ node }) => ({
    id: node.id,
    week: node.week,
  }));

  const { program, enrollments } = props;

  const context = useContext(ProgramContext);
  context.setProgramQuery(props);
  program && loadingContext.setLoading(false);

  return (
    <>
      <RefreshSlug nodesRef={[program]} />
      <HistoryBlock>
        <ProgramEnrollmentsListContext.Provider value={enrollments}>
          <ProgramWeeksContext.Provider value={programWeeks}>
            <UnsplashContext.Provider value={props}>
              <CurriculumEditScreen programRef={program} />
            </UnsplashContext.Provider>
          </ProgramWeeksContext.Provider>
        </ProgramEnrollmentsListContext.Provider>
      </HistoryBlock>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Program Curriculum",
          properties: {
            programId: program?.id,
            programName: program?.name,
            programSlug: program?.slug,
          },
        }}
      />
    </>
  );
}
