import React, { FC, useCallback, useState } from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";
import {
  Box,
  Button,
  Popover,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { numberInputProps } from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { colorSystem } from "../../theme";
import { ReactComponent as CalendarEmptyIcon } from "../../icons/CalendarEmpty.svg";
import { DatePicker } from "../fields/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { clientProfileTextfieldStyles } from "../client-body-weight/ClientBodyWeightDrawerEditView";

const useStyles = makeStyles((theme) => ({
  input: {
    "& input": {
      fontWeight: 500,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  button: {
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
  },

  calendar: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    height: 56,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    fontSize: 16,
    fontFamily: "Montserrat",
    color: colorSystem.black,
    fontWeight: 500,
    cursor: "pointer",
  },
}));

const createAutoNutritionMutation = graphql`
  mutation AddAutoNutritionEntryFormCreateMutation(
    $input: UpsertAutoNutritionInput!
  ) {
    upsertAutoNutrition(input: $input) {
      autoNutrition {
        id
      }
    }
  }
`;

export interface AddAutoNutritionEntryFormProps {
  entries: readonly any[];
  refresh: any;
  children?: any;
}

const AddAutoNutritionEntryForm: FC<AddAutoNutritionEntryFormProps> = (
  props,
) => {
  const { entries, refresh, children } = props;
  const theme = useTheme();

  const s = useStyles();
  const [date, setDate] = useState(() => dayjs(new Date()).startOf("day"));
  const [entry, setEntry] = useState({
    protein: "0",
    carbs: "0",
    fat: "0",
    calories: "0",
  });

  const datePickerState = usePopupState({
    variant: "popover",
    popupId: "auto-nutrition-details-date",
  });

  const [createAutoNutrition] = useMutation(createAutoNutritionMutation);

  const snackAlert = useSnackAlert();

  const handleClickSave = React.useCallback(() => {
    createAutoNutrition({
      variables: {
        input: {
          date: date.format("YYYY-MM-DD"),
          ...Object.entries(entry).reduce((result, [key, value]) => {
            return {
              ...result,
              [key]: +value,
            };
          }, {}),
        },
      },
      onCompleted: (data: any, errors) => {
        if (errors) {
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          refresh?.();
          snackAlert({
            severity: "success",
            message: "Success",
          });
        }
      },
    });
  }, [createAutoNutrition, snackAlert, refresh, date, entry]);

  const isValid = useCallback(() => {
    return !Object.values(entry).some((value) => +value < 0);
  }, [entry]);

  const renderField = useCallback(
    (field: string, label: string) => {
      return (
        <Box mb={2.5}>
          <Typography variant="h4" className={s.label}>
            {label}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={entry[field]}
            onChange={(event) => {
              setEntry({
                ...entry,
                [field]: event.target.value,
              });
            }}
            className={s.input}
            error={+entry[field] < 0}
            type="number"
            InputProps={{
              inputProps: numberInputProps,
            }}
            sx={clientProfileTextfieldStyles(theme.palette.primary.main)}
          />
        </Box>
      );
    },
    [entry, setEntry, s],
  );

  return (
    <>
      <Box mb={2.5}>
        <Typography variant="h4" className={s.label}>
          Date
        </Typography>
        <Box {...bindTrigger(datePickerState)} className={s.calendar}>
          <span>{dayjs(date, "YYYY-MM-DD").format("MMM DD YYYY")}</span>
          <CalendarEmptyIcon />
        </Box>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          {...bindPopover(datePickerState)}
        >
          <DatePicker
            value={date.toDate()}
            onChange={(newDate) => {
              setDate((newDate as Dayjs).startOf("day"));
              datePickerState.close();
            }}
            highlightDays={entries.map(({ date }) => dayjs(date).toDate())}
          />
        </Popover>
      </Box>
      {renderField("protein", "Protein")}
      {renderField("carbs", "Carbohydrate")}
      {renderField("fat", "Fat")}
      {renderField("calories", "Calories")}
      <Button
        fullWidth
        className={s.button}
        variant="contained"
        onClick={handleClickSave}
        disabled={!isValid()}
      >
        Add entry
      </Button>
      {children}
    </>
  );
};

export default AddAutoNutritionEntryForm;
