import { TextField } from "@mui/material";
import React from "react";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import CheckInTextfield from "./CheckInTextfield";

export type ILongTextQuestionAnswer = {
  text?: string;
};

const LongTextQuestion = ({
  view,
  disableAnswer,
  element,
  handleSetNode,
}: ICheckInQuestionPropsBase<ILongTextQuestionAnswer>) => {
  const answer = element.answer as ILongTextQuestionAnswer;

  const handleChange = (newValue: string | null) => {
    handleSetNode({ answer: { text: newValue } }, true);
  };

  const isCoachView = view === EditorElementView.Coach;
  const disabled = view === EditorElementView.Coach || disableAnswer;

  return (
    <DisabledCheckInTooltip visible={isCoachView}>
      <CheckInTextfield
        placeholder="Jot down your thoughts..."
        value={answer?.text ?? ""}
        onChange={(e) => handleChange(e.target.value)}
        disabled={disabled}
        fullWidth
        multiline
        rows={3}
        inputProps={{ style: { resize: "vertical" } }}
      />
    </DisabledCheckInTooltip>
  );
};

export default LongTextQuestion;
