import clsx from "clsx";
import React from "react";
import {
  Button,
  ButtonProps,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { ReactComponent as ListViewIcon } from "../../icons/ListView.svg";
import { ReactComponent as CalendarViewIcon } from "../../icons/CalendarDate.svg";
import { Menu } from "../menu/Menu";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  primary: {
    fontWeight: 500,
  },
}));

export enum ProgramDetailsViewMode {
  CALENDAR = "calendar",
  LIST = "list",
}

export type CalendarViewOptions = Record<
  ProgramDetailsViewMode,
  CalendarViewOption
>;

export const defaultCalendarViewOptions: CalendarViewOptions = {
  [ProgramDetailsViewMode.LIST]: {
    label: "List view",
    icon: <ListViewIcon />,
  },
  [ProgramDetailsViewMode.CALENDAR]: {
    label: "Calendar view",
    icon: <CalendarViewIcon />,
  },
};
export interface CalendarViewOption {
  label: React.ReactNode;
  icon: React.ReactNode;
}

export interface ProgramDetailsViewButtonProps
  extends Omit<ButtonProps, "onChange"> {
  onChange?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ProgramDetailsViewMode,
  ) => void;
  value?: ProgramDetailsViewMode;
  options?: CalendarViewOptions;
}

export function ProgramDetailsViewButton(props: ProgramDetailsViewButtonProps) {
  const {
    className,
    value = ProgramDetailsViewMode.CALENDAR,
    options = defaultCalendarViewOptions,
    onChange,
    ...other
  } = props;
  const s = useStyles();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "program-details-view",
  });

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const {
        dataset: { viewOption: value },
      } = event.currentTarget;

      if (value && onChange) {
        onChange(event, value as ProgramDetailsViewMode);
      }

      menuState.close();
    },
    [menuState, onChange],
  );

  const selected = options[value] || Object.values(options)[0];

  return (
    <>
      <Button
        className={clsx(s.root, className)}
        {...bindTrigger(menuState)}
        startIcon={selected.icon}
        endIcon={<ArrowDropDownIcon />}
        {...other}
      >
        {selected.label}
      </Button>

      <Menu {...bindMenu(menuState)}>
        {Object.entries(defaultCalendarViewOptions).map(
          ([name, { label, icon }]) => (
            <MenuItem
              key={name}
              data-view-option={name}
              selected={name === value}
              onClick={handleClick}
            >
              <ListItemIcon className={s.icon} children={icon} />
              <ListItemText classes={{ primary: s.primary }} primary={label} />
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  );
}
