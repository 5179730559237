import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "center",
    color: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2, 3),
    backgroundColor: `${theme.palette.primary.main}0D`,
  },
}));

export interface EmailNoAccountProps extends TypographyProps {
  email: string;
}

export function EmailNoAccount(props: EmailNoAccountProps) {
  const { className, email, ...other } = props;
  const s = useStyles();
  const brandName = useCurrentBrandName();

  return (
    <Typography className={clsx(s.root, className)} {...other}>
      There isn’t a {brandName} account associated with {email}.
    </Typography>
  );
}
