import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as LogoIcon } from "./logo.svg";
import { SendUsMessageLink } from "./SendUsMessageLink";
import { AppLogo } from "./AppLogo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContemt: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    marginBottom: theme.spacing(6),
    "& path": {
      fill: theme.palette.common.black,
    },
  },
  notFoundTitle: {
    fontSize: 32,
    fontWeight: "normal",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(6),
    textAlign: "center",
  },
  homeButton: {
    fontSize: 13,
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.125, 4),
  },
  split: {
    border: 0,
    borderTop: `1px solid ${theme.palette.border.primary}`,
    margin: theme.spacing(5, 0, 3),
  },
  notFoundText: {
    fontSize: 16,
    color: theme.palette.common.black,
  },
}));

export interface Error40XProps extends BoxProps {
  message: React.ReactNode;
}

export function Error40X(props: Error40XProps) {
  const { className, message, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AppLogo className={s.logo} />
      <Typography
        className={s.notFoundTitle}
        variant="h2"
        children={
          <>
            Woops!
            <br />
            {message}
          </>
        }
      />
      <Button
        className={s.homeButton}
        variant="contained"
        href="/"
        children="Back to Home"
      />
      <hr className={s.split} />
      <Typography className={s.notFoundText}>
        Need help? <SendUsMessageLink />
      </Typography>
    </Box>
  );
}
