import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useUserIsClient } from "../../hooks/useCurrentUser";

import { SendUsMessageLink } from "./SendUsMessageLink";
import { ReactComponent as LogoIcon } from "./logo.svg";
import { useNavigate } from "react-router-dom";
import {
  CLIENT_HOME_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
} from "../../routes/routes";
import { AppLogo } from "./AppLogo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContemt: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    marginBottom: theme.spacing(6),
    "& path": {
      fill: theme.palette.common.black,
    },
  },
  notFoundTitle: {
    fontSize: 32,
    fontWeight: "normal",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(6),
  },
  button: {
    fontSize: 13,
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.125, 4),
    margin: theme.spacing(1),

    borderColor: theme.palette.common.black,
    borderWidth: 2,
    borderStyle: "solid",
    minWidth: theme.spacing(20),
  },
  split: {
    border: 0,
    borderTop: `1px solid ${theme.palette.border.primary}`,
    margin: theme.spacing(5, 0, 3),
  },
  notFoundText: {
    fontSize: 16,
    color: theme.palette.common.black,
  },
}));

export interface ErrorOtherProps extends Omit<BoxProps, "title"> {
  title: React.ReactNode;
  message: React.ReactNode;
  onClose?: () => void;
}

export function ErrorOther(props: ErrorOtherProps) {
  const { className, title, message, onClose, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const isClient = useUserIsClient();
  const homeHref = isClient ? HOME_ROUTE : COACH_PROGRAMS_PUBLISHED_ROUTE;

  const handleBackClick = React.useCallback(() => {
    navigate(homeHref);

    setTimeout(() => onClose && onClose(), 25);
  }, [homeHref, onClose]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AppLogo className={s.logo} />
      <Typography
        className={s.notFoundTitle}
        variant="h2"
        children={
          <>
            {title}
            <br />
            {message}
          </>
        }
      />

      <Box>
        <Button
          className={s.button}
          variant="outlined"
          href={homeHref}
          children="Home"
        />
        <Button
          className={s.button}
          variant="contained"
          onClick={handleBackClick}
          children="Back"
        />
      </Box>

      <hr className={s.split} />
      <Typography className={s.notFoundText}>
        Need help? <SendUsMessageLink />
      </Typography>
    </Box>
  );
}
