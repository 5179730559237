/**
 * @generated SignedSource<<7dca088454c8b1a62163e7d41d530a96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThemeProvider_workspace$data = {
  readonly logoURL: string | null | undefined;
  readonly mealLogging: boolean;
  readonly message: string | null | undefined;
  readonly name: string | null | undefined;
  readonly primaryColor: string;
  readonly slug: string;
  readonly " $fragmentType": "ThemeProvider_workspace";
};
export type ThemeProvider_workspace$key = {
  readonly " $data"?: ThemeProvider_workspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThemeProvider_workspace">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThemeProvider_workspace",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mealLogging",
      "storageKey": null
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "ee93412893bcfbea31395bfabcad2c2f";

export default node;
