/**
 * @generated SignedSource<<ae8397374181633ea20aba19a0915560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateClientPortalInput = {
  clientMutationId?: string | null | undefined;
  logoURL: string;
  mealLogging: boolean;
  message: string;
  name: string;
  slug: string;
};
export type UpdateClientPortalMutation$variables = {
  input: UpdateClientPortalInput;
};
export type UpdateClientPortalMutation$data = {
  readonly updateClientPortal: {
    readonly workspace: {
      readonly " $fragmentSpreads": FragmentRefs<"ThemeProvider_workspace">;
    };
  } | null | undefined;
};
export type UpdateClientPortalMutation = {
  response: UpdateClientPortalMutation$data;
  variables: UpdateClientPortalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateClientPortalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientPortalPayload",
        "kind": "LinkedField",
        "name": "updateClientPortal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ThemeProvider_workspace"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateClientPortalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientPortalPayload",
        "kind": "LinkedField",
        "name": "updateClientPortal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "primaryColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logoURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mealLogging",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8c1ea2578d342822e793084316b3dd8",
    "id": null,
    "metadata": {},
    "name": "UpdateClientPortalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateClientPortalMutation(\n  $input: UpdateClientPortalInput!\n) {\n  updateClientPortal(input: $input) {\n    workspace {\n      ...ThemeProvider_workspace\n      id\n    }\n  }\n}\n\nfragment ThemeProvider_workspace on Workspace {\n  primaryColor\n  name\n  logoURL\n  message\n  slug\n  mealLogging\n}\n"
  }
};
})();

(node as any).hash = "f1211f15992def11c64e48cf1e39f26e";

export default node;
