import React, { useCallback, useState } from "react";
import {
  Box,
  BoxProps,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import { ReactComponent as SaveIcon } from "../../icons/CheckMarkCircle2.svg";
import { numberInputProps, nutritionComments } from "../../constants";
import { colorSystem } from "../../theme";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";
import { useClient } from "../../hooks/useClient";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  inputBox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flex: 1,
    overflow: "hidden",
    "&": {
      height: (open: boolean) => (open ? "auto" : 23),
    },
  },

  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },

  label: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.1),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },

    "& input.Mui-disabled": {
      color: colorSystem.black,
    },
  },

  delete: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),

    "& svg": {
      width: theme.spacing(2.75),
      height: theme.spacing(2.75),
    },

    "& svg path": {
      strokeWidth: 1,
    },
  },

  date: {
    fontSize: 16,
  },

  dateBox: {
    width: "100%",
    cursor: "pointer",
  },

  inputWrapper: {
    width: "40%",
  },

  actions: {
    display: "flex",
    marginLeft: theme.spacing(3.5),
  },
}));

const removeNutritionEntryMutation = graphql`
  mutation ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation(
    $input: DeleteClientNutritionEntryInput!
  ) {
    deleteClientNutritionEntry(input: $input) {
      clientMutationId
    }
  }
`;

const upsertNutritionEntryMutation = graphql`
  mutation ClientNutritionTargetDrawerEntryHistoryItemMutation(
    $input: UpsertClientNutritionEntryInput!
  ) {
    upsertClientNutritionEntry(input: $input) {
      clientNutritionEntry {
        id
        carbsGrams
        calories
        fatGrams
        proteinGrams
        target {
          id
        }
      }
    }
  }
`;

export interface ClientNutritionTargetDrawerEntryHistoryItemProps
  extends BoxProps {
  entry: any;
  commentTypeValue: string;
  trackingTypeValue: string;
  targetId: string;
  refetchEntries: () => void;
}

export function ClientNutritionTargetDrawerEntryHistoryItem(
  props: ClientNutritionTargetDrawerEntryHistoryItemProps,
) {
  const {
    entry: defaultValue,
    commentTypeValue,
    trackingTypeValue,
    refetchEntries,
    targetId,
  } = props;
  const [entry, setEntry] = useState(defaultValue);
  const [dirty, setDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const s = useStyles(open);

  const client: any = useClient();

  const snackAlert = useSnackAlert();

  const [removeNutritionEntry] = useMutation(removeNutritionEntryMutation);
  const [upsertNutritionEntry] = useMutation(upsertNutritionEntryMutation);

  const handleDelete = React.useCallback(() => {
    removeNutritionEntry({
      variables: {
        input: { id: entry.id },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.error(errors);
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          if (refetchEntries) {
            refetchEntries();
          }
          snackAlert({
            severity: "success",
            message: "Delete successfully",
          });
        }
      },
    });
  }, [entry, refetchEntries, snackAlert, removeNutritionEntry]);

  const handleSave = React.useCallback(() => {
    upsertNutritionEntry({
      variables: {
        input: {
          targetId: targetId,
          date: entry.date,
          carbsGrams: +entry.carbsGrams,
          fatGrams: +entry.fatGrams,
          proteinGrams: +entry.proteinGrams,
          calories: +entry.calories,
        },
      },
      onCompleted: (data: any, errors) => {
        if (errors) {
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Success",
          });
          if (refetchEntries) {
            refetchEntries();
          }
        }
      },
    });
  }, [upsertNutritionEntry, targetId, entry, refetchEntries, snackAlert]);

  const validateInputNumber = React.useCallback(
    (value, prevValue, defaultValue = "0") => {
      if (+value < 0) {
        return defaultValue;
      } else if (value.length >= 6) {
        return prevValue;
      } else return value;
    },
    [],
  );

  const disableCalories = useCallback(() => {
    const { PROTEIN_ONLY } = nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      trackingTypeValue === "PORTIONS" ||
      commentTypeValue === nutritionComments.FULL_MACROS
    );
  }, [commentTypeValue, trackingTypeValue]);

  const handleChangeCarbohydrate = React.useCallback(
    (value) => {
      setDirty(true);
      setEntry({
        ...entry,
        carbsGrams: validateInputNumber(value, entry.carbsGrams, "0.0"),
      });
    },
    [setEntry, entry, validateInputNumber],
  );

  const handleChangeFat = React.useCallback(
    (value) => {
      setDirty(true);
      setEntry({
        ...entry,
        fatGrams: validateInputNumber(value, entry.fatGrams),
      });
    },
    [setEntry, entry, validateInputNumber],
  );

  const handleChangeCalories = React.useCallback(
    (value) => {
      setDirty(true);
      setEntry({
        ...entry,
        calories: validateInputNumber(value, entry.calories),
      });
    },
    [setEntry, entry, validateInputNumber],
  );

  const handleChangeProtein = React.useCallback(
    (value) => {
      setDirty(true);
      setEntry({
        ...entry,
        proteinGrams: validateInputNumber(value, entry.proteinGrams, "0.0"),
      });
    },
    [setEntry, entry, validateInputNumber],
  );

  const disableFat = useCallback(() => {
    const { PROTEIN_ONLY, PROTEIN_CALORIES, CALORIES_ONLY } = nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      commentTypeValue === PROTEIN_CALORIES ||
      commentTypeValue === CALORIES_ONLY
    );
  }, [commentTypeValue]);

  const disableCarb = useCallback(() => {
    const { PROTEIN_ONLY, PROTEIN_CALORIES, CALORIES_ONLY } = nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      commentTypeValue === PROTEIN_CALORIES ||
      commentTypeValue === CALORIES_ONLY
    );
  }, [commentTypeValue]);

  const disableProtein = useCallback(() => {
    const { CALORIES_ONLY } = nutritionComments;
    return commentTypeValue === CALORIES_ONLY;
  }, [commentTypeValue]);

  const disableButton = useCallback(() => {
    const {
      PROTEIN_ONLY,
      TRACKING_ONLY,
      PROTEIN_CALORIES,
      CALORIES_ONLY,
      FULL_MACROS,
    } = nutritionComments;
    if (!dirty) {
      return true;
    }
    switch (commentTypeValue) {
      case PROTEIN_ONLY:
        return +entry.proteinGrams < 0;
      case CALORIES_ONLY:
        return +entry.calories < 0;
      case PROTEIN_CALORIES:
        return +entry.proteinGrams < 0 || +entry.calories < 0;
      case FULL_MACROS:
        return (
          +entry.proteinGrams < 0 ||
          +entry.carbsGrams < 0 ||
          +entry.fatGrams < 0
        );
      case TRACKING_ONLY:
        return false;
    }
  }, [commentTypeValue, entry, dirty]);

  React.useEffect(() => {
    if (commentTypeValue === nutritionComments.FULL_MACROS) {
      const calories =
        +entry.fatGrams * 9 + +entry.proteinGrams * 4 + +entry.carbsGrams * 4;
      setEntry({
        ...entry,
        calories: calories.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEntry, entry.fatGrams, entry.proteinGrams, entry.carbsGrams]);

  React.useEffect(() => {
    setEntry(defaultValue);
  }, [defaultValue]);

  return (
    <Box className={s.root}>
      <Box className={s.inputBox}>
        <Box className={s.dateBox} onClick={() => setOpen(!open)}>
          <Typography
            className={s.date}
            variant="h6"
            children={dayjs(entry.date, "YYYY-MM-DD").format("MMM, DD YYYY")}
          />
        </Box>
        <Box className={s.inputWrapper}>
          <Typography variant="body1" className={s.label}>
            Fat
          </Typography>
          <TextField
            value={entry.fatGrams}
            onChange={(e) => handleChangeFat(e.target.value as string)}
            className={s.input}
            error={+entry.fatGrams < 0}
            disabled={disableFat()}
            type="number"
            InputProps={{
              inputProps: numberInputProps,
            }}
          />
        </Box>
        <Box className={s.inputWrapper}>
          <Typography variant="body1" className={s.label}>
            Carbs
          </Typography>
          <TextField
            value={entry.carbsGrams}
            onChange={(e) => handleChangeCarbohydrate(e.target.value as string)}
            className={s.input}
            error={+entry.carbsGrams < 0}
            disabled={disableCarb()}
            type="number"
            InputProps={{
              inputProps: numberInputProps,
            }}
          />
        </Box>
        <Box className={s.inputWrapper}>
          <Typography variant="body1" className={s.label}>
            Protein
          </Typography>
          <TextField
            value={entry.proteinGrams}
            onChange={(e) => handleChangeProtein(e.target.value as string)}
            className={s.input}
            disabled={disableProtein()}
            error={+entry.proteinGrams < 0}
            type="number"
            InputProps={{
              inputProps: numberInputProps,
            }}
          />
        </Box>
        {trackingTypeValue === "MACROS" && (
          <Box className={s.inputWrapper}>
            <Typography variant="body1" className={s.label}>
              Kcal
            </Typography>
            <TextField
              value={entry.calories}
              onChange={(e) => handleChangeCalories(e.target.value as string)}
              className={s.input}
              error={+entry.calories < 0}
              disabled={disableCalories()}
              type="number"
              InputProps={{
                inputProps: numberInputProps,
              }}
            />
          </Box>
        )}
      </Box>
      {!client && (
        <Box className={s.actions}>
          <IconButton
            disabled={disableButton()}
            className={s.delete}
            children={<SaveIcon />}
            onClick={handleSave}
            size="large"
          />
          <IconButton
            className={s.delete}
            children={<DeleteIcon />}
            onClick={handleDelete}
            size="large"
          />
        </Box>
      )}
      <Divider />
    </Box>
  );
}
