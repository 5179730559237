import clsx from "clsx";
import React from "react";
import {
  Box,
  Typography,
  DialogProps,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";

import { ActionButton } from "../button/ActionButton";
import { colorSystem } from "../../theme";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useAnalytics } from "../../hooks/useAnalytics";
import { PendingInvitesList } from "../list/PendingInvitesList";
import { MultipleEmailsField } from "../fields/MultipleEmailsField";
import { AppError, ClientStatus, Plan, planClients } from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { InviteLinkSettings } from "../coach-clients/InviteLinkSettings";

import { BaseDialog } from "./BaseDialog";
import { InviteDialog_inviteCode$key } from "./__generated__/InviteDialog_inviteCode.graphql";
import { InviteDialogInviteClientsMutation } from "./__generated__/InviteDialogInviteClientsMutation.graphql";
import { InviteDialogAddClientsMutation } from "./__generated__/InviteDialogAddClientsMutation.graphql";
import { InviteDialog_invitedUsers$key } from "./__generated__/InviteDialog_invitedUsers.graphql";
import { InviteDialog_addedUsers$key } from "./__generated__/InviteDialog_addedUsers.graphql";
import { InviteCodePreviewDialog } from "./InviteCodePreviewDialog";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    maxWidth: 650,
    width: 650,
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.common.black,
  },

  inviteByLink: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "&$withInviteLink": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  inviteByLinkHeader: {
    "&:not($withInviteLink)": {
      width: "45%",
    },
  },

  divider: {
    width: `calc(100% + calc(${theme.spacing(3)} * 2))`,
    margin: theme.spacing(3, 0, 3, -3),
    backgroundColor: colorSystem.secondaryGray,
  },

  pendingInvitesList: {
    maxHeight: 264,
    overflowY: "auto",
  },

  messageInput: {
    cursor: "text",
    height: 240,

    "& > textarea": {
      height: "100% !important",
    },
  },

  buttons: {
    display: "flex",
    marginTop: theme.spacing(3),

    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },

  cancelButton: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    borderRadius: 4,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
    width: "30%",
    height: theme.spacing(7),

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginRight: theme.spacing(0),
    },

    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },

  titleActive: {
    textDecoration: "underline",
  },

  inviteButton: {
    flexGrow: 1,
    height: theme.spacing(7),

    [theme.breakpoints.down("lg")]: {
      width: "49%",
      margin: theme.spacing(1, 0, 1, 0),
      flexGrow: "initial",
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  inviteCode: {
    cursor: "pointer",
    textDecoration: "underline",
  },

  addButton: {
    margin: theme.spacing(0, 3, 0, 0),

    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(1, 0, 1, 0),
    },
  },

  tabUsersStatus: {
    display: "flex",
  },

  titleAdded: {
    marginLeft: theme.spacing(2),
  },

  withInviteLink: {},
}));

const inviteClientsMutation = graphql`
  mutation InviteDialogInviteClientsMutation($input: InviteClientsInput!) {
    inviteClients(input: $input) {
      invites {
        ...PendingInviteItem_invite
      }
      problems {
        code
        message
      }
    }
  }
`;

const addClientsMutation = graphql`
  mutation InviteDialogAddClientsMutation($input: AddClientsInput!) {
    addClients(input: $input) {
      invites {
        ...PendingInviteItem_invite
      }
      problems {
        code
        message
      }
    }
  }
`;

const inviteCodeFragment = graphql`
  fragment InviteDialog_inviteCode on InviteCode {
    ...InviteLinkSettings_inviteCode
    ...InviteCodePreviewDialog_inviteCode
    code
  }
`;

const invitedUsersFragment = graphql`
  fragment InviteDialog_invitedUsers on InviteConnection {
    totalCount
    edges {
      node {
        client {
          clientStatus
        }
      }
    }
    ...PendingInvitesList_invites
  }
`;

const addedUsersFragment = graphql`
  fragment InviteDialog_addedUsers on InviteConnection {
    totalCount
    edges {
      node {
        client {
          clientStatus
        }
      }
    }
    ...PendingInvitesList_invites
  }
`;

export interface InviteDialogProps extends DialogProps {
  inviteCode?: InviteDialog_inviteCode$key;
  invitedUsers?: InviteDialog_invitedUsers$key;
  addedUsers?: InviteDialog_addedUsers$key;
  onInvite: () => void;
}

export function InviteDialog(props: InviteDialogProps) {
  const {
    className,
    inviteCode: inviteCodeRef,
    invitedUsers: invitedUsersRef,
    addedUsers: addedUsersRef,
    onInvite,
    ...other
  } = props;

  const brandName = useCurrentBrandName();

  const DEFAULT_MESSAGE = `Hello!\n\nI’d like to invite you to be my client on ${brandName}. It’s a coaching platform that will help us stay organized, chat with each other, and track your progress.\n\nIt takes less than a minute to join – see you there!`;

  const inviteCode = useFragment(inviteCodeFragment, inviteCodeRef);
  const invitedUsers = useFragment(invitedUsersFragment, invitedUsersRef);
  const addedUsers = useFragment(addedUsersFragment, addedUsersRef);
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const [trackEvent] = useAnalytics();
  const [emails, setEmails] = React.useState<string[]>([]);
  const [message, setMessage] = React.useState<string>(DEFAULT_MESSAGE);
  const [tabUserStatus, setTabUserStatus] = React.useState<string>(
    ClientStatus.PENDING,
  );
  const [previewInviteCode, setPreviewInviteCode] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState({
    added: addedUsers?.totalCount || 0,
    invited: invitedUsers?.totalCount || 0,
  });

  const user = useCurrentUser();

  const [inviteClients, inviting] =
    useMutation<InviteDialogInviteClientsMutation>(inviteClientsMutation);

  const [addClients, adding] =
    useMutation<InviteDialogAddClientsMutation>(addClientsMutation);

  const onError = useGenericErrorHandler();

  const handleEmailsChange = React.useCallback((values) => {
    setEmails(values);
  }, []);

  const handleMessageChange = React.useCallback(({ target: { value } }) => {
    setMessage(value);
  }, []);

  const handleCancel = React.useCallback(() => {
    setEmails([]);
  }, []);

  const handleInvite = React.useCallback(() => {
    if (
      user.clientsCountNoSample +
        emails.length +
        totalCount.invited +
        totalCount.added >
      Number(planClients(user.plan as Plan, true))
    ) {
      snackAlert({
        severity: "error",
        message: `Max ${planClients(user.plan as Plan)} clients`,
      });
    } else {
      inviteClients({
        variables: {
          input: {
            emails,
            message: message || DEFAULT_MESSAGE,
          },
        },
        onCompleted: ({ inviteClients }, errors) => {
          if (errors && errors[0]) {
            const error = errors[0] as AppError;

            if (error.code?.includes("invite")) {
              console.error(error);
              snackAlert({
                severity: "error",
                message: error.message,
              });
            } else {
              onError(error);
            }
          } else {
            for (const email of emails) {
              trackEvent("Coach - Invite Client", { email });
            }

            setEmails([]);

            const { invites, problems } = inviteClients;
            const severity = problems.length ? "error" : "success";
            const messages = [
              invites.length && `${invites.length} client successfully added.`,
              ...problems.map(({ message }) => message),
            ];

            onInvite();

            snackAlert({
              severity,
              message: messages
                .filter(Boolean)
                .map((text, index) => <Box key={index}>{text}</Box>),
            });
          }
        },
        onError,
        updater: (store) => {
          const invites = ConnectionHandler.getConnection(
            store.getRoot(),
            "ClientInvites_invitedUsers",
          );

          if (invites) {
            const newInvites = store
              .getRootField("inviteClients")
              .getLinkedRecords("invites");

            setTotalCount({
              ...totalCount,
              invited: totalCount.invited + newInvites.length,
            });

            for (const newInvite of newInvites) {
              const edge = ConnectionHandler.createEdge(
                store,
                invites,
                newInvite,
                "InviteEdge",
              );
              ConnectionHandler.insertEdgeBefore(invites, edge);
            }
          }
        },
      });
    }
  }, [
    emails,
    inviteClients,
    message,
    onError,
    snackAlert,
    trackEvent,
    user,
    totalCount,
  ]);

  const handleAdd = React.useCallback(() => {
    if (
      user.clientsCountNoSample +
        emails.length +
        totalCount.invited +
        totalCount.added >
      Number(planClients(user.plan as Plan, true))
    ) {
      snackAlert({
        severity: "error",
        message: `Max ${planClients(user.plan as Plan)} clients`,
      });
    } else {
      addClients({
        variables: {
          input: {
            emails,
          },
        },
        onCompleted: ({ addClients }, errors) => {
          if (errors && errors[0]) {
            const error = errors[0] as AppError;

            if (error.code?.includes("invite")) {
              console.error(error);
              snackAlert({
                severity: "error",
                message: error.message,
              });
            } else {
              onError(error);
            }
          } else {
            for (const email of emails) {
              trackEvent("Coach - Add Client", { email });
            }

            setEmails([]);

            const { invites, problems } = addClients;
            const severity = problems.length ? "error" : "success";
            const messages = [
              invites.length && `${invites.length} client successfully added.`,
              ...problems.map(({ message }) => message),
            ];
            onInvite();

            snackAlert({
              severity,
              message: messages
                .filter(Boolean)
                .map((text, index) => <Box key={index}>{text}</Box>),
            });
          }
        },
        onError,
        updater: (store) => {
          const invites = ConnectionHandler.getConnection(
            store.getRoot(),
            "ClientInvites_addedUsers",
          );

          if (invites) {
            const newInvites = store
              .getRootField("addClients")
              .getLinkedRecords("invites");

            setTotalCount({
              ...totalCount,
              added: totalCount.added + newInvites.length,
            });

            for (const newInvite of newInvites) {
              const edge = ConnectionHandler.createEdge(
                store,
                invites,
                newInvite,
                "InviteEdge",
              );
              ConnectionHandler.insertEdgeBefore(invites, edge);
            }
          }
        },
      });
    }
  }, [emails, onError, snackAlert, trackEvent, user, addClients, totalCount]);

  const handlePreviewInviteCodeOpen = React.useCallback(
    () => setPreviewInviteCode(true),
    [],
  );

  const handlePreviewInviteCodeClose = React.useCallback(
    () => setPreviewInviteCode(false),
    [],
  );

  const hasInviteLink = Boolean(inviteCode);
  const disabled = inviting || adding;

  return (
    <>
      {previewInviteCode ? (
        <InviteCodePreviewDialog
          open
          inviteCode={inviteCode}
          onClose={handlePreviewInviteCodeClose}
        />
      ) : (
        <BaseDialog
          className={clsx(s.root, className)}
          title={emails.length === 0 ? "Invite clients" : "Invite by email"}
          classes={{
            paper: s.paper,
          }}
          {...other}
        >
          <Box>
            {emails.length === 0 ? (
              <Typography className={s.title} variant="h3">
                Invite by Email
              </Typography>
            ) : (
              <Divider className={s.divider} />
            )}
            <MultipleEmailsField
              values={emails}
              onChange={handleEmailsChange}
              disabled={disabled}
            />
          </Box>

          <Divider className={s.divider} />

          {emails.length === 0 ? (
            <>
              <Box
                className={clsx(
                  s.inviteByLink,
                  hasInviteLink && s.withInviteLink,
                )}
              >
                <Box
                  className={clsx(
                    s.inviteByLinkHeader,
                    hasInviteLink && s.withInviteLink,
                  )}
                >
                  <Typography className={s.title} variant="h3">
                    Invite by Link
                  </Typography>
                  <Typography className={s.text} variant="body1">
                    Use this link to invite your clients.
                    {inviteCode && (
                      <>
                        {" "}
                        You can also share your{" "}
                        <span
                          className={s.inviteCode}
                          onClick={handlePreviewInviteCodeOpen}
                        >
                          invite code
                        </span>
                        .
                      </>
                    )}
                  </Typography>
                </Box>

                <InviteLinkSettings inviteCode={inviteCode} />
              </Box>

              <Divider className={s.divider} />

              <Box>
                <Box className={s.tabUsersStatus}>
                  <Typography
                    className={clsx(
                      s.title,
                      tabUserStatus === ClientStatus.PENDING && s.titleActive,
                    )}
                    variant="h3"
                    onClick={() => setTabUserStatus(ClientStatus.PENDING)}
                  >
                    Pending invites ({totalCount.invited})
                  </Typography>
                  <Typography
                    className={clsx(
                      s.title,
                      s.titleAdded,
                      tabUserStatus === ClientStatus.ADDED && s.titleActive,
                    )}
                    variant="h3"
                    onClick={() => setTabUserStatus(ClientStatus.ADDED)}
                  >
                    Added users ({totalCount.added})
                  </Typography>
                </Box>
                <PendingInvitesList
                  className={s.pendingInvitesList}
                  invites={
                    tabUserStatus === ClientStatus.PENDING
                      ? invitedUsers
                      : addedUsers
                  }
                  totalCount={totalCount}
                  setTotalCount={setTotalCount}
                />
              </Box>
            </>
          ) : (
            <Box>
              <TextField
                fullWidth
                multiline
                value={message}
                onChange={handleMessageChange}
                InputProps={{
                  disableUnderline: true,
                  className: s.messageInput,
                }}
              />
              <Box className={s.buttons}>
                <Button
                  className={s.cancelButton}
                  variant="outlined"
                  children="Cancel"
                  onClick={handleCancel}
                  disabled={disabled}
                />
                <ActionButton
                  className={clsx(s.inviteButton, s.addButton)}
                  children="Add without invite"
                  onClick={handleAdd}
                  disabled={emails.length === 0 || disabled}
                />
                <ActionButton
                  className={s.inviteButton}
                  children={`Invite client${emails.length === 1 ? "" : "s"}`}
                  onClick={handleInvite}
                  disabled={emails.length === 0 || disabled}
                />
              </Box>
            </Box>
          )}
        </BaseDialog>
      )}
    </>
  );
}
