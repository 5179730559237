import { groupBy } from "lodash";
// TODO_Editor
// import { SchemaElements } from "../editor/normalizers/withSchema";

import { WorkoutExercise, WorkoutSection } from "./types";
import {
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
} from "../../constants";
import { generateId } from "../new-editor/utils/nodeUtil";
import { fixCompletedRepsTypoInWorkoutSection } from "../editor/utils/common";

export const isSupersetAtIndex = (
  exercises: WorkoutExercise[],
  index: number,
) => index > 0 && exercises[index - 1].superset === exercises[index].superset;

export const orderedExercises = (
  exercises: WorkoutExercise[],
  move?: [number, number],
) => {
  const result = exercises.map((it) => ({
    ...it,
    superset: it.superset || it.id,
  }));

  if (move) {
    result.splice(move[1], 0, result.splice(move[0], 1)[0]);
  }

  for (let i = 0; i < result.length; i++) {
    const it = result[i];
    const current = it.superset;
    const previous = result[i - 1]?.superset;
    const next = result[i + 1]?.superset;

    if (previous !== current && next !== current) {
      result[i] = {
        ...it,
        superset: it.id,
      };
    }

    if (previous && next && previous === next) {
      result[i] = {
        ...it,
        superset: next,
      };
    }
  }

  return result;
};

export const countExercises = (exercises: WorkoutExercise[]) => {
  let counter = 0;

  return exercises.map((it, index) => {
    if (it.superset === exercises[index - 1]?.superset) {
      counter += 1;
    } else if (it.superset === exercises[index + 1]?.superset) {
      counter = 1;
    } else {
      counter = 0;
    }

    return counter;
  });
};

export const createEmptyWorkout = () => ({
  title: "",
  exercises: [],
});

export const createEmptySet = () => {
  return { reps: "", weight: "" };
};

export const createEmptyExercise = () => {
  const id = generateId();
  return {
    id,
    superset: id,
    title: "",
    subtitle: "",
    sets: [createEmptySet()],
    instructions: "",
    images: [],
    typeSet: ExerciseTypeSet.WEIGHT,
    typeReps: ExerciseTypeReps.WHOLE,
  };
};

export const defaultSectionTitle = "Untitled Section";

type ActivityType = {
  completedAt: string;
  component: {
    title: string;
  };
};

export type UserInfo = {
  displayName: string;
  photoURL?: string;
  email?: string;
};

export type WorkoutExerciseResult = {
  id: string;
  client: UserInfo & { id: string };
  section: WorkoutSection;
  activity: ActivityType;
};

export type WorkoutActivitySection = WorkoutSection & {
  activity: ActivityType;
};

export type WorkoutExerciseClientResult = {
  results: number;
  sections: WorkoutActivitySection[];
} & WorkoutExerciseResult["client"];

export const groupWorkoutClients = (
  workoutExerciseResults: WorkoutExerciseResult[],
  exercise: WorkoutExercise,
): WorkoutExerciseClientResult[] =>
  Object.values(
    groupBy(
      workoutExerciseResults.map(({ client }) => client),
      ({ id }) => id,
    ),
  )
    .map((clients) => clients[0])
    .map((client) => {
      const sections = workoutExerciseResults
        .filter((it) => it.client.id === client.id)
        .map(({ section: { exercises, ...section }, activity }) => ({
          ...section,
          activity,
          exercises: exercises.filter(
            ({ assetId, title }) =>
              title === exercise.title ||
              (assetId && assetId === exercise.assetId),
          ),
        }));

      const results = sections
        .map(({ exercises }) => exercises.length)
        .reduce((a, b) => a + b, 0);

      return {
        ...client,
        sections,
        results,
      };
    });

export const setBeforeMaskedValueChange = (newState, oldState, userInput) => {
  const { value } = newState;
  let selection = newState.selection;
  let cursorPosition = selection ? selection.start : null;

  if (userInput === "-" && cursorPosition < 4) {
    cursorPosition = 4;
    selection = { start: cursorPosition, end: cursorPosition };
  }

  return {
    value,
    selection,
  };
};

export const hasExtraColumn = (typeExtraMeasurement: ExerciseTypeExtra) => {
  return (
    typeExtraMeasurement !== null &&
    typeExtraMeasurement !== undefined &&
    typeExtraMeasurement !== ExerciseTypeExtra.NONE
  );
};

export const getExtraColumnLabel = (extraColumn: ExerciseTypeExtra) => {
  switch (extraColumn) {
    case ExerciseTypeExtra.RATE_OF_PERCEIVED_EXERTION:
      return "RPE";
    case ExerciseTypeExtra.REPS_IN_RESERVE:
      return "RIR";
    default:
      return "";
  }
};

export const getRepsColumnLabel = (typeReps: string, history: boolean) => {
  return "Reps";
};

export const getSetFieldMaxLength = (
  name: string,
  typeReps: ExerciseTypeReps,
  typeSet: ExerciseTypeSet,
) => {
  if (name === "type") {
    return 100;
  }
  if (name === "extraMeasurement") {
    return 3;
  }

  const repsNames = ["reps", "completedReps"];
  if (repsNames.includes(name)) {
    return typeReps === ExerciseTypeReps.RANGE ? 7 : 3;
  }

  return typeSet === ExerciseTypeSet.DISTANCE ? 4 : 3;
};

export const isPositiveNumber = (value: number | string): boolean => {
  if (typeof value === "string") {
    return isPositiveNumber(parseFloat(value));
  }

  return !isNaN(value) && value > 0;
};

export const getRange = (
  name: string,
  value: string,
  rangeStart: string,
  rangeEnd: string,
): string => {
  if (name === "rangeStart") {
    const end = rangeEnd ? rangeEnd : "";
    return `${value}-${end}`;
  } else if (name === "rangeEnd") {
    const start = rangeStart ? rangeStart : "";
    return `${start}-${value}`;
  } else {
    throw new Error("Invalid name provided");
  }
};

export const parseWorkoutResultValueJsonToWorkoutSection = (value: string) => {
  const workoutSection = JSON.parse(value);
  fixCompletedRepsTypoInWorkoutSection(workoutSection);
  return workoutSection as WorkoutSection;
};
