import React from "react";

import { ThemeProvider_workspace$data } from "../components/app/__generated__/ThemeProvider_workspace.graphql";
import { isBrandedApp } from "../utils/mobile";

export const CurrentWorkspaceContext =
  React.createContext<ThemeProvider_workspace$data>(null);

export function useCurrentWorkspace() {
  return React.useContext(CurrentWorkspaceContext);
}

export function useCurrentBrandName() {
  const workspace = useCurrentWorkspace();
  // TODO_Branded_Apps: Replace `isBrandedApp` with condition if current workspace is branded;
  return isBrandedApp ? workspace.name : "Stridist";
}
