import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
} from "@mui/material";
import React, { ReactNode, useRef, useState } from "react";

import Cropper, { Crop, PixelCrop, ReactCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import { Media } from "./Image";
export interface CropScaled extends Crop {
  scaleX: number;
  scaleY: number;
}
type ImageCropProps = {
  open: boolean;
  onClose: () => void;
  imageSrc: string;
  previewCanvasRef?: React.MutableRefObject<HTMLCanvasElement>;
  completedCrop?: PixelCrop;
  setCompletedCrop?: React.Dispatch<React.SetStateAction<PixelCrop>>;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement>>;
  preview?: boolean;
  setPreview?: React.Dispatch<React.SetStateAction<boolean>>;
  setMedia?: React.Dispatch<React.SetStateAction<Media>>;
  media?: Media;
  setIsCroppedImage?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: ReactNode;
};
const ImageCropDialog = ({
  open,
  onClose,
  imageSrc,
  previewCanvasRef,
  completedCrop,
  setCompletedCrop,
  setAnchorEl,
  preview,
  setPreview,
  setMedia,
  media,
  setIsCroppedImage,
  children,
}: ImageCropProps) => {
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [preview],
  );
  const handleSave = async () => {
    setPreview && setPreview(true);
    onClose();
    setAnchorEl && setAnchorEl(null);
  };
  const handleChange = (c) => {
    setCrop(c);
  };
  React.useEffect(() => {
    if (imgRef.current?.clientWidth) {
      const newMedia = {
        ...media,
        crop: {
          ...crop,
          scaleX: imgRef.current?.naturalWidth / imgRef.current?.clientWidth,
          scaleY: imgRef.current?.naturalHeight / imgRef.current?.clientHeight,
        },
      };

      setMedia && setMedia(newMedia);
    }
  }, [crop]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiPaper-root": {
          borderRadius: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
          maxWidth: "initial",
          minWidth: "25vw",
          minHeight: "25vh",
        },
      }}
    >
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            overflow: "hidden",
            marginBottom: "20px",
          }}
        >
          <ReactCrop
            crop={crop}
            onChange={(c, p) => {
              setPreview && setPreview(false);
              handleChange(c);
              setIsCroppedImage(true);
            }}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imageSrc}
              style={{ maxHeight: "70vh" }}
            />
          </ReactCrop>
        </Box>
        <DialogActions sx={{ width: "100%", p: 0 }}>
          <Box display={"flex"} width={"100%"} flexDirection={"column"}>
            {children && children}
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                handleSave();
              }}
              color="primary"
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCropDialog;
