import clsx from "clsx";
import React, { useRef } from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  WorkoutSectionExercise,
  getExerciseBoxShadow,
} from "../../components/workout/WorkoutSectionExercise";
import {
  countExercises,
  createEmptyExercise,
  defaultSectionTitle,
  createEmptyWorkout,
} from "../../../workout/utils";
import { WorkoutDrawer } from "../../../workout/WorkoutDrawer";
import { WorkoutExercise, WorkoutSection } from "../../../workout/types";
import { useSaveExercise } from "../../../exercise-library/hooks/useSaveExercise";
import { WorkoutTitle } from "../../../workout/WorkoutTitle";
import {
  HighlightContainer,
  WORKOUT_SECTION_HIGHLIGHT_CONTAINER_CLASS,
} from "../../../container/HighlightContainer";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { useQueryParam } from "../../../../hooks/useQueryParam";
import { useAddExercise } from "../../../../hooks/useAddExercise";
import {
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { useEditorProgram, useReadOnly } from "../../hooks";

export const WORKOUT_SECTION = "workout_section";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },

  empty: {},

  title: {
    display: "flex",
    alignItems: "center",
    zIndex: 1,
    position: "relative",
  },

  titleText: {
    fontSize: 24,
    fontWeight: 700,
  },

  more: {
    marginLeft: theme.spacing(1),
    display: "none",
    "$title:hover h5 ~ &": {
      display: "block",
    },
  },

  editSection: {
    marginLeft: "auto",
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: "nowrap",
    marginRight: theme.spacing(-0.5),
    flexShrink: 0,
  },

  addButton: {
    fontSize: 16,
    fontWeight: 700,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    backgroundColor: theme.palette.selected.light,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(-2),

    "$empty &": {
      marginTop: theme.spacing(2),
    },
  },

  selectedExercise: {
    [theme.breakpoints.up("md")]: {
      borderColor: theme.palette.primary.main,
      boxShadow: getExerciseBoxShadow(theme.palette.primary.dark),
    },
  },
}));

// export interface ElementWithWorkout extends ElementWithId {
//   workout: WorkoutSection;
// }

// export interface WorkoutSectionElementProps extends RenderElementProps {
//   element: ElementWithWorkout;
// }

const programFragment = graphql`
  fragment WorkoutSectionElement_program on Program {
    id
  }
`;

export function WorkoutSectionElement(props: any) {
  const { attributes, element, children } = props;
  const s = useStyles();
  const programRef = useEditorProgram();
  const [idProgram] = useQueryParam("id", "");
  const [disabledAction] = useQueryParam("notification", "");
  const program = useFragment(programFragment, programRef);

  const editor = useEditorRef();
  const path = findNodePath(editor, element);
  if (!path) throw new Error("Path not found");

  const handleRemoveNode = () => {
    removeNodes(editor, { at: path });
  };

  const handleSetNode = (dataToSet: object) => {
    setNodes(editor, dataToSet, { at: path });
  };

  const addExercise = useAddExercise();

  const workout = element.workout || createEmptyWorkout();
  const { exercises } = workout;
  const counters = countExercises(exercises);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedExercise, setSelectedExercise] =
    React.useState<WorkoutExercise>();
  const readOnly = useReadOnly();
  const saveExercise = useSaveExercise();

  const updateWorkout = React.useCallback(
    (update: Partial<WorkoutSection>) => {
      setNodes(editor, { workout: { ...workout, ...update } }, { at: path });
    },
    [editor, element.id, workout],
  );
  const handleDelete = React.useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      exercise?: WorkoutExercise,
    ) => {
      if (exercise) {
        updateWorkout({
          exercises: exercises.filter((it) => it !== exercise),
        });
      } else {
        handleRemoveNode();
      }
    },
    [editor, element.id, exercises, updateWorkout],
  );

  const handleSectionEdit = React.useCallback((event) => {
    setSelectedExercise(null);
    setOpenDrawer(true);
  }, []);

  const handleEdit = React.useCallback((event, exercise?: WorkoutExercise) => {
    setSelectedExercise({ ...createEmptyExercise(), ...exercise });
    setOpenDrawer(true);
  }, []);

  const handleAddToLibrary = React.useCallback(
    (event, exercise: WorkoutExercise) => {
      saveExercise({ exercise });
    },
    [saveExercise],
  );

  const handleAddExercise = React.useCallback(() => {
    setSelectedExercise(createEmptyExercise());
    setOpenDrawer(true);
  }, []);

  const handleDrawerClose = React.useCallback(() => {
    setOpenDrawer(false);
    setSelectedExercise(null);
  }, []);

  const handleTitleChange = React.useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      title: string,
    ) => updateWorkout({ title }),
    [updateWorkout],
  );

  const handleHighlightClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (readOnly) {
        return;
      }

      const el = event.target as HTMLDivElement;

      if (el && "closest" in el) {
        const closestExercise = el.closest("[data-exercise-id]");
        const withinWorkoutSection = el.closest(
          `.${WORKOUT_SECTION_HIGHLIGHT_CONTAINER_CLASS}`,
        );

        if (withinWorkoutSection && !closestExercise) {
          setSelectedExercise(null);
        }
      }

      setOpenDrawer(true);
    },
    [],
  );

  const handleExerciseClick = React.useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      exercise: WorkoutExercise,
    ) => {
      if (readOnly) {
        return;
      }

      setSelectedExercise({ ...createEmptyExercise(), ...exercise });
      setOpenDrawer(true);
    },
    [readOnly],
  );

  const handleExerciseUpdate = React.useCallback(
    (exercise: WorkoutExercise) =>
      updateWorkout({
        exercises: exercises.map((it) =>
          it.id === exercise.id ? exercise : it,
        ),
      }),
    [exercises, updateWorkout],
  );

  return (
    <>
      <HighlightContainer enabled={openDrawer} onClick={handleHighlightClick}>
        <Box
          className={clsx(s.root, exercises.length === 0 && s.empty)}
          {...attributes}
          contentEditable={false}
        >
          {children}
          <Box className={s.title}>
            <WorkoutTitle
              classes={{ text: s.titleText }}
              value={workout.title}
              onChange={handleTitleChange}
              defaultValue={defaultSectionTitle}
              placeholder={defaultSectionTitle}
            />

            {(!readOnly || addExercise) && !disabledAction && (
              <Button
                className={s.editSection}
                variant="text"
                color="primary"
                onClick={handleSectionEdit}
              >
                Edit section block
              </Button>
            )}
          </Box>

          {exercises.map((exercise, index) => (
            <WorkoutSectionExercise
              key={index}
              className={clsx(
                exercise.id === selectedExercise?.id && s.selectedExercise,
              )}
              exercise={exercise}
              counter={counters[index]}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onAddToLibrary={handleAddToLibrary}
              onClick={handleExerciseClick}
              onUpdate={handleExerciseUpdate}
              programId={program?.id || idProgram}
            />
          ))}

          {(!readOnly || addExercise) && !disabledAction && (
            <Button
              className={s.addButton}
              variant="outlined"
              fullWidth
              onClick={handleAddExercise}
            >
              Add exercise
            </Button>
          )}
        </Box>
      </HighlightContainer>
      {openDrawer && (
        <WorkoutDrawer
          contentEditable={false}
          section={workout}
          exercise={selectedExercise}
          onSelectExercise={setSelectedExercise}
          onUpdate={updateWorkout}
          onClose={handleDrawerClose}
          onRemove={handleDelete}
          onTitleChange={handleTitleChange}
          open
        />
      )}
    </>
  );
}
