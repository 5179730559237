import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { usePopupState } from "material-ui-popup-state/hooks";

import {
  WorkoutExerciseHistoryDialog,
  WorkoutExerciseHistoryDialogProps,
} from "./WorkoutExerciseHistoryDialog";
import HistoryIcon from "../../icons/HistoryIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(5),
    padding: 0,

    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },

  text: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: theme.spacing(0.75),
  },
}));

export interface WorkoutExerciseHistoryButtonProps
  extends IconButtonProps,
    Pick<WorkoutExerciseHistoryDialogProps, "exercise"> {}

export const WorkoutExerciseHistoryButton = React.forwardRef<
  HTMLButtonElement,
  WorkoutExerciseHistoryButtonProps
>((props: WorkoutExerciseHistoryButtonProps, ref) => {
  const { className, children, exercise, ...other } = props;
  const s = useStyles();
  const dialogState = usePopupState({
    variant: "popover",
    popupId: "workout-exercise-history",
  });

  const handleHistoryClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      dialogState.open(event);
    },
    [dialogState],
  );
  const theme = useTheme();
  return (
    <>
      <IconButton
        className={clsx(s.root, className)}
        color="primary"
        ref={ref}
        onClick={handleHistoryClick}
        {...other}
        size="large"
      >
        <HistoryIcon fill={theme.palette.primary.main} />
        {children && <span className={s.text}>{children}</span>}
      </IconButton>
      {dialogState.isOpen && (
        <WorkoutExerciseHistoryDialog
          exercise={exercise}
          onClose={dialogState.close}
          open
        />
      )}
    </>
  );
});
