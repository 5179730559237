import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Units, UnitsLabels, numberInputProps } from "../../constants";
import { polyfillCSS } from "../../utils/css";

import { RadioGroupField } from "./RadioGroupField";
import { FieldsGroup } from "./FieldsGroup";
import { FieldError } from "./FieldError";

const useStyles = makeStyles((theme) => ({
  root: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  inputs: {
    display: "flex",
    marginRight: theme.spacing(-2),

    "& > *": {
      backgroundColor: theme.palette.background.paper,

      width: polyfillCSS(`calc(100% / 2 - ${theme.spacing(2)} * 2)`),
      margin: theme.spacing(0, 2, 2, 0),
      flexGrow: 1,
      flexShrink: 0,
    },
  },

  adornment: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 0,
    marginRight: 0,

    [theme.breakpoints.up("sm")]: {
      marginLeft: "initial",
    },
  },

  radioLabel: {
    fontWeight: 500,
  },

  inputGroups: {
    display: "block",

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      "& > *": {
        flexGrow: 1,
      },

      "& > *:last-child": {
        marginLeft: theme.spacing(1.75),
      },
    },
  },

  error: {
    position: "absolute",
  },
}));

export type BodyWeightHeightValue = {
  units: Units;
  height: string;
  weight: string;
};

type MaybeNumber = number | "";

type State = {
  units: Units;
  heightCm: MaybeNumber;
  heightFt: MaybeNumber;
  heightIn: MaybeNumber;
  weightLbs: MaybeNumber;
  weightKg: MaybeNumber;
};

const toMaybeNumber = (x: string, max = 999): MaybeNumber => {
  const num = parseFloat(x);

  return num ? Math.min(num, max) : 0;
};

const round = (x: number, p = 0) => {
  const d = Math.pow(10, p);

  return Math.round(x * d) / d;
};

const fromMaybeNumber = (x: MaybeNumber) => x || 0;

const parseValue = ({
  units,
  height,
  weight,
}: BodyWeightHeightValue): State => {
  let heightCm: MaybeNumber = "",
    heightFt: MaybeNumber = "",
    heightIn: MaybeNumber = "",
    weightLbs: MaybeNumber = "",
    weightKg: MaybeNumber = "";

  if (units === Units.METRIC) {
    weightKg = toMaybeNumber(weight);
    heightCm = toMaybeNumber(height);
  } else {
    const heightUS = toMaybeNumber(height);

    if (heightUS) {
      heightFt = Math.floor(heightUS / 12);
      heightIn = round(heightUS - heightFt * 12, 2);
    }

    weightLbs = toMaybeNumber(weight);
  }

  return {
    units,
    heightCm,
    heightFt,
    heightIn,
    weightLbs,
    weightKg,
  };
};

export interface BodyWeightHeightFieldProps
  extends Omit<BoxProps, "onChange" | "error"> {
  disabled?: boolean;
  onChange: (value: BodyWeightHeightValue) => void;
  value: BodyWeightHeightValue;
  error?: boolean;
}

export function BodyWeightHeightField(props: BodyWeightHeightFieldProps) {
  const {
    className,
    disabled,
    onChange,
    value: initialValue,
    error,
    ...other
  } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const [value, setValue] = React.useState(initialValue);
  const [state, setState] = React.useState<State>(parseValue(value));
  const [touched, setTouched] = React.useState(error);

  const handleChange = React.useCallback(
    ({ units, heightFt, heightIn, heightCm, weightLbs, weightKg }: State) => {
      const height =
        units === Units.US
          ? 12 * fromMaybeNumber(heightFt) + fromMaybeNumber(heightIn)
          : heightCm;
      const weight = units === Units.US ? weightLbs : weightKg;

      const value = {
        units,
        height: height && String(height),
        weight: weight && String(weight),
      };

      setTouched(true);
      setValue(value);
      onChange(value);
    },
    [onChange],
  );

  const updateState = React.useCallback(
    (update) => {
      const newState = {
        ...state,
        ...update,
      };

      setState(newState);
      handleChange(newState);
    },
    [handleChange, state],
  );

  const heightLabel = React.useMemo(() => {
    if (mdUp) {
      return state.units === Units.US ? "feet" : "centimeters";
    }

    return state.units === Units.US ? "ft" : "cm";
  }, [mdUp, state.units]);

  const inchesLabel = React.useMemo(() => {
    return mdUp ? "inches" : "in";
  }, [mdUp]);

  const weightLabel = React.useMemo(() => {
    if (mdUp) {
      return state.units === Units.US ? "pounds" : "kilograms";
    }

    return state.units === Units.US ? "lbs" : "kg";
  }, [mdUp, state.units]);

  const heightValue = React.useMemo(
    () => (state.units === Units.US ? state.heightFt : state.heightCm) || 0,
    [state.heightCm, state.heightFt, state.units],
  );

  const weightValue = React.useMemo(
    () => (state.units === Units.US ? state.weightLbs : state.weightKg) || 0,
    [state.units, state.weightKg, state.weightLbs],
  );

  const handleUnitsChange = React.useCallback(
    ({ target: { value } }) => {
      const units = value;
      const newState = { ...state, units };

      if (value === Units.US) {
        if (state.weightKg) {
          newState.weightLbs = Math.round(2.205 * state.weightKg);
        }

        if (state.heightCm) {
          const heightFt = state.heightCm / 30.48;

          newState.heightFt = Math.floor(heightFt);
          newState.heightIn = round((heightFt - newState.heightFt) * 12, 2);
        }
      } else {
        if (state.weightLbs) {
          newState.weightKg = round(state.weightLbs / 2.205, 1);
        }

        if (state.heightFt) {
          let newHeightCm = state.heightFt * 30.48;

          if (state.heightIn) {
            newHeightCm += state.heightIn * 2.52;
          }

          newState.heightCm = Math.round(newHeightCm);
        }
      }

      updateState(newState);
    },
    [state, updateState],
  );

  const handleHeightChange = React.useCallback(
    ({ target: { value } }) => {
      const num = toMaybeNumber(value);
      const key = state.units === Units.US ? "heightFt" : "heightCm";

      updateState({ [key]: num });
    },
    [state, updateState],
  );

  const handleHeightExtraChange = React.useCallback(
    ({ target: { value } }) => {
      const num = toMaybeNumber(value);

      updateState({
        heightIn: num,
      });
    },
    [updateState],
  );

  const handleWeightChange = React.useCallback(
    ({ target: { value } }) => {
      const num = toMaybeNumber(value);
      const key = state.units === Units.US ? "weightLbs" : "weightKg";

      updateState({ [key]: num });
    },
    [state, updateState],
  );

  const unitOptions = React.useMemo(
    () =>
      Object.keys(Units).map((unit) => ({
        value: unit,
        label: UnitsLabels[unit],
      })),
    [],
  );

  const handleBlur = React.useCallback(() => {
    setTouched(true);
  }, []);

  const heightIn = state.heightIn || 0;
  const heightValid = Boolean(value.height);
  const weightValid = Boolean(value.weight);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.inputGroups}>
        <FieldsGroup label="Height">
          <Box className={s.inputs}>
            <TextField
              variant="outlined"
              type="number"
              value={heightValue || ""}
              onChange={handleHeightChange}
              error={!heightValid || (error && value.height === "null")}
              disabled={disabled}
              onFocus={handleBlur}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className={s.adornment}
                    position="end"
                    children={heightLabel}
                  />
                ),
                inputProps: numberInputProps,
              }}
            />
            {state.units === Units.US && (
              <TextField
                variant="outlined"
                type="number"
                value={`${heightIn}`}
                onChange={handleHeightExtraChange}
                error={!heightValid || (error && value.height === "null")}
                disabled={disabled}
                onFocus={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={s.adornment}
                      position="end"
                      children={inchesLabel}
                    />
                  ),
                  inputProps: {
                    ...numberInputProps,
                    step: 0.01,
                  },
                }}
              />
            )}
          </Box>
          {error && !heightValid && (
            <FieldError className={s.error}>
              Please enter a valid height
            </FieldError>
          )}
        </FieldsGroup>

        <FieldsGroup label="Weight">
          <Box className={s.inputs}>
            <TextField
              variant="outlined"
              type="number"
              value={weightValue || ""}
              onChange={handleWeightChange}
              error={!weightValid || (error && value.weight === "null")}
              disabled={disabled}
              fullWidth
              onFocus={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className={s.adornment}
                    position="end"
                    children={weightLabel}
                  />
                ),
                inputProps: numberInputProps,
              }}
            />
          </Box>
          {touched && !weightValid && (
            <FieldError className={s.error}>
              Please enter a valid weight
            </FieldError>
          )}
        </FieldsGroup>
      </Box>

      <RadioGroupField
        label="Unit of Measurement"
        value={state.units}
        options={unitOptions}
        onChange={handleUnitsChange}
        disabled={disabled}
      />
    </Box>
  );
}
