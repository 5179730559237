/**
 * @generated SignedSource<<b0283d881c737ea09c63c08b4b45b8af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
export type UpsertComponentInput = {
  clientMutationId?: string | null | undefined;
  content?: string | null | undefined;
  days?: ReadonlyArray<boolean> | null | undefined;
  draft?: string | null | undefined;
  duration?: number | null | undefined;
  habitPrompt?: string | null | undefined;
  iconName?: string | null | undefined;
  id?: string | null | undefined;
  image?: string | null | undefined;
  locked?: boolean | null | undefined;
  messageContent?: string | null | undefined;
  messageTime?: string | null | undefined;
  reminderTime?: string | null | undefined;
  reminderType?: ReminderType | null | undefined;
  repeat?: ComponentRepeat | null | undefined;
  status?: ComponentStatus | null | undefined;
  title?: string | null | undefined;
  type?: ComponentType | null | undefined;
  weekId?: string | null | undefined;
};
export type ProgramDetailsAddComponentMutation$variables = {
  input: UpsertComponentInput;
};
export type ProgramDetailsAddComponentMutation$data = {
  readonly upsertComponent: {
    readonly component: {
      readonly slug: string;
    };
  } | null | undefined;
};
export type ProgramDetailsAddComponentMutation = {
  response: ProgramDetailsAddComponentMutation$data;
  variables: ProgramDetailsAddComponentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgramDetailsAddComponentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProgramDetailsAddComponentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "236df53ba3af9a02eed1f90cb938cdc7",
    "id": null,
    "metadata": {},
    "name": "ProgramDetailsAddComponentMutation",
    "operationKind": "mutation",
    "text": "mutation ProgramDetailsAddComponentMutation(\n  $input: UpsertComponentInput!\n) {\n  upsertComponent(input: $input) {\n    component {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c3e83b440fc5f9c529dc5372c86438e";

export default node;
