import React, { useEffect, useMemo, useState } from "react";
import {
  Outlet,
  matchPath,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AppLayout from "../../components/app/AppLayout";
import { programsNavigation } from "../../components/coach-programs/constants";
import {
  COACH_CLIENTS_LIST,
  COACH_PROGRAMS_LIST,
  ALL_ROUTES,
  COACH_GROUPS_ROUTES_LIST,
  COACH_PROGRAM_CREATE_ROUTE,
  COACH_PROGRAMS_FOLDERS_ROUTE,
  COACH_ACTIVITY_ROUTES_LIST,
  COACH_MESSAGE_ROUTES_LIST,
  COACH_CLIENTS_FORMS_ROUTE,
  COACH_GROUPS_ROUTE,
  COACH_CLIENT_ROUTES_LIST,
  COACH_CLIENT_OVERVIEW_ROUTE,
  COACH_PROGRAM_ENROLLMENTS_ROUTE,
  COACH_PROGRAM_CURRICULUM_ROUTE,
  COACH_PROGRAM_SETTING_ROUTE,
  COACH_PROGRAM_COMPONENT_ROUTE,
  COACH_PROGRAM_PREVIEW_ROUTE,
  COACH_SETTINGS_ROUTES_LIST,
  COACH_CARD_INFORMATION_ROUTE,
  COACH_PLAN_ROUTE,
  COACH_PROGRAM_COMPONENT_CLIENT_SUMMARY,
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_GROUPS_ARCHIVED_ROUTE,
} from "../../routes/routes";
import { CreateButton } from "../../components/coach-programs/buttons/CreateProgram";
import { coachActivityNavigation } from "../../components/activity/CoachActivity";
import ProgramsFoldersButtonContext from "../../contexts/ProgramsFoldersPopupContext";
import { useCurrentUser, useCurrentUserRole } from "../../hooks/useCurrentUser";
import { NewMessageAction } from "../../components/messages/NewMessageAction";
import { clientsNavigation } from "../../components/coach-clients/constants";
import AddClientButtonContext from "../../contexts/AddClientContext";
import { CreateClientFormAction } from "../../components/coach-client-forms/CreateClientFormAction";
import { groupsNavigation } from "../../routes/coach/groups/CoachGroupsListRoute";
import { GroupsFilters } from "../../components/filters/GroupsFilters";
import { CreateGroupAction } from "../../components/groups/CreateGroupAction";
import GroupsContext from "../../contexts/GroupsContext";
import { CoachGroupsListRouteQuery$data } from "../../routes/coach/groups/__generated__/CoachGroupsListRouteQuery.graphql";
import { groupNavigation } from "../../routes/coach/group/common";
import {
  GroupSort,
  GroupStatus,
  Plan,
  UserRole,
  planClients,
} from "../../constants";
import GroupContext from "../../contexts/GroupContext";
import { createPathObjects } from "../../utils/router";
import { getActionForGroup } from "./app-layout-helpers/common";
import {
  SendMessage,
  clientNavigation,
  clientsBreadcrumb,
} from "../../routes/coach/client/utils/common";
import { ClientContext } from "../../contexts/ClientContext";
import {
  coachProgramNavigation,
  programsBreadcrumb,
} from "../../routes/coach/program/utils/common";
import { EnrollmentsFilters } from "../../components/filters/EnrollmentsFilters";
import { EnrolledAvatars } from "../../components/coach-programs/EnrolledAvatars";
import { EnrollAction } from "../../components/coach-programs/EnrollAction";
import { EditorProgramContext } from "../../components/new-editor/hooks";
import { PreviewProgramButton } from "../../components/coach-programs/buttons/PreviewProgramButton";
import { ProgramContext } from "../../contexts/ProgramContext";
import { CoachProgramEnrollmentsRouteQuery$data } from "../../routes/coach/program/enrollments/__generated__/CoachProgramEnrollmentsRouteQuery.graphql";
import { CoachProgramCurriculumRouteQuery$data } from "../../routes/coach/program/curriculum/__generated__/CoachProgramCurriculumRouteQuery.graphql";
import LayoutLoadingContext from "../../contexts/LayoutLoadingContext";
import { Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { coachSettingsNavigation } from "../../routes/coach/settings/utils/common";
import { CreateProgramFolderButton } from "../../components/coach-programs/buttons/CreateProgramFolder";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import ManageGroupDialogContext from "../../contexts/ManageGroupDialogContext";
import { ActionButton } from "../../components/button/ActionButton";
import AddIcon from "@mui/icons-material/Add";
import AddClientDialogContext from "../../contexts/AddClientDialogContext";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import {
  getAfter,
  getStatusFromPath,
  PaginationContext,
  perPage,
} from "../../utils/paging";

const DEFAULT_CONFIG = {
  title: undefined,
  subtitle: <>&nbsp;</>,
  actions: [],
  tabsNavigation: undefined,
  appBar: true,
  appDrawer: true,
  filters: undefined,
  breadcrumbs: [{ name: "" }],
  hideUpgradeBanner: false,
  appBarHideOnMobile: false,
};

const AppLayoutWrapper = () => {
  const { slug, username, date, component, client, recipient } = useParams();
  const role = useCurrentUserRole();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { unreadMessagesCounter } = useCurrentUser();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const isMobile = useMediaMobile("md", false);

  const limitReached = React.useMemo(
    () => user?.clientsCountNoSample >= Number(planClients(user?.plan as Plan)),
    [user?.clientsCountNoSample, user?.plan],
  );

  // PORTALS
  const addClientButtonPortal = React.useRef<HTMLDivElement>(null);

  // GROUPS CONTEXT
  const [coachGroupsListRouteQuery, setCoachGroupsListRouteQuery] =
    useState<CoachGroupsListRouteQuery$data>();

  const groupsContextValue = useMemo(
    () => ({ coachGroupsListRouteQuery, setCoachGroupsListRouteQuery }),
    [coachGroupsListRouteQuery],
  );

  // GROUP CONTEXT
  const [groupRouteQuery, setGroupRouteQuery] = useState<any>({
    group: { name: "...", membersCount: ".." },
  });

  const groupContextValue = useMemo(
    () => ({ groupRouteQuery, setGroupRouteQuery }),
    [groupRouteQuery],
  );

  // CLIENT CONTEXT
  const [coachClientQuery, setCoachClientQuery] = useState<any>({
    client: { displayName: "...", username: "..." },
  });

  const clientContextValue = useMemo(
    () => ({ coachClientQuery, setCoachClientQuery }),
    [coachClientQuery],
  );

  // PROGRAM CONTEXT
  const [programQuery, setProgramQuery] = useState<
    | CoachProgramEnrollmentsRouteQuery$data
    | CoachProgramCurriculumRouteQuery$data
  >();

  const programContextValue = useMemo(
    () => ({ programQuery, setProgramQuery }),
    [programQuery],
  );

  const clientDisplayName =
    clientContextValue.coachClientQuery?.client.displayName;
  const clientUserName = clientContextValue.coachClientQuery?.client.username;

  const enrollmentsAvatars =
    programContextValue.programQuery?.enrollmentsAvatars;
  const program = programContextValue.programQuery?.program;
  const clients = programContextValue.programQuery?.clients;
  const groups = programContextValue.programQuery?.groups;

  // LOADING CONTEXT
  const [loading, setLoading] = useState(false);

  const loadingContextValue = useMemo(
    () => ({ loading, setLoading }),
    [loading],
  );

  // ACTION BUTTONS
  const programsFoldersButtonState = usePopupState({
    variant: "popover",
    popupId: "program-folder-rename",
  });

  // MANAGE GROUP DIALOG
  const [manageGroupDialogOpen, setManageGroupDialogOpen] =
    React.useState(false);

  const manageGroupDialogOpenContextValue = {
    manageGroupDialogOpen,
    setManageGroupDialogOpen,
  };

  // ADD CLIENT DIALOG

  const [addClientDialog, setAddClientDialog] = useState(false);

  const addClientDialogContextValue = {
    addClientDialog,
    setAddClientDialog,
  };

  // CONFIGS
  const getAppLayoutConfigs = (pathname: string) => {
    try {
      const [{ route }] = matchRoutes(createPathObjects(ALL_ROUTES), pathname);

      if (
        COACH_PROGRAM_COMPONENT_CLIENT_SUMMARY.replace(":slug", slug)
          .replace(":date", date)
          .replace(":component", component)
          .replace(":client", client) === location.pathname
      )
        return {
          ...DEFAULT_CONFIG,
          title: "Program",
          subtitle: "End workout",
          breadcrumbs: [{ name: "" }],
          appBar: true,
          appDrawer: true,
        };

      if (COACH_PROGRAMS_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Programs",
          ...(isMobile ? { subtitle: "" } : {}),
          actions:
            pathname === COACH_PROGRAMS_FOLDERS_ROUTE
              ? [
                  <CreateProgramFolderButton
                    {...bindTrigger(programsFoldersButtonState)}
                  />,
                ]
              : [<CreateButton />],
          tabsNavigation:
            pathname === COACH_PROGRAM_CREATE_ROUTE
              ? undefined
              : programsNavigation(searchParams.get("folder")),
          appBar: pathname === COACH_PROGRAM_CREATE_ROUTE ? false : true,
          appDrawer: pathname === COACH_PROGRAM_CREATE_ROUTE ? false : true,
        };

      if (COACH_CLIENTS_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Clients",
          ...(isMobile ? { subtitle: "" } : {}),
          tabsNavigation: clientsNavigation(),
          actions:
            pathname === COACH_CLIENTS_FORMS_ROUTE
              ? [<CreateClientFormAction />]
              : [
                  <ActionButton
                    startIcon={<AddIcon />}
                    size="small"
                    children="Add a client"
                    onClick={() => {
                      if (limitReached) {
                        navigate(COACH_PLAN_ROUTE);
                      } else {
                        setAddClientDialog(true);
                      }
                    }}
                  />,
                ],
        };

      if (COACH_GROUPS_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Groups",
          tabsNavigation: groupsNavigation,
          filters: coachGroupsListRouteQuery.groups.totalCount > 0 && (
            <GroupsFilters />
          ),
          subtitle: `${coachGroupsListRouteQuery.groups.totalCount} group${
            coachGroupsListRouteQuery.groups.totalCount === 1 ? "" : "s"
          }`,
          actions: [
            <CreateGroupAction
              clientsRef={coachGroupsListRouteQuery.clients}
            />,
          ],
        };

      if (COACH_ACTIVITY_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Client Activity",
          tabsNavigation: coachActivityNavigation,
        };

      if (COACH_MESSAGE_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: !isMobile ? "Messages" : null,
          subtitle: !isMobile
            ? `${unreadMessagesCounter} new message${
                unreadMessagesCounter === 1 ? "" : "s"
              }`
            : null,
          actions: !isMobile && [<NewMessageAction />],
          appBarHideOnMobile: recipient ? true : false,
        };

      if (route.path.includes(COACH_GROUPS_ROUTE))
        return {
          ...DEFAULT_CONFIG,
          title: groupRouteQuery.group.name,
          tabsNavigation: groupNavigation(slug, role as UserRole),
          subtitle: `${groupRouteQuery.group.membersCount} member${
            groupRouteQuery.group.membersCount === 1 ? "" : "s"
          }`,
          actions: getActionForGroup(
            pathname,
            groupRouteQuery,
            setManageGroupDialogOpen,
          ),
        };

      if (COACH_CLIENT_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: clientDisplayName,
          ...(isMobile ? { subtitle: "" } : {}),
          breadcrumbs: [clientsBreadcrumb],
          tabsNavigation: clientNavigation(clientUserName),
          actions: matchPath(COACH_CLIENT_OVERVIEW_ROUTE, pathname) && [
            <SendMessage username={clientUserName} />,
          ],
        };

      if (COACH_PROGRAM_ENROLLMENTS_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Enroll Clients",
          ...(isMobile ? { subtitle: "" } : {}),
          tabsNavigation: coachProgramNavigation(
            program.slug,
            enrollmentsAvatars.totalCount,
          ),
          breadcrumbs: [programsBreadcrumb],

          actions: [
            <EnrolledAvatars
              enrollmentsRef={enrollmentsAvatars}
              programSlug={program.slug}
            />,
            <EnrollAction
              programRef={program}
              clientsRef={clients}
              groupsRef={groups}
            />,
          ],
          filters: <EnrollmentsFilters />,
        };

      if (COACH_PROGRAM_CURRICULUM_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: program.name,
          ...(isMobile ? { subtitle: "" } : {}),
          tabsNavigation: coachProgramNavigation(
            program.slug,
            enrollmentsAvatars.totalCount,
          ),
          breadcrumbs: [programsBreadcrumb],

          actions: [
            <EnrolledAvatars
              enrollmentsRef={enrollmentsAvatars}
              programSlug={program.slug}
            />,
            <EnrollAction
              programRef={program}
              clientsRef={clients}
              groupsRef={groups}
            />,
            <EditorProgramContext.Provider value={program}>
              <PreviewProgramButton />
            </EditorProgramContext.Provider>,
          ],
        };

      if (COACH_PROGRAM_SETTING_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          appBar: false,
          appDrawer: false,
        };

      if (COACH_PROGRAM_COMPONENT_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          appBar: false,
          appDrawer: false,
          hideUpgradeBanner: true,
        };

      if (COACH_PROGRAM_PREVIEW_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          appBar: false,
          appDrawer: false,
        };

      if (COACH_CARD_INFORMATION_ROUTE === location.pathname)
        return {
          ...DEFAULT_CONFIG,
          title: "Stripe",
          subtitle: "Credit card settings",
          breadcrumbs: [{ name: "Settings", link: COACH_PLAN_ROUTE }],
        };

      if (COACH_SETTINGS_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Settings",
          tabsNavigation: coachSettingsNavigation(true),
        };

      return DEFAULT_CONFIG;
    } catch (error) {
      return DEFAULT_CONFIG;
    }
  };

  const AppLayoutData = getAppLayoutConfigs(location.pathname);

  useEffect(() => {
    setGroupRouteQuery({ group: { name: "...", membersCount: ".." } });
  }, [slug]);

  useEffect(() => {
    setCoachClientQuery({ client: { displayName: "...", username: ".." } });
  }, [username]);

  const page = searchParams.get("page");
  const sortKey = searchParams.get("sort");
  const paginationContextValue = {
    after: getAfter(perPage, Number(page ?? "1")),
    first: perPage,
    orderBy: sortKey ?? GroupSort.NAME_ASC,
    status: getStatusFromPath(location.pathname),
  };

  return (
    <ProgramsFoldersButtonContext.Provider value={programsFoldersButtonState}>
      <AddClientButtonContext.Provider value={addClientButtonPortal}>
        <GroupsContext.Provider value={groupsContextValue}>
          <GroupContext.Provider value={groupContextValue}>
            <ClientContext.Provider value={clientContextValue}>
              <ProgramContext.Provider value={programContextValue}>
                <LayoutLoadingContext.Provider value={loadingContextValue}>
                  <PaginationContext.Provider value={paginationContextValue}>
                    <AddClientDialogContext.Provider
                      value={addClientDialogContextValue}
                    >
                      <ManageGroupDialogContext.Provider
                        value={manageGroupDialogOpenContextValue}
                      >
                        <AppLayout
                          title={
                            loading || AppLayoutData.title === "..." ? (
                              <Skeleton variant="text" width={500} />
                            ) : (
                              AppLayoutData.title
                            )
                          }
                          subtitle={
                            (AppLayoutData.subtitle && loading) ||
                            AppLayoutData.title === "..." ? (
                              <Skeleton variant="text" width={200} />
                            ) : (
                              AppLayoutData.subtitle
                            )
                          }
                          tabsNavigation={AppLayoutData.tabsNavigation}
                          actions={AppLayoutData.actions}
                          appBar={AppLayoutData.appBar}
                          appDrawer={AppLayoutData.appDrawer}
                          filters={AppLayoutData.filters}
                          breadcrumbs={AppLayoutData.breadcrumbs}
                          hideUpgradeBanner={AppLayoutData.hideUpgradeBanner}
                          appBarHideOnMobile={AppLayoutData.appBarHideOnMobile}
                        >
                          <Outlet />
                        </AppLayout>
                      </ManageGroupDialogContext.Provider>
                    </AddClientDialogContext.Provider>
                  </PaginationContext.Provider>
                </LayoutLoadingContext.Provider>
              </ProgramContext.Provider>
            </ClientContext.Provider>
          </GroupContext.Provider>
        </GroupsContext.Provider>
      </AddClientButtonContext.Provider>
    </ProgramsFoldersButtonContext.Provider>
  );
};

export default AppLayoutWrapper;
