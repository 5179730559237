import clsx from "clsx";
import React from "react";
import { DialogProps, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";

import { WorkoutExercise } from "./types";
import { WorkoutExerciseHistory } from "./WorkoutExerciseHistory";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "9999 !important" as any,
  },

  paper: {
    width: "100vw",
    height: "100vh",
    margin: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(65.5),
      width: "auto",
      height: theme.spacing(70),
      margin: theme.spacing(4),
    },
  },

  header: {
    marginBottom: 0,
  },

  title: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },

  loading: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginTop: theme.spacing(22),
  },
}));

export interface WorkoutExerciseHistoryDialogProps extends DialogProps {
  exercise: WorkoutExercise;
}

export const WorkoutExerciseHistoryDialog = (
  props: WorkoutExerciseHistoryDialogProps,
) => {
  const { className, exercise, ...other } = props;
  const s = useStyles();

  const handleClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      classes={{
        paper: s.paper,
        header: s.header,
      }}
      title="Exercise History"
      onClick={handleClick}
      {...other}
    >
      <Typography className={s.title}>{exercise.title}</Typography>

      <React.Suspense
        fallback={<Box className={s.loading}>Loading results…</Box>}
      >
        <WorkoutExerciseHistory exercise={exercise} />
      </React.Suspense>
    </BaseDialog>
  );
};
