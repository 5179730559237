import clsx from "clsx";
import React from "react";
import { Box, BoxProps, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { grey } from "@mui/material/colors";

import logo from "../app/logo-full.svg";
import { LocalStorageKeys } from "../../constants";
import { brandedAppWorkspaceLogoUrl, isBrandedApp } from "../../utils/mobile";

const useStyles = makeStyles((theme) => ({
  root: {
    color: grey[400],
  },
  spinner: {
    width: 20,
    height: 20,
    position: "absolute",
    left: "50%",
    top: "50%",
    marginTop: -10,
    marginLeft: -10,
  },
  logo: {
    margin: 40,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
  },
}));

export interface LoadingPageProps extends BoxProps {}

export function LoadingPage(props: LoadingPageProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const logoUrl = isBrandedApp
    ? brandedAppWorkspaceLogoUrl
    : localStorage.getItem(LocalStorageKeys.LOADER_LOGO_URL);
  const clientPortalLogo = logoUrl && logoUrl !== "" ? logoUrl : undefined;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <CircularProgress color="inherit" className={s.spinner} size="20" />
      <Box className={s.logo}>
        <img
          src={clientPortalLogo ?? logo}
          alt="logo"
          style={{
            marginInline: "auto",
            width: clientPortalLogo && "60px",
            height: clientPortalLogo && "60px",
          }}
        />
      </Box>
    </Box>
  );
}
