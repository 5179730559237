export const colorSystem = {
  primary: "#EB5757",
  primaryOpacity: "rgba(235, 87, 87, 0.1)",
  secondary: "#000000",
  gray: "#7E898F",
  grayAlpha1A: "#7E898F1A",
  grayAlpha55: "#7E898F55",
  secondaryGray: "#D0D8DC",
  secondaryGrayOpacity5: "rgba(208, 216, 220, 0.5)",
  secondaryGrayOpacity: "rgba(208,216,220,0.1)",
  greyOpacity: "rgba(125,137,142,0.83)",
  greyOpacity2: "rgba(126,137,143,0.15)",
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#E0E0E0",
  gray6: "#F2F2F2",
  gray7: "#CCD7DD",
  gray8: "#EDF1F3",
  gray9: "#F3F3F3",
  gray10: "#FBFEFF",
  gray11: "#F4F4F4",
  background: "rgba(208, 216, 220, 0.1)",
  orange: "#F2994A",
  orange2: "#f85802",
  yellow: "#E5BF48",
  green: "#219653",
  green1: "#219653",
  green2: "#27AE60",
  green3: "#6FCF97",
  greenOpacity2: "rgba(39, 174, 96, 0.05)",
  greenOpacity: "rgba(39, 174, 96, 0.1)",
  green4: "#E9F7EF",
  blue1: "#2F80ED",
  blue2: "#2D9CDB",
  blue3: "#56CCF2",
  blue4: "#F2F5FA",
  purple2: "#BB6BD9",
  white: "#FFFFFF",
  white2: "#FAFAFA",
  white3: "#F5F5F5",
  black: "#000000",
  blackOpacity: "rgba(0,0,0,0.7)",
  blackOpacitySecond: "rgba(0,0,0,0.5)",
  border1: "#19191951",
  border2: "rgba(0, 0, 0, 0.23)",
  border3: "rgba(208, 216, 220, 0.2)",
};

export const calloutColor = {
  info: { main: colorSystem.blue1, background: `${colorSystem.blue1}0F` },
  success: { main: colorSystem.green2, background: `${colorSystem.green2}0F` },
  warning: { main: colorSystem.yellow, background: `${colorSystem.yellow}0F` },
  danger: { main: colorSystem.primary, background: `${colorSystem.primary}0F` },
};
