import React from "react";
import { isBrandedApp } from "../../utils/mobile";
import { PoweredByStridist, PoweredByStridistProps } from "./PoweredByStridist";

export interface ConsentBannerProps extends PoweredByStridistProps {}

export function ConsentBanner(props: ConsentBannerProps) {
  const { className } = props;
  return !isBrandedApp && <PoweredByStridist className={className} />;
}
