import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, TextField, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";

import { AppLogo } from "../app/AppLogo";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  AcceptClientInviteInvalidReason,
  AppError,
  planClients,
  Plan,
} from "../../constants";
import { colorSystem } from "../../theme";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { deviceSupports } from "../../utils/device";
import {
  AuthTokenClientSignUpResponse,
  useAuthTokenClientSignUp,
} from "../../hooks/useAuthTokenClientSignUp";
import { iOSMobileApp } from "../../utils/mobile";

import { ClientAcceptInviteInvalid } from "./ClientAcceptInviteInvalid";
import { ClientLinkInviteScreen_inviteCode$key } from "./__generated__/ClientLinkInviteScreen_inviteCode.graphql";
import { GoogleButton } from "./GoogleButton";
import { OrDivider } from "./OrDivider";
import { AuthButton } from "./AuthButton";
import { ClientLinkInviteScreenInviteMutation } from "./__generated__/ClientLinkInviteScreenInviteMutation.graphql";
import { VerifyEmailDialog } from "./VerifyEmailDialog";
import { useSignInMutation } from "./mutations/SignIn";
import { AppleButton } from "./AppleButton";
import { ClientDetailedLinkInviteScreen } from "./ClientDetailedLinkInviteScreen";
import { SIGN_UP_CLIENT_COMPLETE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { urlConstants } from "../../constants/urlConstants";
import { ConsentBanner } from "../app/ConsentBanner";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  groupScreen: {
    width: "100%",
    height: "100%",

    "& $logo, & $poweredBy": {
      display: "none",
    },
  },

  logo: {
    marginBottom: theme.spacing(4),
  },

  content: {
    maxWidth: 457,
    textAlign: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    marginTop: theme.spacing(1.5),
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  divider: {
    marginTop: theme.spacing(3),
  },

  email: {
    marginTop: theme.spacing(3),
  },

  conditions: {
    margin: theme.spacing(2, "auto", 0),
    width: 274,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
}));

const inviteMutation = graphql`
  mutation ClientLinkInviteScreenInviteMutation(
    $input: InviteClientByCodeInput!
  ) {
    inviteClientByCode(input: $input) {
      invite {
        id
      }
    }
  }
`;

const inviteCodeFragment = graphql`
  fragment ClientLinkInviteScreen_inviteCode on InviteCode {
    ...ClientDetailedLinkInviteScreen_inviteCode
    code
    coach {
      displayName
      clientsCountNoSample: clientsCount(noSample: true)
      plan
    }

    group {
      id
    }

    program {
      id
    }
  }
`;

export interface ClientLinkInviteScreenProps extends BoxProps {
  inviteCode: ClientLinkInviteScreen_inviteCode$key;
}

export function ClientLinkInviteScreen(props: ClientLinkInviteScreenProps) {
  const { className, inviteCode: inviteCodeRef, ...other } = props;
  const inviteCode = useFragment(inviteCodeFragment, inviteCodeRef);
  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();
  const brandName = useCurrentBrandName();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [verifyEmailDialogOpen, setVerifyEmailDialogOpen] =
    React.useState(false);
  const onError = useGenericErrorHandler(iOSMobileApp);
  const snackAlert = useSnackAlert();
  const [inviteClientByCode, inviting] =
    useMutation<ClientLinkInviteScreenInviteMutation>(inviteMutation);
  const [signIn, signingIn] = useSignInMutation();

  const clientLimitReached =
    inviteCode.coach.clientsCountNoSample >
    Number(planClients(inviteCode.coach.plan as Plan, true));

  React.useEffect(() => {
    if (clientLimitReached) {
      snackAlert({
        severity: "error",
        message: "Sorry, this coach can't add new clients.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUpWithIdToken = React.useCallback(
    (error, response: AuthTokenClientSignUpResponse) => {
      if (error) {
        if (error.code) {
          snackAlert({
            severity: "error",
            message: error.message,
          });
        } else {
          onError(error);
        }
      } else {
        setLoading(true);
        signIn({
          variables: { idToken: response.fireBaseToken },
          updater: (store) => {
            (store as any).invalidateStore();
          },
          onCompleted(result, errors) {
            setLoading(false);
            if (errors && errors[0]) {
              onError(errors[0]);
            } else {
              window.location.href = SIGN_UP_CLIENT_COMPLETE;
            }
          },
          onError,
        });
      }
    },
    [onError, signIn, snackAlert],
  );

  const [handleSignIn, signInInFlight] = useAuthTokenClientSignUp(
    inviteCode.code,
    signUpWithIdToken,
  );

  const pending = loading || inviting || signingIn || signInInFlight;

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    [],
  );

  const sendInvite = React.useCallback(() => {
    inviteClientByCode({
      variables: {
        input: {
          email,
          code: inviteCode.code,
        },
      },
      onCompleted(_, errors) {
        if (errors && errors[0]) {
          onError(errors[0] as AppError);
        } else {
          setVerifyEmailDialogOpen(true);
        }
      },
      onError,
    });
  }, [email, inviteClientByCode, inviteCode.code, onError]);

  const handleEmailSignUp = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      sendInvite();
    },
    [sendInvite],
  );

  if (user) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.ANOTHER_USER_LOGGED}
      />
    );
  }

  const applyGroupScreen = Boolean(inviteCode?.group || inviteCode?.program);

  return (
    <ClientDetailedLinkInviteScreen
      inviteCodeKey={inviteCode}
      enabled={applyGroupScreen}
    >
      <Box
        className={clsx(s.root, className, applyGroupScreen && s.groupScreen)}
        {...other}
      >
        <AppLogo className={s.logo} />

        <Box className={s.content}>
          <Typography className={s.title} variant="h1">
            {inviteCode?.coach.displayName} on {brandName}
          </Typography>
          <Typography className={s.subtitle} variant="h5">
            Create a free {brandName} account to join your coach.
          </Typography>

          {(deviceSupports.googleAuthClientSignUpEnabled ||
            deviceSupports.appleAuthClientSignUpEnabled) && (
            <>
              {deviceSupports.appleAuthClientSignUpEnabled && (
                <AppleButton
                  onSuccess={handleSignIn}
                  disabled={pending || clientLimitReached}
                  children="Sign up with Apple"
                />
              )}

              {deviceSupports.googleAuthClientSignUpEnabled && (
                <GoogleButton
                  onSuccess={handleSignIn}
                  disabled={pending || clientLimitReached}
                  children="Sign up with Google"
                />
              )}

              <OrDivider className={s.divider} />
            </>
          )}

          <form onSubmit={handleEmailSignUp}>
            <TextField
              className={s.email}
              name="email"
              type="email"
              label="Email address"
              placeholder="Email address"
              value={email}
              onChange={handleEmailChange}
              variant="outlined"
              fullWidth
            />

            <AuthButton
              type="submit"
              children="Sign up with email"
              disabled={!email || pending || clientLimitReached}
            />
          </form>

          <Typography className={s.conditions}>
            By signing up, you agree to our{" "}
            <Link
              href={urlConstants.terms}
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of service
            </Link>{" "}
            and{" "}
            <Link
              href={urlConstants.privacy}
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </Link>
            .
          </Typography>
        </Box>
        <ConsentBanner />
      </Box>

      <VerifyEmailDialog
        open={verifyEmailDialogOpen}
        email={email}
        onResendClick={sendInvite}
      />
    </ClientDetailedLinkInviteScreen>
  );
}
