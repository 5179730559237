import React from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { LocalStorageKeys, UserRole } from "../constants";
import { authStorage } from "../utils/auth";
import { nativeIntercom } from "../utils/intercom";

import { useAnalytics } from "./useAnalytics";
import { useCurrentWorkspace } from "./useCurrentWorkspace";
import { useCurrentUser } from "./useCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGOUT_ROUTE } from "../routes/routes";

const signOutMutation = graphql`
  mutation useLogoutSignOutMutation {
    signOut
  }
`;

export function useLogout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [trackEvent] = useAnalytics();
  const [signOut] = useMutation(signOutMutation);
  const user = useCurrentUser();
  const workspace = useCurrentWorkspace();
  const returnUrl =
    user?.role === UserRole.CLIENT ? `/${workspace.slug}/login` : "/login";

  return React.useCallback(() => {
    signOut({
      variables: {},
      onCompleted() {
        localStorage.removeItem(LocalStorageKeys.FAVICON_LOGO_URL);
        localStorage.removeItem(LocalStorageKeys.LOADER_LOGO_URL);
        authStorage.resetToken();
        nativeIntercom.logout();
        trackEvent("Logout");
        location.pathname === LOGOUT_ROUTE && navigate(0);
        navigate(LOGOUT_ROUTE);
      },
      updater: (store) => {
        (store as any).invalidateStore();
      },
    });
  }, [signOut, trackEvent, returnUrl]);
}
