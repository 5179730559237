import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Avatar,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import UserMenu from "../menu/UserMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    cursor: "pointer",
  },

  info: {
    overflow: "hidden",
  },

  name: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: "18px",
    color: theme.palette.common.black,
  },

  email: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.common.black,
  },

  textOverflow: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  menuIcon: {
    transform: "rotate(180deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),

    "&$active": {
      transform: "rotate(0)",
    },
  },

  active: {},
}));

export interface CurrentUserBoxProps extends BoxProps {
  minimized?: boolean;
}

export function CurrentUserBox(props: CurrentUserBoxProps) {
  const theme = useTheme();
  const { className, minimized, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const openMenu = React.useCallback((event) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = React.useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  return user ? (
    <>
      <Box
        className={clsx(s.root, className)}
        {...other}
        onClick={openMenu}
        aria-label="user-menu"
        sx={{
          alignItems: "center",
          minHeight: theme.spacing(6),
          display: "flex",
          overflow: "hidden",
          marginInline: 2,
          marginBlock: 3,
          maxWidth: "100%",

          ...(minimized && {
            marginInline: 1.25,
            marginBottom: 1,
            maxWidth: "40px",
            flexWrap: "wrap",
            justifyContent: "center",
          }),

          transition: theme.transitions.create(["max-width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Avatar
          id="nav-user-photo"
          alt={user.displayName}
          src={user.photoURL}
          variant="square"
          sx={{
            marginRight: !minimized && theme.spacing(1),
            borderRadius: theme.spacing(1),
          }}
        />
        {!minimized && (
          <Box className={s.info}>
            <Typography
              className={clsx(s.name, s.textOverflow)}
              children={user.displayName}
            />
            <Typography
              className={clsx(s.email, s.textOverflow)}
              children={user.email}
            />
          </Box>
        )}
        <IconButton
          size="large"
          sx={{
            padding: 1,
          }}
        >
          <KeyboardArrowUp
            className={clsx({
              [s.menuIcon]: true,
              [s.active]: Boolean(menuAnchorEl),
            })}
          />
        </IconButton>
      </Box>
      {
        // TODO: Investigate why menu keeps opening on when clicking on `sample` settings item
        menuAnchorEl && (
          <UserMenu
            id="user-menu"
            open={Boolean(menuAnchorEl)}
            anchorEl={menuAnchorEl}
            onClose={closeMenu}
          />
        )
      }
    </>
  ) : null;
}
