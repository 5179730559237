/**
 * @generated SignedSource<<359857fc17b9a76875139c665fb32caf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CoachProgramCurriculumRouteWeeksQuery$variables = {
  programSlug: string;
};
export type CoachProgramCurriculumRouteWeeksQuery$data = {
  readonly program: {
    readonly id: string;
    readonly weeks: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly week: number;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CoachProgramCurriculumRouteWeeksQuery = {
  response: CoachProgramCurriculumRouteWeeksQuery$data;
  variables: CoachProgramCurriculumRouteWeeksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "programSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "programSlug"
      }
    ],
    "concreteType": "Program",
    "kind": "LinkedField",
    "name": "program",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "after",
            "value": ""
          },
          {
            "kind": "Literal",
            "name": "first",
            "value": 9999
          }
        ],
        "concreteType": "WeeksConnection",
        "kind": "LinkedField",
        "name": "weeks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WeeksEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Week",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "week",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "weeks(after:\"\",first:9999)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachProgramCurriculumRouteWeeksQuery",
    "selections": (v2/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachProgramCurriculumRouteWeeksQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4bc54cc47640466f6d1d1cea05a0a039",
    "id": null,
    "metadata": {},
    "name": "CoachProgramCurriculumRouteWeeksQuery",
    "operationKind": "query",
    "text": "query CoachProgramCurriculumRouteWeeksQuery(\n  $programSlug: String!\n) {\n  program(slug: $programSlug) {\n    id\n    weeks(first: 9999, after: \"\") {\n      edges {\n        node {\n          id\n          week\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "40871b60f698d5903dd6469f6f9631d4";

export default node;
