import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import {
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
} from "../../constants";
import { unitSetLabel } from "../../utils/units";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useQueryParam } from "../../hooks/useQueryParam";
import WorkoutButton from "./WorkoutButton";
import WorkoutSet from "./workout-set/WorkoutSet";
import AddCircle from "../../icons/AddCircle";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import {
  getExtraColumnLabel,
  getRepsColumnLabel,
  hasExtraColumn,
} from "./utils";
import ExerciseTooltip from "../tooltip/ExerciseTooltip";
import { useWorkoutTableStyles } from "./tableStyles";
import { Sets, WorkoutExercise } from "../workout/types";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: 12,
    },
    header: {
      display: "flex",
      color: colorSystem.gray,
      fontWeight: 500,
      marginBottom: 12,
      fontSize: 14,
    },
    body: {
      display: "flex",
      maxHeight: 42,
      alignItems: "center",
      backgroundColor: colorSystem.secondaryGrayOpacity,
      borderRadius: 4,
      marginBottom: 4,
    },
    addButton: {
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(4),
      marginTop: 16,
      "& svg": {
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
    },
    addText: {
      color: colorSystem.black,
      fontSize: 16,
      marginLeft: theme.spacing(0.75),
    },
  };
});

type WorkoutSetTableProps = {
  sets: Array<Sets>;
  exercise?: WorkoutExercise;
  onUpdate?: (exercise: WorkoutExercise) => void;
  history?: boolean;
  completedAt?: string;
  readOnly?: boolean;
  isCoach?: boolean;
  programId?: string;
  className?: string;
};

const WorkoutSetTable = (props: WorkoutSetTableProps) => {
  const {
    sets: defaultSets,
    exercise,
    onUpdate,
    history = false,
    completedAt,
    readOnly = false,
    isCoach = false,
    programId,
    className,
  } = props;

  const [sets, setSets] = React.useState(defaultSets || []);
  const s = useStyles();
  const tableStyles = useWorkoutTableStyles();
  const user = useCurrentUser();
  const [disabledAction] = useQueryParam("notification", "");
  const theme = useTheme();

  React.useEffect(() => {
    if (!history) setSets(defaultSets);
  }, [defaultSets, history]);

  const handleAddSet = React.useCallback(() => {
    const { sets } = exercise;
    const newSets = [...sets, { reps: "-", weight: "-" }];
    if (onUpdate) {
      setSets(newSets);
      onUpdate({
        ...exercise,
        sets: [...newSets],
      });
    }
  }, [exercise, onUpdate]);

  const handleSetChange = (set, index) => {
    if (onUpdate) {
      const newSets = [...sets];
      newSets[index] = set;
      setSets([...newSets]);

      onUpdate({
        ...exercise,
        sets: [...newSets],
        // TODO check that result and time should be always updated
        result: newSets.filter((item) => item.checked),
        time: dayjs(new Date()).format("MMM DD.YY"),
      });
    }
  };

  const typeSet = exercise?.typeSet || ExerciseTypeSet.WEIGHT;
  const units = exercise?.units || user.units;

  return (
    <div className={clsx(s.container, className)}>
      <div
        className={clsx(
          s.header,
          tableStyles.header,
          hasExtraColumn(exercise?.typeExtraMeasurement) &&
            tableStyles.headerWithExtraColumn,
        )}
      >
        <div className={clsx(tableStyles.cell, tableStyles.set)}>Set</div>
        <div className={clsx(tableStyles.cell, tableStyles.reps)}>
          <ExerciseTooltip type={exercise?.typeReps as ExerciseTypeReps}>
            <>{getRepsColumnLabel(exercise?.typeReps, history)}</>
          </ExerciseTooltip>
        </div>
        <div className={clsx(tableStyles.cell, tableStyles.weight)}>
          {unitSetLabel(units, typeSet)}
        </div>
        {hasExtraColumn(exercise?.typeExtraMeasurement) && (
          <div className={clsx(tableStyles.cell, tableStyles.extraColumn)}>
            {getExtraColumnLabel(exercise?.typeExtraMeasurement)}
          </div>
        )}
        <div
          className={clsx(
            tableStyles.cell,
            tableStyles.result,
            history && tableStyles.resultHistory,
          )}
        >
          {history && completedAt && (
            <>{dayjs(completedAt).format("MMM DD.YY")}</>
          )}
        </div>
      </div>
      {sets?.map((set, index) => (
        <WorkoutSet
          key={index}
          set={set}
          setNumber={index}
          typeExtraMeasurement={
            exercise?.typeExtraMeasurement || ExerciseTypeExtra.NONE
          }
          typeReps={exercise?.typeReps || ExerciseTypeReps.WHOLE}
          typeSet={exercise?.typeSet || ExerciseTypeSet.WEIGHT}
          history={history}
          isCoach={isCoach}
          programId={programId}
          onChange={(set) => handleSetChange(set, index)}
        />
      ))}

      {!history && readOnly && !disabledAction && (
        <WorkoutButton
          icon={<AddCircle fill={theme.palette.primary.main} />}
          text="Add set"
          style={s.addButton}
          styleText={s.addText}
          onClick={handleAddSet}
        />
      )}
    </div>
  );
};

export default WorkoutSetTable;
