"use client";

import React from "react";

import type { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";

import { useEditorRef } from "@udecode/plate-common";
import { Icons } from "./Icons/icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  useOpenState,
} from "./dropdown-menu";
import { ToolbarButton } from "./toolbar";
import { Box, MenuItem, Theme, useMediaQuery } from "@mui/material";
import { IMenuSection } from "../new-editor/utils/menuSectionsUtil";
import { InsertElementMenuGroupHeader } from "./insert-element-menu-group-header";
import { InsertElementMenuItem } from "./insert-element-menu-item";
import { insertElementAt } from "../new-editor/utils/editorUtils";
import { useItemDisabled } from "../new-editor/hooks";

interface ToolbarInsertDropdownMenuProps extends DropdownMenuProps {
  items?: IMenuSection[];
}

export function ToolbarInsertDropdownMenu({
  items,
  ...props
}: ToolbarInsertDropdownMenuProps) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const editor = useEditorRef();
  const openState = useOpenState();
  const isItemDisabled = useItemDisabled();

  const handleMenuItemClick = async (type: string) => {
    insertElementAt(type, editor);
  };

  return (
    <DropdownMenu modal={false} {...openState} {...props}>
      <DropdownMenuTrigger asChild>
        <ToolbarButton isDropdown pressed={openState.open} tooltip="Insert">
          <Icons.add />
        </ToolbarButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align="start"
        className="flex min-w-0 flex-col gap-0.5 overflow-y-auto"
        style={{
          maxHeight: "min(70vh, 500px)",
          maxWidth: isSm ? "unset" : "90vw",
        }}
      >
        {items?.map(({ items: nestedItems, label }, menuIndex) => (
          <Box key={label}>
            <InsertElementMenuGroupHeader groupLabel={label} />

            <Box sx={{ py: 1 }}>
              {nestedItems.map(
                (
                  {
                    value: type,
                    label: itemLabel,
                    icon: Icon,
                    description: itemDescription,
                  },
                  index,
                ) => (
                  <Box key={index} sx={{ px: 1 }}>
                    <MenuItem
                      key={index}
                      onClick={async () => handleMenuItemClick(type)}
                      disabled={isItemDisabled(type)}
                      sx={{ p: 0, borderRadius: 2 }}
                    >
                      <InsertElementMenuItem
                        itemLabel={itemLabel}
                        itemDescription={itemDescription}
                        Icon={Icon}
                      />
                    </MenuItem>
                  </Box>
                ),
              )}
            </Box>
          </Box>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
