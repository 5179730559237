import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Button,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ComponentIcons } from "../program/icons";
import { getContentType } from "../../utils/component";
import { ComponentType, SOMETHING_WENT_WRONG } from "../../constants";
import { useDirtyMutation } from "../dirty-transaction/hooks";
import { useEditorProgram } from "../new-editor/hooks";
import { useSnackAlert } from "../../hooks/useSnackAlert";

import { SelectComponentIcons_component$key } from "./__generated__/SelectComponentIcons_component.graphql";
import { SelectComponentIconsUpdateDefaultIconMutation as UpdateDefaultIconMutation } from "./__generated__/SelectComponentIconsUpdateDefaultIconMutation.graphql";
import { SelectComponentIconsUpdateComponentIconMutation as UpdateComponentIconMutation } from "./__generated__/SelectComponentIconsUpdateComponentIconMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    outline: "none",
  },

  icons: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
  },

  icon: {
    cursor: "pointer",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    textAlign: "center",

    "& svg": {
      width: 32,
      height: 32,
    },

    "&:hover": {
      backgroundColor: theme.palette.selected.main,
    },

    "&$selected": {
      backgroundColor: theme.palette.quote,
    },
  },

  selected: {},

  checkbox: {
    "& .MuiFormControlLabel-label": {
      color: theme.palette.text.secondary,
    },
  },

  button: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(0.5),
  },
}));

const fragment = graphql`
  fragment SelectComponentIcons_component on Component {
    id
    iconName
    type
  }
`;

const updateDefaultIconMutation = graphql`
  mutation SelectComponentIconsUpdateDefaultIconMutation(
    $input: UpdateDefaultIconInput!
  ) {
    updateDefaultIcon(input: $input) {
      program {
        ...CurriculumEditScreen_program
      }
    }
  }
`;

const updateComponentIconMutation = graphql`
  mutation SelectComponentIconsUpdateComponentIconMutation(
    $input: UpsertComponentInput!
  ) {
    upsertComponent(input: $input) {
      component {
        ...SelectComponentIcons_component
      }
    }
  }
`;

export interface SelectComponentIconsProps extends Omit<BoxProps, "onSubmit"> {
  componentRef: SelectComponentIcons_component$key;
  onSubmit?: (iconName: string, applyToAll: boolean) => void;
  disableApplyToAll?: boolean;
}

export const SelectComponentIcons = React.forwardRef(function (
  props: SelectComponentIconsProps,
  ref,
) {
  const { className, onSubmit, componentRef, disableApplyToAll, ...other } =
    props;
  const s = useStyles();
  const { id: programId } = useEditorProgram();
  const component = useFragment(fragment, componentRef);
  const componentTypeName = getContentType(component.type as ComponentType);
  const [selected, setSelected] = React.useState(component.iconName);
  const [applyToAll, setApplyToAll] = React.useState(false);
  const snackAlert = useSnackAlert();

  const [updateDefaultIcon] = useDirtyMutation<UpdateDefaultIconMutation>(
    updateDefaultIconMutation,
  );
  const [updateComponentIcon] = useDirtyMutation<UpdateComponentIconMutation>(
    updateComponentIconMutation,
  );

  const handleClick = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    setSelected(event.currentTarget.dataset.name);

    return false;
  }, []);

  const handleApplyToAllChange: CheckboxProps["onChange"] = React.useCallback(
    (_, checked) => {
      setApplyToAll(checked);
    },
    [],
  );

  const handleSubmit = React.useCallback(() => {
    const iconName = selected;

    if (applyToAll) {
      updateDefaultIcon({
        variables: {
          input: {
            iconName,
            componentType: component.type,
            programId,
          },
        },
        optimisticUpdater: (store) => {},
        onCompleted: (_, errors) => {
          if (errors && errors.length) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: `Default ${componentTypeName}s icon updated`,
            });
          }
        },
      });
    } else {
      updateComponentIcon({
        variables: {
          input: {
            id: component.id,
            iconName,
          },
        },
        optimisticUpdater: (store) => {
          store.get(component.id).setValue(iconName, "iconName");
        },
        onCompleted: (_, errors) => {
          if (errors && errors.length) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Icon updated",
            });
          }
        },
      });
    }

    if (onSubmit) {
      onSubmit(selected, applyToAll);
    }
  }, [
    applyToAll,
    component.id,
    component.type,
    componentTypeName,
    onSubmit,
    programId,
    selected,
    snackAlert,
    updateComponentIcon,
    updateDefaultIcon,
  ]);

  return (
    <Box className={clsx(s.root, className)} {...{ ref }} {...other}>
      <Box className={s.icons}>
        {Object.entries(ComponentIcons).map(([name, Icon]) => (
          <Box
            key={name}
            data-name={name}
            onClick={handleClick}
            className={clsx(s.icon, selected === name && s.selected)}
          >
            <Icon />
          </Box>
        ))}
      </Box>

      {1 && (
        <FormControlLabel
          className={s.checkbox}
          control={
            <Checkbox
              name="apply-to-all"
              checked={applyToAll}
              onChange={handleApplyToAllChange}
              color="primary"
            />
          }
          label={`Apply icon to all ${componentTypeName}s`}
        />
      )}

      <Button
        className={s.button}
        fullWidth
        variant="contained"
        onClick={handleSubmit}
      >
        Apply changes
      </Button>
    </Box>
  );
});
