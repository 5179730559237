import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { colorSystem } from "../../theme";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { AppError, BillingPlan, SOMETHING_WENT_WRONG } from "../../constants";

import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useQueryParam } from "../../hooks/useQueryParam";

import { SignUpBenefits } from "./SignUpBenefits";
import SignUpStartForm from "./SignUpStartForm";
import { usePreCoachRegistrationMutation } from "./mutations/PreCoachRegistration";
import { countries } from "../../utils/countries";
import PreLoader from "../loading/PreLoader";
import useLocalScript from "../../hooks/useLocalScript";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CoachSignupSchema,
  coachSignupSchema,
} from "../../utils/coachSignupSchema";
import { useCurrentBrandName } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: colorSystem.white2,
  },

  benefits: {
    width: "40%",
    backgroundColor: theme.palette.common.white,
  },

  form: {
    margin: "70px auto 20px auto",
    backgroundColor: colorSystem.white2,
    height: "100%",
    "&$mobile": {
      padding: theme.spacing(4),
      textAlign: "center",
    },
  },

  mobile: {},
}));

export interface SignUpStartScreenProps extends BoxProps {}

export function SignUpStartScreen(props: SignUpStartScreenProps) {
  useLocalScript("meta-pixel-checkout.js");

  const { className, ...other } = props;
  const s = useStyles();
  const brandName = useCurrentBrandName();

  const {
    register,
    trigger,
    setError,
    getValues,
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields },
  } = useForm<CoachSignupSchema>({
    resolver: zodResolver(coachSignupSchema),
  });

  const [savingCard, setSavingCard] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState("");
  const [referral, setReferral] = React.useState("");
  const [preloader, setPreloader] = React.useState(false);
  const [step, setStep] = React.useState(1);

  const isMobile = useMediaMobile();
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const [preCoachRegistration, preCoachRegistrationIn] =
    usePreCoachRegistrationMutation();

  const [plan] = useQueryParam("plan", BillingPlan.UP_TO_50);

  const countriesList = [
    {
      name: "Country",
      flag: "",
      number: "",
      code: "",
    },
    ...countries,
  ];

  const onPreSubmit = React.useCallback(
    (data: CoachSignupSchema) => {
      setPreloader(true);

      preCoachRegistration({
        variables: {
          input: {
            email: data.email,
            displayName: data.fullName,
            referral,
          },
        },
        onCompleted(data, errors) {
          if (errors && errors[0]) {
            const err = errors[0] as AppError;

            console.error(err);

            setPreloader(false);

            if (err.code && err.code.includes("email")) {
              setError("email", { message: err.message });
              setStep(1);
            } else if (err.code && err.code.includes("name")) {
              setError("fullName", { message: err.message });
              setStep(1);
            } else {
              snackAlert({
                severity: "error",
                message: SOMETHING_WENT_WRONG,
              });
            }
          } else {
            setClientSecret(data?.preCoachRegistration?.client_secret);
            setSavingCard(true);
          }
        },
        onError,
      });
    },
    [
      getValues,
      setError,
      preCoachRegistration,
      onError,
      snackAlert,
      setSavingCard,
      referral,
      setPreloader,
    ],
  );

  const pending = preCoachRegistrationIn || !isDirty || !isValid;

  React.useEffect(() => {
    window.rewardful("ready", () => {
      if (window.Rewardful.referral) {
        setReferral(window.Rewardful.referral);
      }
    });
  }, []);

  React.useEffect(() => {
    const validPlan = [
      BillingPlan.UP_TO_50,
      BillingPlan.UP_TO_100,
      BillingPlan.UP_TO_200,
      BillingPlan.UP_TO_UNLIMITED,
      BillingPlan.PROMO,
      BillingPlan.YEARLY_UP_TO_50,
      BillingPlan.YEARLY_UP_TO_100,
      BillingPlan.YEARLY_UP_TO_200,
      BillingPlan.YEARLY_UNLIMITED,
    ].includes(plan);
    if (!validPlan) window.location.href = "https://stridist.com/#pricing";
  }, [plan]);

  return (
    <Box width={"100%"} sx={{ backgroundColor: colorSystem.white2 }}>
      <Box className={clsx(s.root, className)} {...other}>
        {!isMobile && <SignUpBenefits className={s.benefits} plan={plan} />}
        <SignUpStartForm
          className={clsx(s.form, isMobile && s.mobile)}
          step={step}
          setStep={setStep}
          register={register}
          trigger={trigger}
          getValues={getValues}
          errors={errors}
          setError={setError}
          dirtyFields={Object.keys(dirtyFields)}
          onPreSubmit={handleSubmit(onPreSubmit)}
          disabled={pending}
          countriesList={countriesList}
          plan={plan}
          onSaveCard={savingCard}
          setSavingCard={setSavingCard}
          clientSecret={clientSecret}
          closePreloader={() => setPreloader(false)}
        />
        {preloader && (
          <PreLoader
            text={`We are currently building out your ${brandName} account`}
          />
        )}
      </Box>
    </Box>
  );
}
